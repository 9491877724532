import {IBotSettings, OpenAIBotSettings} from "../interfaces/bot-settings.interface"
import {ExternalProvider} from "../enums/external-provider.enum"
import {MicrosoftSTTLanguage} from "../enums/microsoft/microsoft-stt.language.enum"

export const defaultBotSettings: Partial<IBotSettings> = {
  idleText: "",
  idleTimeout: 1,
  intentRecognizerProvider: ExternalProvider.Microsoft,
  language: "en",
  languageDetectionProvider: ExternalProvider.Microsoft,
  languageTranslationProvider: ExternalProvider.Microsoft,
  sentimentAnalysisProvider: ExternalProvider.Microsoft,
  speechToTextLanguageCode: MicrosoftSTTLanguage["English (United States)"],
  speechToTextProvider: ExternalProvider.Microsoft,
  textToSpeechLanguageCode: MicrosoftSTTLanguage["English (United States)"],
  textToSpeechPreferredVoiceType: "f",
  textToSpeechProvider: ExternalProvider.Microsoft,

  // Don't use streaming by default. It requires extra code on the client side.
  textToSpeechStreaming: false,

  // No default for:
  // textToSpeechSpeed
  // textToSpeechResponseFormat
  // textToSpeechModel
  // entityExtractorProvider
}

export const defaultOpenAISettings: Partial<OpenAIBotSettings> = {
  ...defaultBotSettings,

  // TextToSpeechModel
  //    "tts-1-hd" by default for best quality.
  //    "tts-1" optimized for speed is also a good option
  //    "gpt-4o-audio-preview" (or any future LLM model) is too expensive.
  textToSpeechModel: "tts-1-hd",
  textToSpeechPreferredVoiceType: 'nova',
  textToSpeechResponseFormat: 'mp3',
  textToSpeechSpeed: 1,
}