/**
 * Convert enum to array, for Select.vue
 *  <Select :elements="objAsArray"
 *            keyAttr="label"
 *            valueAttr="id" >
 *
 * @param obj = {a = "A"}
 * @return [ {id: 'a', label: 'A'}
 */
export function enumToSelectArray<T>(obj: T): Array<{ id: string, label: T[keyof T] }> {
  return Object.entries(obj).map(([key, value]) => ({
    id: key,
    label: value,
  }));
}
