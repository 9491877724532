<template>
  <div class="content-intents__sidebar">
    <div class="filter-selector" v-if="this.type === 'Entity' || this.type === 'Function'">
      <DropdownEditable
        v-if="itemsEditable"
        :items="filterOptions"
        :value="selectedFilter"
        @input="$emit('update:selectedFilter', $event)"
        @add="$emit('add-item', $event)"
        @delete="$emit('delete-item', $event)"
      ></DropdownEditable>

      <Dropdown
        v-if="!itemsEditable"
        :items="type === 'Intent' ? [] : filterOptions"
        :value="selectedFilter"
        @input="$emit('update:selectedFilter', $event)"
      ></Dropdown>
    </div>
    <div v-else class="margin-top"></div>

    <router-link :to="'#'">
      <button
        type="button"
        class="btn sidemenu btn--purple gradient"
        @click="emitItem('add')"
        v-if="!isRedirect && hasSufficientPermission"
      >
        <i class="icon icon-add"></i>
        Add {{ type === 'Sub' ? 'Sub Flow' : type }}
      </button>
    </router-link>

    <router-link :to="'#'" v-if="hasBatchImport && hasSufficientPermission">
      <button type="button" class="btn sidemenu btn--white" @click="emitItem('batch-import')">
        <i class="icon icon-import"></i>
        Batch Import
        <div></div>
      </button>
    </router-link>

    <router-link :to="'#'" v-if="hasSufficientPermission">
      <button type="button" class="btn sidemenu btn--white" @click="emitItem('import')">
        <i class="icon icon-import"></i>
        Import {{ type === 'Sub' ? 'Sub Flow' : type }}
        <div></div>
      </button>
    </router-link>

    <router-link :to="'#'" v-if="hasImportExport && hasSufficientPermissionExport">
      <button type="button" class="btn sidemenu btn--white" @click="emitItem('export')">
        <i class="icon icon-export"></i>
        Export {{ type === 'Sub' ? 'Sub Flow' : type }}
        <div></div>
      </button>
    </router-link>

    <ul
      class="intents-list"
      :class="{'remove-top-border': !hasSufficientPermissionExport && !hasSufficientPermission}"
    >
      <li v-for="(item, index) in items" :key="index" :class="{'active' : selectedIndex == index }">
        <button @click="$emit('update:selectedIndex', index)">
          {{item}}
          <i
            class="icon-delete"
            v-if="item !== 'None' && hasSufficientPermission"
            @click.prevent="$emit('delete', index)"
          ></i>
        </button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import DropdownEditable from '../../../helpers/DropdownEditable.vue'
import Dropdown from '../../../helpers/Dropdown.vue'
import popupService from '../../../../services/popup.service'
import { Permissions } from '../../../../../../common/enums/tenant/user-permissions.enum'
import permissionsService from '../../../../services/tenants/permissions.service'

@Component({
  components: {
    Dropdown,
    DropdownEditable
  }
})
export default class BotSideNav extends Vue {
  private importContent = '';
  private exportContent = '';

  @Prop() readonly type!: string;
  @Prop() readonly botName: string;
  @Prop({ default: null }) readonly importText: string;
  @Prop({ default: 0 }) readonly selectedIndex: number;
  @Prop({ default: () => [] }) readonly items: any[];
  @Prop({ default: () => ['Custom', 'All'] }) readonly filterOptions: string[];
  @Prop({ default: 'All' }) readonly selectedFilter: string;
  @Prop({ default: false }) readonly isRedirect: boolean;
  @Prop({ default: false }) readonly itemsEditable: boolean;
  @Prop({ default: null }) readonly exportText: string;
  @Prop() readonly hasImportExport: boolean;
  @Prop({ default: false }) readonly hasBatchImport: boolean;

  private hasSufficientPermissionExport = false;
  private hasSufficientPermission = false;

  async mounted () {
    this.importContent = this.importText || this.type
    this.exportContent = this.exportText || this.type
    await this.loadNeededPermissions()
  }

  async loadNeededPermissions () {
    const permissions: Permissions[] | undefined =
      this.type == 'Intent'
        ? [Permissions.ManageIntents, Permissions.ExportIntents]
        : this.type == 'Entity'
          ? [Permissions.ManageEntities, Permissions.ExportEntities]
          : this.type == 'Sub'
            ? [Permissions.ManageSubflows, Permissions.ExportSubs]
            : this.type == 'Function'
              ? [Permissions.ManageFunctions, Permissions.ExportFunctions]
              : this.type == 'LLM'
                ? [Permissions.ManageLLMs, Permissions.ExportLLMs]
                : undefined

    if (permissions) {
      [this.hasSufficientPermission, this.hasSufficientPermissionExport] = await permissionsService.hasAllPermissions(this.botName, ...permissions)
    }
  }

  emitItem (type, index) {
    if (this.hasSufficientPermission || this.hasSufficientPermissionExport) {
      if (index) this.$emit(type, index)
      else this.$emit(type)
    } else popupService.showWarning('You do not have permission to do that!')
  }
}
</script>
