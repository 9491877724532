export const fix = (value = 0, digits = 2) => {
  if (isNaN(value)) return value

  const decimals = Math.pow(10, digits)
  return value ? Math.round(value * decimals) / decimals : 0.0
}

export const fromMillisecondsToSeconds = (milliseconds?: number) => {
  return ((milliseconds || 0) / 1000).toFixed(2)
}

export const addK = (value = 0) => {
  return value >= 1000 ? (value / 1000).toFixed(1) + 'K' : value
}

export const round = (value = 0, all = 0) => {
  const percentage = value === 0 || all === 0 ? 0 : Math.round((value / all) * 10000) / 100
  return percentage > 100 ? 100.0 : percentage.toFixed(1)
}

export const format = (value = 0.0) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}
