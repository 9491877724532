import axios, {AxiosResponse} from 'axios'
import {IChatMessage} from "@common/interfaces/chat-message.interface";

type HistoryResponse = AxiosResponse<IChatMessage[]>

export class ConversationLogService {
    private url: string;
    constructor (botName: string) {
      this.url = `${process.env.VUE_APP_BACKEND_URL}/bots/${botName}/conversation/log`
    }

    async getChatHistory ():Promise<IChatMessage[]> {
      const result:HistoryResponse = await axios.get(this.url);
      return result.data.map(this.parseMessage)
    }

    async getChatHistoryRecent ():Promise<IChatMessage[]> {
      const result:HistoryResponse = await axios.get(`${this.url}/latest`);
      return result.data.map(this.parseMessage)
    }

    /**
     * Convert timestamp from string to date
     */
    private parseMessage(message:IChatMessage): IChatMessage {
        message.timestamp = new Date(message.timestamp);
        return message
      }
}
