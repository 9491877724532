export enum AliModels {
  // Qwen 1.0
  QWEN = "qwen",
  QWEN_0_5B = "qwen-0.5b",
  QWEN_1_8B = "qwen-1.8b",
  QWEN_4B = "qwen-4b",
  QWEN_7B = "qwen-7b",
  QWEN_14B = "qwen-14b",
  QWEN_32B = "qwen-32b",
  QWEN_72B = "qwen-72b",
  QWEN_110B = "qwen-110b",

  // Qwen 2.0
  QWEN2 = "qwen2",
  QWEN2_0_5B = "qwen2-0.5b",
  QWEN2_1_5B = "qwen2-1.5b",
  QWEN2_7B = "qwen2-7b",

  // Qwen 2.5
  QWEN2_5 = "qwen2.5",
  QWEN2_5_0_5B = "qwen2.5-0.5b",
  QWEN2_5_1_5B = "qwen2.5-1.5b",
  QWEN2_5_3B = "qwen2.5-3b",
  QWEN2_5_7B = "qwen2.5-7b",
  QWEN2_5_14B = "qwen2.5-14b",
  QWEN2_5_32B = "qwen2.5-32b",
  QWEN2_5_72B = "qwen2.5-72b",
}

export const AliModelsEnabled = [AliModels.QWEN2]
