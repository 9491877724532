<template>
  <div id="sync-container" class="sync">
    <SynchronizationProcess
      :key="index"
      ref="syncProcess"
      v-for="(provider, index) in providers"
      v-show="openProcesses[index]"
      :botName="botName"
      :botId="botId"
      :syncId="syncId"
      :provider="provider"
      :existingSteps="existingProviders ? existingProviders[provider] : {}"
      :isPublished="isPublished"
      :existingSyncId="existingSyncId"
      @start="setStarted(index)"
      @end="setEnded(index)"
      @close="close(index)"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import botNluService from '../../../../services/bots/bot-nlu.service'
import { ExternalProvider } from '@common/enums/external-provider.enum'
import SynchronizationProcess from './SynchronizationProcess.vue'
import WebSocketService from '../../../../services/websocket.service'
import { SyncState } from '@common/types/nlu-sync-status.type'

@Component({
  components: { SynchronizationProcess }
})
export default class SynchronizationPage extends Vue {
  @Prop() readonly botName!: string
  @Prop() botId!: string
  @Prop() isPublished!: boolean
  @Prop() existingSyncId: string | null
  @Prop() existingProviders?: { [key: string]: SyncState }

  private providers: ExternalProvider[] = []
  private openProcesses: boolean[] = []

  mounted() {
    this.generateSyncId()
  }

  get syncId() {
    return this.$route.query.syncId
  }

  get isSyncActive() {
    return this.$store.state.botData[this.botId]?.syncStatus.isSyncActive
  }

  private generateSyncId() {
    const copy = JSON.parse(JSON.stringify(this.$route.query))
    const newSyncId = !!this.existingSyncId ? this.existingSyncId : `${this.botName}|${Date.now()}`
    if (copy.syncId == newSyncId) {
      return
    }
    copy.syncId = newSyncId
    this.$router.replace({ query: copy })
  }

  private removeSyncId() {
    const copy = JSON.parse(JSON.stringify(this.$route.query))
    if (copy.syncId) {
      delete copy.syncId
      this.$router.replace({ query: copy })
    }
  }

  /** @description returns the providers that support synchronization */
  async getProviders() {
    if (this.isSyncActive && this.existingProviders) {
      return Object.keys(this.existingProviders)
        .map((provider) => provider as ExternalProvider)
        .filter(p => p != ExternalProvider.Nuance)
    }
    const allProviders = await botNluService.getConfiguredNlps(this.botName)
    return allProviders.filter(p => p != ExternalProvider.Nuance)
  }

  async created() {
    this.providers = await this.getProviders()
    this.openProcesses = this.providers.map(_ => true)
    await WebSocketService.connect()

    // Listen for messages
    WebSocketService.on('message', (message) => {
      if (message.botId != this.botId) {
        return
      }
      switch (message.event) {
        case 'sync:progress-state': {
          for (const process of this.$refs.syncProcess as SynchronizationProcess[]) {
            if (process.provider === message.provider) {
              if(!message.syncStatus.isSyncInProgress) {
                process.finishTraining()
              }
              else if(message.syncStatus.step) {
                process.updateStep(message.syncStatus.step, message.syncStatus.stepData)
                return
              }
            }
          }
        }
      }
    })
  }

  setStarted(index) {
    this.$store.commit('addBotActivePublisher', {botId: this.botId, provider: this.providers[index]})
    this.$emit('update:isSyncActive', true)
  }

  setEnded(index) {
    this.$store.commit('removeBotActivePublisher', {botId: this.botId, provider: this.providers[index]})

    if (!this.isSyncActive) {
      this.$emit('update:isPublished', true)
      this.removeSyncId()
    }
  }

  close(index) {
    this.$set(this.openProcesses, index, false)
    if (this.openProcesses.every(p => p === false)) {
      this.$emit('close')
    }
  }

  beforeDestroy() {
    WebSocketService.close()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.sync {
  margin-top: 15px;
}

</style>
