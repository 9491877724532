<template>
  <div class="conversation-log">
    <div class="sidebar">
      <div class="con-title">
        <h4>Conversation Log</h4>
      </div>
      <div class="form--wrapper">
        <div class="main-header">
          <select v-model="hoursBack" v-on:change="onChangeTimeBack" class="white-select">
            <option value="336">Last 2 weeks</option>
            <option value="168">Last week</option>
            <option value="24">Last day</option>
            <option value="12">Last 12 hours</option>
            <option value="6">Last 6 hours</option>
            <option value="2">Last 2 hours</option>
          </select>
        </div>
      </div>
      <div class="content-intents__sidebar">
        <ul class="intents-list">
          <li v-for="(message, index) in firstMessagesOfConversation" :key="index">
            <button class="user-button" @click="filterByConversationId(message.conversationId)">
              {{ timestampToDate(message.timestamp) }}<br />
              {{ message.user ?? 'unknown user' }}
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="chat-container">
      <div class="chat">
        <div class="messages" v-for="(message, key) in messagesAfterTimeBack" :key="key">
          <div class="conversation-title" v-if="key === 0">
            <span
              >Conversation {{ message.conversationId }}<br />
              {{ timestampToDate(message.timestamp) }}<br />
              user {{ message.user ?? 'unknown' }}</span
            >
            <br />
          </div>
          <div class="from-user">
            <div class="content">
              <div class="recieved-txt-bbl" v-if="message.type == 'received'">{{ message.message }}</div>
              <!-- <div class="user-name-bbl" v-if="message.type == 'received'">
               user {{message.user ?? 'unknown'}}<br/>
                bot {{message.botId}}
              </div>-->
            </div>
          </div>
          <div class="content">
            <ShowImageGif v-if="message.message.contentType?.startsWith('image')" :url="message.message.contentUrl"></ShowImageGif>
            <ShowTitleAndChoices
              v-else-if="message.message.contentType == 'application/vnd.microsoft.card.hero'"
              :title="message.message.content.title"
              :buttons="message.message.content.buttons"
            ></ShowTitleAndChoices>
            <div class="css-djy7c2" v-else-if="message.message.contentType == 'application/vnd.microsoft.card.adaptive'">
              <AdaptiveCards :card="message.message.content" :data="template" :useTemplating="true"></AdaptiveCards>
            </div>
            <div class="sent-txt-bbl" v-else-if="message.type == 'sent'">{{ message.message }}</div>
            <!--          <div class="user-name-bbl" v-if="message.type == 'sent'">
                        user {{message.user ?? 'unknown'}}<br/>
                        bot {{message.botId}}</div>-->
          </div>
        </div>
        <div v-if="loading" class="messages">
          <div>
            <h4>Loading...</h4>
            This can take a while for a busy bot.
          </div>
        </div>
        <div v-if="!loading && !messagesAfterTimeBack.length" class="messages">
          <div class="no-data">
            <h4>No conversation data to display</h4>
            Select a different user or time filter.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ConversationLogService } from '../../services/conversation-log.service'
import ShowTitleAndChoices from './ShowTitleAndChoices.vue'
import ShowImageGif from './ShowImageGif.vue'
import ShowAttachment from './ShowAttachment.vue'
import { AdaptiveCards } from 'adaptivecards-vue'
import { PermissionError } from '../../../../common/errors/permission.error'
import popupService from '../../services/popup.service'
import { IChatMessage } from '@common/interfaces/chat-message.interface'

const hourMs = 60 * 60 * 1000

@Component({
  components: {
    ShowTitleAndChoices,
    ShowImageGif,
    ShowAttachment,
    AdaptiveCards
  }
})
export default class ConvLog extends Vue {
  @Prop() name: string
  private allMessages: IChatMessage[] = []
  private hoursBack = 2 * 7 * 24 // last 2 weeks
  private messagesAfterTimeBack: IChatMessage[] = []
  private firstMessagesOfConversation: IChatMessage[] = []
  private currentConversationId = null
  private conversationId = null
  private timeId
  private template = {}
  private logService: ConversationLogService
  private botName
  private loading = true

  private lastConversationId = ''

  async created() {
    this.botName = this.$route.params.botName

    this.logService = new ConversationLogService(this.botName)
    try {
      this.allMessages = await this.logService.getChatHistory()

      // Sort by timestamp asc. Last conversation comes last.
      this.allMessages = this.allMessages.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
    } catch (error) {
      if (error instanceof PermissionError) {
        popupService.showError('Error!', error.message)
      }
      this.$router.push('/bots')
    }

    // If conversationId is in the URL, filter by it and only show that conversation
    this.conversationId = this.$route.params.conversationId
    if (this.conversationId) {
      this.filterByConversationId(this.conversationId)
      this.loading = false
      return
    }

    this.filterByTime()
    this.filterAllMessagesByConversationId()

    // Select the first conversation on the list (latest by timestamp)
    if (this.firstMessagesOfConversation.length) {
      this.filterByConversationId(this.firstMessagesOfConversation[0].conversationId)
    }
    this.timeId = setInterval(await this.getChatHistoryRecent, 2 * 60000)

    this.loading = false
  }

  beforeDestroy() {
    clearInterval(this.timeId)
  }

  async getChatHistoryRecent() {
    const messagesRecent = await this.logService.getChatHistoryRecent()
    this.allMessages = this.allMessages.concat(messagesRecent)
    this.filterByConversationId(this.currentConversationId)
  }

  filterByTime() {
    this.messagesAfterTimeBack = this.allMessages.filter((m) => new Date(m.timestamp).getTime() > new Date().getTime() - this.hoursBack * hourMs)
  }

  onChangeTimeBack() {
    // Only messages after 'timeBack' hours
    this.filterByTime()

    // Only users from those messages
    this.filterAllMessagesByConversationId()
  }

  filterByConversationId(conversationId: any) {
    if (conversationId !== this.currentConversationId) {
      this.currentConversationId = conversationId
      this.filterByTime()
      this.messagesAfterTimeBack = this.messagesAfterTimeBack.filter((m) => m.conversationId === conversationId)
    }
  }

  private timestampToDate(date: Date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')

    return `${year}-${month}-${day} ${hours}:${minutes}`
  }

  filterAllMessagesByConversationId() {
    this.firstMessagesOfConversation = this.messagesAfterTimeBack
      .filter((message) => {
        // First message of that conversation, that has a user (somehow sometimes user property is missing)
        const firstMessageWithUser = this.messagesAfterTimeBack.find((m) => m.conversationId === message.conversationId && m.user)
        if (firstMessageWithUser) {
          return message === firstMessageWithUser
        }
        // First message of that conversation, that apparently never has a user.
        const first = this.messagesAfterTimeBack.find((m) => m.conversationId === message.conversationId)
        return first === message
      })
      // The last conversation goes at the top.
      .reverse()
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/components/_chat.scss';
@import '@/assets/scss/_variables.scss';

.conversation-log {
  .sidebar {
    float: left;
    margin-left: 85px;

    .user-button:active,
    :focus {
      border-left: 4px solid $main;
    }

    .white-select {
      width: 90%;
    }

    .con-title {
      background-color: $white;
      height: 100%;
      margin-bottom: 0px;
      padding-top: 10px;
      border-right: 1px solid $light-blue;

      h4 {
        color: $default-dark;
        font-weight: 700;
        font-size: 24px;
        padding: 10px;
        text-align: center;
        margin-bottom: 0px;
      }
    }

    .main-header {
      background-color: $white;
      height: 80px;
      padding-left: 35px;
      padding-top: 15px;
      display: flex;
      position: relative;
      border-bottom: 0px solid $light-blue;
      border-right: 1px solid $light-blue;
      margin-bottom: 0px;
    }

    .content-intents__sidebar {
      background-color: $sent-text;
      overflow: auto;
      max-height: calc(100vh - 165px);
      min-height: calc(100vh - 139px);
      width: 320px;

      .intents-list {
        height: 100%;

        li {
          height: 100%;
        }
      }
    }
  }

  .conversation-title {
    width: 100%;
    text-align: center;
    color: $grey;
    font-size: 14px;
    border-bottom: 1px solid $light-blue;
    margin: 50px 20px 50px 20px;

    span {
      background: #fff;
      padding: 0 10px;
    }
  }

  .chat-container {
    background-color: #f2f7ff;
  }

  .chat {
    position: absolute;
    display: inline-block;
    background-color: $white;
    overflow-y: auto;
    height: calc(100% - 60px);
    width: calc(100% - 500px);
    margin: 30px 50px 20px 50px;
    border-radius: 12px;

    .messages {
      position: relative;
      margin-right: 5%;
      margin-left: 5%;
      padding-top: 40px;
      padding-bottom: 30px;

      .no-data {
        margin-top: 40px;
        color: $grey;
        font-size: 12px;
        margin-left: 50px;

        h4 {
          color: $default-dark;
          font-weight: 500;
        }
      }

      .textBtn {
        color: $default-dark;
        font-weight: 500;
        font-size: 14px;
        background-color: $white;
        border-radius: 6px;
      }

      .title {
        color: $default-dark;
        font-size: 14px;
        padding: 0px;
      }

      .sent-txt-bbl,
      .recieved-txt-bbl {
        background-color: $received-background;
        border-radius: 8px 8px 8px 0;
        color: $chat-text;
        font-size: 14px;
        font-weight: 500;
        min-height: 20px;
        margin: 0px;
        padding: 10px 20px 10px 20px;
        display: inline-block;
      }

      .from-user {
        float: right;

        .recieved-txt-bbl {
          background-color: $main;
          border-radius: 8px 8px 0 8px;
          color: $white;
        }
      }

      /*.user-name-bbl {
        color: #afafaf;
        font-size: 12px;
      }*/
    }

    .ac-container,
    .ac-adaptiveCard {
      background-color: transparent !important;
    }
  }
}
</style>
