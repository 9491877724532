<template>
  <Modal title="Import Intents" :show="true" @close="$emit('close')" :smallModal="!createType">
    <div class="content">
      <input type="file" ref="faqFile" accept=".xlsx" hidden @change="importFaqIntents" />

      <div class="buttons">
        <button type="button" class="btn btn--white" @click="$refs.faqFile.click()">
          <i class="icon icon-export"></i>
          <p>Import File</p>
        </button>
        <a href="https://blitsproduction.z6.web.core.windows.net/templates/batch-import.xlsx" rel="noopener noreferrer" target="_blank" class="btn btn--white">
          <i class="icon icon-import"></i>
          <p>Template File</p>
        </a>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import intentsService from '../../../../services/bots/intents.service'
import popupService from '../../../../services/popup.service'
import Modal from '../../../helpers/Modal.vue'

@Component({ components: { Modal } })
export default class ImportIntent extends Vue {
  @Prop() botName: string
  @Prop() createType: string

  @Emit('import')
  async importFaqIntents() {
    try {
      const file = (this.$refs.faqFile as HTMLInputElement).files[0]
      const extParts = file.name.split('.')
      const ext = extParts[extParts.length - 1]
      if (ext.toLowerCase() !== 'xlsx') {
        throw new Error('Invalid file extension')
      }

      const intents = await intentsService.importFaq(file, this.botName)
      popupService.showInfo('Success!')
      return intents
    } catch (err) {
      const message = err.response ? err.response.data.message : err.message || err
      if (err.response && err.response.status == 403) {
        throw err
      }
      popupService.showError('Error!', message)
      return []
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: space-evenly;

  .btn {
    height: auto;
    width: 160px;
    padding: 20px;

    i {
      height: 42px;
      width: 54px;
    }

    p {
      margin: 0;
    }
  }
}
</style>
