<template>
  <div class="container">
    <WarningBanner
      v-if="isMonthLimitExceeded"
      description="You have reached your plan's max interactions. Upgrade your plan to keep your bot working."
      buttonValue="Upgrade plan"
      @buttonAction="$router.push('/configuration/subscription')"
    ></WarningBanner>
    <WarningBanner
      description="Microsoft LUIS will be deprecated on October 1, 2025. Retrain your models on Microsoft CLU by adding utterances and re-publishing your model to ensure uninterrupted service."
      info-link="https://support.blits.ai/portal/en/kb/articles/publish"
      info-text="For help, visit support.blits.ai"
      type="warning"
      v-if="showDeprecatedWarning.length"
    ></WarningBanner>
    <div class="container--content">
      <div class="content-header" v-if="bots.length > 0">
        <div class="row">
          <div class="col-4">
            <h1>Bot Overview</h1>
          </div>
          <div class="col-8 text-right">
            <button v-if="hasImportExport" type="button" class="btn btn--white" @click="$refs.file.click()">
              Import a bot
              <i class="icon icon-import"></i>
            </button>
            <button type="button" class="btn btn--purple gradient" @click="openBotDialog('create-bot')">
              Create a bot
              <i class="icon icon-add"></i>
            </button>
            <input type="file" class="main-fill-color-button" ref="file" accept=".enc" hidden @change="openBotDialog('import-bot')" />
          </div>
        </div>
      </div>
      <div class="list-options" v-if="openedDialog == 'none'">
        <Loading v-if="!hasLoaded" :title="loadingText"></Loading>
        <Loading v-if="deletingBotName" :title="`Deleting ${deletingBotName}`"></Loading>
        <div class="row" v-if="hasLoaded">
          <div v-if="bots.length == 0" class="list-options__empty">
            <div>
              <div class="select-element-icon">
                <i class="icon element-1"></i>
                <i class="icon element-2"></i>
                <i class="icon element-3"></i>
              </div>
              <h1>No bots yet!</h1>
              <div class="nobot-text">
                Let’s create
                {{ hasImportExport ? 'or import' : undefined }} your first, and very own bot!
              </div>
              <button type="button" class="btn btn--purple gradient" @click="openBotDialog('create-bot')">
                Create a bot
                <i class="icon icon-add"></i>
              </button>

              <input type="file" class="main-fill-color-button" ref="file" accept=".enc" hidden @change="openBotDialog('import-bot')" />

              <button v-if="hasImportExport" type="button" class="btn btn--white" @click="$refs['file'].click()">
                Import a bot
                <i class="icon icon-import"></i>
              </button>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-lg-4" v-for="(bot, i) in bots" :key="i">
              <router-link :to="`/bots/${bot.botName}`">
                <div :class="`list-option list-option--dashboard ${showDeprecatedWarning.includes(bot.id) ? 'warning' : ''}`">
                  <div class="list-option--information">
                    <div class="dropdown list-option--actions">
                      <a href="#" class="dropdown-toggle" v-on:click.prevent.stop="dropdownClick(bot.botName)">
                        <span></span>
                        <span></span>
                        <span></span>
                      </a>
                      <div class="dropdown-menu" :class="{ show: dropdownOpen === bot.botName }">
                        <a href="#" class="dropdown-item" @click.prevent="exportBot(bot.botName)"> <i class="icon icon-export"></i>Export Bot </a>
                        <a href="#" class="dropdown-item" @click.prevent="downloadCsv(bot.botName)"> <i class="icon icon-import"></i>Download Logs </a>
                        <router-link :to="`/bots/${bot.botName}/conversation/log`" class="dropdown-item" @click.prevent=";`/bots/${bot.botName}`">
                          <i class="icon icon-conversation"></i>View Conversations
                        </router-link>
                        <a href="#" class="dropdown-item red" @click.prevent="deleteFunction(i)"> <i class="icon icon-delete-new"></i>Delete Bot </a>
                      </div>
                    </div>

                    <h4>{{ bot.displayName || bot.botName | capitalize }}</h4>

                    <div class="language-container">
                      <label class="language-label">{{ displayLanguage(bot.settings.language) }}</label>
                      <div class="engine-icon">
                        <div
                          class="icon"
                          :class="{
                            microsoft: bot.settings.intentRecognizerProvider === 'MICROSOFT' || bot.settings.intentRecognizerProvider === 'MICROSOFT_CLU',
                            google: bot.settings.intentRecognizerProvider === 'GOOGLE',
                            ibm: bot.settings.intentRecognizerProvider === 'IBM',
                            rasa: bot.settings.intentRecognizerProvider === 'RASA',
                            amazon: bot.settings.intentRecognizerProvider === 'AMAZON',
                            wit: bot.settings.intentRecognizerProvider === 'WIT',
                            neuralspace: bot.settings.intentRecognizerProvider === 'NEURALSPACE'
                          }"
                        ></div>
                      </div>
                      <label class="region-label">{{ bot.region | botRegionFilter }}</label>
                    </div>
                  </div>
                  <ul class="list-option--dashboard">
                    <li>
                      <p>Total Interactions</p>
                      <div class="flex">
                        <span>{{ bot.allTimeData ? bot.allTimeData.interactions : '' }}</span>
                      </div>
                    </li>
                    <li>
                      <p>Intents recognized</p>
                      <div class="flex">
                        <span>{{ getIntentsRecognized(bot) }}%</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <CreateBot v-if="openedDialog == 'create-bot'" @close="reset()" :templates="botTemplates"></CreateBot>

      <ImportBot v-if="hasImportExport && openedDialog == 'import-bot'" :bots="bots" :file="$refs['file'].files[0]" @close="reset()"></ImportBot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Vue } from 'vue-property-decorator'
import CreateBot from './CreateBot.vue'
import ImportBot from './ImportBot.vue'
import Loading from '../../helpers/Loading.vue'
import DataManagementMixin from './settings/_mixins/settings/data-management.mixin'
import { capitalize } from '../../filters/string.filters'
import WarningBanner from './warning/WarningBanner.vue'
import tenantService from '../../../services/tenant.service'
import { TenantPlan } from '../../../../../common/interfaces/plans/tenant-plan.interface'
import popupService from '../../../services/popup.service'
import botService from '../../../services/bots/bot.service'
import { botRegionFilter } from './_filters/bot-region.filter'
import DropdownMixin from '@/components/admin/bots/_mixins/dropdown.mixin'
import { ExternalProvider } from '@common/enums/external-provider.enum'

@Component({
  components: {
    CreateBot,
    ImportBot,
    Loading,
    WarningBanner
  },
  filters: {
    capitalize,
    botRegionFilter
  }
})
export default class Bots extends Mixins(DataManagementMixin, DropdownMixin) {
  private msg = 'Hey there, start dash-ing stuff ...'
  private openedDialog = 'none'
  private showDeprecatedWarning = []
  private bots = []
  private hasLoaded = false
  private loads = true
  private importedFile = null
  private loadingText = 'Loading all the bots'
  private botTemplates: string[] = []
  private loadingTexts = [
    'Locating the required gigapixels to render...',
    'Warming up the flux capacitor...',
    'Shovelling coal into the server...',
    'Spinning up the hamster...',
    'Brewing some colombian coffee...',
    'Brewing some colombian coffee...',
    'Waking up the chatbot goeroes...',
    'Busy beating the turing test...'
  ]

  private isMonthLimitExceeded = false
  private tenantPlan: TenantPlan = null

  created() {
    document.querySelector('body').classList.remove('image-background')
  }

  beforeMount() {
    this.randomText()
  }

  async mounted() {
    try {
      await Promise.all([this.loadBots(), this.loadTemplates(), this.interactionsCalc(), this.getTenantPlan()])
    } finally {
      this.hasLoaded = true
      this.loads = false
    }
  }

  async interactionsCalc() {
    this.isMonthLimitExceeded = await tenantService.areInteractionExceeded()
  }

  async getTenantPlan() {
    this.tenantPlan = await tenantService.getPlan()
  }

  async loadBots() {
    this.bots = await botService.getAllBots()
    this.checkDeprecatedBots()
  }

  async loadTemplates() {
    await botService.getBotTemplates().then((templates) => (this.botTemplates = templates))
  }

  get hasImportExport() {
    return this.tenantPlan?.metadata?.hasImportExport
  }

  randomText() {
    const id = Math.floor(Math.random() * this.loadingTexts.length)
    this.loadingText = this.loadingTexts[id]
  }

  async openBotDialog(dialogName: string) {
    if (this.bots.length >= this.tenantPlan.maxFreeBots) {
      if (this.tenantPlan.hasAdditionalBotsCost) {
        if (!(await popupService.showDialog('Warning!', 'You are adding more bots outside your current plan. This will add additional cost.', 'Continue')))
          return
      } else {
        if (await popupService.showDialog('Error!', "You have reached your plan's max bots. Upgrade your plan to add more bots.", 'Upgrade'))
          this.$router.push('/configuration/subscription')
        return
      }
    }
    this.openedDialog = dialogName
  }

  reset() {
    this.openedDialog = 'none'
    ;(this.$refs.file as Vue).value = ''
  }

  deletingBotName = ''

  async deleteFunction(index: number) {
    const name = this.bots[index].botName

    if (!(await popupService.showDialog('Warning!', 'Do you really want to DELETE that bot?', 'DELETE'))) return
    this.deletingBotName = name

    const success = await this.deleteBot(name)
    if (success) {
      this.bots.splice(index, 1)
    }
    this.deletingBotName = ''
  }

  displayLanguage(language) {
    return language.toUpperCase().split('-')[0]
  }

  getIntentsRecognized(bot: any) {
    const calculated =
      bot.allTimeData && bot.allTimeData.questionsAsked ? Math.min(100, (bot.allTimeData.questionsRecognized / bot.allTimeData.questionsAsked) * 100) : 0
    return calculated % 1 === 0 ? calculated : calculated.toFixed(1)
  }

  checkDeprecatedBots() {
    this.showDeprecatedWarning = this.bots
      .filter(
        (bot) => bot.settings.intentRecognizerProvider === ExternalProvider.Microsoft || bot.settings.entityExtractorProvider === ExternalProvider.Microsoft
      )
      .map((value) => value.id)
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables';

.language-container {
  float: left;
  display: flex;
  align-items: center;
  margin-top: 5px;
  width: 100%;
}

.engine-icon {
  width: 30px;
  height: 20px;
  background-color: transparent;

  .icon {
    height: 100%; /* <------ */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &.microsoft {
      background-image: url('/public/assets/icons/icon-logo-ms.png');
    }

    &.ibm {
      background-image: url('/public/assets/img/logo-ibm.png');
    }

    &.google {
      background-image: url('/public/assets/img/logo-google.png');
    }

    &.rasa {
      background-image: url('/public/assets/img/logo-rasa.png');
    }

    &.amazon {
      background-image: url('/public/assets/img/logo-amazon.png');
    }

    &.wit {
      background-image: url('/public/assets/img/logo-wit.png');
    }

    &.neuralspace {
      background-image: url('/public/assets/img/logo-neuralspace.svg');
    }
  }
}

label {
  width: fit-content;
  background-color: $default-dark;
  color: $white;
  font-weight: bold;
  border-radius: 5px;
  padding: 1px 9px;

  &.language-label {
    margin-right: 15px;
  }

  &.region-label {
    margin-left: auto;
  }
}

h1 {
  font-size: 36px;
  font-weight: 700;
  color: $default-dark;
  line-height: 50px;
  margin: 0;
  @media only screen and (min-width: 1200px) {
    font-size: 40px;
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-option {
  border: 0;
  border-left: 10px solid $main;

  &.warning {
    border-left: 10px solid $orange;
  }

  .list-option--information {
    padding-left: 0;
  }
}

.list-option--dashboard li:first-child .flex div {
  margin-right: 15px;
}

.main-fill-color-button {
  margin-left: 15px;
}

.content-header {
  margin-bottom: 30px;
}

.list-option .list-option--actions .dropdown-toggle {
  // overly specific to override _main.scss
  margin-right: -20px;
  margin-top: 5px;
  padding: 5px 20px;

  span {
    background: $grey;
  }
}
</style>
