export enum GoogleModels {
  //gemma 1
  GEMMA = "gemma", //7b
  GEMMA_2B = "gemma:2b", //2b

  //gemma 2
  GEMMA2_2B = "gemma2:2b", //2b
  GEMMA2 = "gemma2", //9b
  GEMMA2_27B = "gemma2:27b", //27b
}

export const GoogleModelsEnabled = [GoogleModels.GEMMA2]
