<template>
  <div v-if="currentFunctionName">
    <Modal :title="`${chooseType} ${currentFunctionName} usage:`" :show="true" :confirm-close="false" @close="$emit('close')">
      <div class="form--wrapper">
        <Loading title="We are getting the data" v-if="loadingData"></Loading>
        <div v-if="!loadingData && !isGlobal && Object.keys(data).length != 0">
          <h2>{{ chooseType }} is used in intents:</h2>
          <div v-for="(intentName) in Object.keys(data)" :key="intentName">
            <hr>
            <h3>
              <strong>{{ extractName(intentName) }}</strong>
            </h3>
            <h4>In versions:</h4>
            <div v-for="(versionName) in Object.keys(data[intentName])" :key="versionName">
              Version:
              <strong>{{ extractVersion(versionName) }}</strong> -> Times used:
              <strong>{{ data[intentName][versionName] }}</strong>
            </div>
            <br/>
          </div>
        </div>

        <div v-if="!loadingData && isGlobal && Object.keys(data).length != 0">
          <h2>{{ chooseType }} is used in bots:</h2>
          <div v-for="(botName) in Object.keys(data)" :key="botName">
            <hr>
            <h3><strong>Bot-{{ botName }}</strong><br></h3>
            <div v-for="(intentName) in Object.keys(data[botName])" :key="intentName">
              <h3>
                <strong>{{ extractName(intentName) }}</strong>
              </h3>
              <h4>In versions:</h4>
              <div v-for="(versionName) in Object.keys(data[botName][intentName])" :key="versionName">
                Version:
                <strong>{{ extractVersion(versionName) }}</strong> -> Times used:
                <strong>{{ data[botName][intentName][versionName] }}</strong>
              </div>
              <br/>
            </div>
          </div>
        </div>
      </div>
      <h3 v-if="!loadingData && Object.keys(data).length == 0" style="text-align: center; color: red;">
        <br/>
        <strong>This function is not used anywhere!</strong>
      </h3>
    </Modal>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator'
import Loading from '../../../helpers/Loading.vue'
import Modal from '../../../helpers/Modal.vue'
import functionsService from '../../../../services/bots/functions.service'

@Component({
  components: {Loading, Modal}
})
export default class FunctionUsage extends Vue {
  @Prop({type: String, default: () => ''}) currentFunctionName;
  @Prop({type: String, default: () => ''}) botName;
  @Prop({type: Boolean, default: () => false}) isGlobal;
  @Prop({type: String, default: () => ''}) type;
  private loadingData = true;
  private data = {};

  async mounted() {
    this.data = this.isGlobal
        ? await functionsService.getFunctionsUsagesAllBots(
            this.currentFunctionName
        )
        : await functionsService.getFunctionsUsages(
            this.botName,
            this.currentFunctionName
        )
    this.loadingData = false
  }

  extractName(name: string) {
    return name.includes('Sub') ? name : name + ' intent'
  }

  extractVersion(intnetVersionName: string) {
    return intnetVersionName.substring(intnetVersionName.lastIndexOf('-') + 1)
  }

  get chooseType() {
    return this.type == 'flow' ? 'Sub' : 'Function'
  }
}
</script>

<style lang="scss">
@import "../../../../assets/scss/variables";
</style>
