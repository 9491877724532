<template>
  <div class="rect rect--costs" v-if="!isEmpty">
    <h1 style="text-align: left">Interactions Per Channel</h1>
    <div>
      <div class="row" v-for="(value, channel) in channels" :key="`${value}-${channel}`">
        <div class="col-8">
          <h3>{{ directlineFilter(channel) }}</h3>
        </div>
        <div class="col-4 text-right">
          <p class="amount">{{ value }}</p>
        </div>
      </div>
      <div class="row">
        <div class="rect--costs__border"></div>

        <div class="col-8">
          <h3>TOTAL</h3>
        </div>
        <div class="col-4 text-right">
          <p class="amount">{{ total }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { BotConnectionEnum } from '../../../../common/enums/bot/bot-connection.enum'

@Component
export default class Channels extends Vue {
  private isEmpty = true
  private discount = 0
  private total = 0

  @Prop({
    default: () => {
      return {}
    }
  })
  readonly channels: any
  @Prop({
    default: () => {
      return {}
    }
  })
  readonly snippets: any
  @Prop({ default: '' }) readonly month: string

  @Watch('channels')
  onChannelsChange() {
    this.init()
  }

  mounted() {
    this.init()
  }

  init() {
    this.total = Number(
      Object.values(this.channels)
        .map((e) => e)
        .reduce((a: number, b: number) => a + b, 0)
    )
    this.isEmpty = Object.keys(this.channels).length === 0
  }

  directlineFilter(text: string) {
    switch (text.toLowerCase()) {
      case 'directline':
        return 'API'
      case 'web widget':
      case BotConnectionEnum.TestChat.toLowerCase():
        return 'TEST CHAT'
      default:
        return this.snippets[text] || text
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'analytics.style';

.rect {
  height: auto;
  &--costs {
    .amount {
      color: $default-dark;
      font-size: 12px;
      font-weight: 600;
    }
    h1 {
      color: $main;
      font-size: 14px;
      font-weight: 600;
      line-height: 15px;
      text-transform: uppercase;
    }
    h2,
    h3,
    p {
      margin: 0;
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      line-height: 23px;
      margin-bottom: 15px;
    }
    h3 {
      font-size: 12px;
      font-weight: 600;
      color: $secondary-text;
      line-height: 15px;
      text-transform: uppercase;
    }
    p {
      position: relative;
      top: 1px;
      font-size: 14px;
      font-weight: 400;
      color: #000;
    }
    &__border {
      display: inline-block;
      border-top: 1px solid #d8d8d8;
      width: 100%;
      margin: 8px 15px 12px;
    }
  }
}
</style>
