import axios from 'axios'
import { WitAppLanguage } from '../../../../../common/enums/wit/wit-app-language.enum'
import { getRelativeLanguageKey } from '../../../../../common/helpers/nlu-language.helper'
import store from '@/store'

export class GeneralSettingsService {
  constructor(private botName: string) {}

  /*
   * The frontend is setup to set the logic of the language on creating a bot, depending on the recognizer provider.
   * When we load the bot's settings we need to set the language to the relative language to store the properties correct.
   * The flow properties get stored on the language for multiple language support. So we need to set the language to the relative language,
   * so we have a common language if we switch engines.
   */

  async getSettings(): Promise<{ displayName: string; settings: any; botAvatar: string }> {
    const res = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${this.botName}/settings`)

    // Get the relative language to the recognizer provider where we use Wit as it has the most languages as the default enum for language.
    const relativeLanguage = getRelativeLanguageKey(res.data.settings.language, WitAppLanguage)

    // If the relative language is not found, we set the language to the default language of the recognizer provider,
    // But this can be a problem if we switch engines where the supported language doesn't exist.
    let currentLanguage = res.data.settings.language
    let supportedLanguages = res.data.settings.supportedLanguages

    // If the relative language is found, we set the language to the relative language.
    if (relativeLanguage) {
      currentLanguage = relativeLanguage
    }

    store.commit('setCurrentLanguage', currentLanguage)
    store.commit('setBotDefaultLanguage', currentLanguage)

    // Make sure the current language is in the supported languages
    if (!supportedLanguages.includes(currentLanguage)) {
      supportedLanguages.push(currentLanguage)
    }

    // Older bots before this functinoaliteit might be stored on English instead of en, so we add it to the supported languages for backwards compatibility.
    if (!supportedLanguages.includes('English') && currentLanguage !== 'English') {
      supportedLanguages.push('English')
    }

    store.commit('setSupportedLanguages', supportedLanguages)

    return res.data
  }

  updateSettings(displayName: string, settings: any, botAvatar: string) {
    return axios.put(`${process.env.VUE_APP_BACKEND_URL}/bots/${this.botName}/settings`, {
      settings,
      displayName,
      botAvatar
    })
  }
}
