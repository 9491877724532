<template>
  <div class="wrapper-inside general-settings active settings-wrapper">
    <div class="form-section">
      <h4>User Consent / Opt-in</h4>

      <Toggle
        label="Ask users for consent to process sensitive or personal data"
        v-model="gdprSettings.askForConsent"
        tooltip="If enabled, every conversation will start with a consent question."
        :disabled="!hasSufficientPermission"
      ></Toggle>
      <!-- <Toggle label="Disable bot if user declines" v-if="isTenantAdmin && gdprSettings.askForConsent" v-model="gdprSettings.canDisable" tooltip="Enable if you want to make sure only users that accept can use the bot."></Toggle> -->
      <Toggle
        label="Disable bot if user declines"
        v-if="gdprSettings.askForConsent"
        v-model="gdprSettings.canDisable"
        tooltip="Enable if you want to make sure only users that accept can use the bot."
        :disabled="!hasSufficientPermission"
      ></Toggle>

      <div v-if="gdprSettings.askForConsent">
        <FormGroup label="Consent message">
          <input
            type="text"
            v-model="gdprSettings.consentMessage"
            :disabled="!hasSufficientPermission"
          />
        </FormGroup>

        <FormGroup label="Accept message">
          <input
            type="text"
            v-model="gdprSettings.consentAgreeMessage"
            :disabled="!hasSufficientPermission"
          />
        </FormGroup>

        <FormGroup label="Decline message">
          <input
            type="text"
            v-model="gdprSettings.consentRejectMessage"
            :disabled="!hasSufficientPermission"
          />
        </FormGroup>

        <FormGroup label="Your privacy statement URL" tooltip="Leave empty to hide">
          <input type="text" v-model="gdprSettings.consentUrl" :disabled="!hasSufficientPermission" />
        </FormGroup>

        <!-- <FormGroup label="Disabled message" v-if="gdprSettings.canDisable"> -->
        <FormGroup label="Disabled message" v-if="gdprSettings.canDisable">
          <input
            type="text"
            v-model="gdprSettings.disableMessage"
            :disabled="!hasSufficientPermission"
          />
        </FormGroup>
      </div>

      <button
        type="button"
        class="btn btn--white margin-correct"
        @click="downloadConsentCsv"
        :disabled="!logsPermission"
      >
        Download GDPR Consent Logs (CSV)
      </button>
    </div>

    <div class="form-section">
      <h4>Personal Data</h4>

      <Toggle
        label="Enable 'forget me' function"
        tooltip="If enabled, user can type 'forget me' in chat to remove all their stored chat data."
        v-model="gdprSettings.removeUserData"
        :disabled="!hasSufficientPermission"
      ></Toggle>

      <div v-if="gdprSettings.removeUserData">
        <FormGroup label="Message when the user's data is deleted">
          <input
            type="text"
            v-model="gdprSettings.removeUserDataMessage"
            :disabled="!hasSufficientPermission"
          />
        </FormGroup>
      </div>
    </div>

    <div class="form-section">
      <h4>Masking of Personal Data</h4>
      <Toggle
        label="Enable masking of personal data"
        tooltip="Masks sensitive or personal data in the conversation logs"
        v-model="gdprSettings.maskPersonalData"
        :disabled="!hasSufficientPermission"
      ></Toggle>

      <div v-if="gdprSettings.maskPersonalData">
        <Toggle
          label="Enable masking personal data to AI service (Google, Microsoft, etc.)"
          tooltip="If enabled, the system will limit the data sent to AI services."
          v-model="gdprSettings.maskAIData"
          :disabled="!hasSufficientPermission"
        ></Toggle>
      </div>

      <FormGroup
        label="Add masking patterns (RegEx)"
        v-if="gdprSettings.maskPersonalData  && hasSufficientPermission"
        tooltip="Add you own masking patterns for sensitive or personal data here."
      >
        <div class="row" v-for="(_, index) in gdprSettings.maskingPatterns" :key="index">
          <div class="form-group col-md-3">
            <input
              type="text"
              placeholder="Name"
              v-model="gdprSettings.maskingPatterns[index].name"
              class="form-control"
              :disabled="!hasSufficientPermission"
            />
          </div>
          <div class="form-group col-md-8">
            <input
              type="text"
              placeholder="Masking pattern"
              v-model="gdprSettings.maskingPatterns[index].pattern"
              class="form-control"
              :disabled="!hasSufficientPermission"
            />
          </div>

          <div class="col-md-1">
            <span @click="removeMaskingPattern(index)" class="delete"></span>
          </div>
        </div>
        <button
          class="btn btn-add"
          type="button"
          @click="addMaskingPattern"
          :disabled="!hasSufficientPermission"
        >
          <i class="plus"></i>
        </button>
      </FormGroup>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from 'vue-property-decorator'
import Toggle from '../../../../../helpers/Toggle.vue'
import FormGroup from '../../../../../helpers/FormGroup.vue'
import { Permissions } from '@common/enums/tenant/user-permissions.enum'
import permissionsService from '../../../../../../services/tenants/permissions.service'
import gdprManagementMixin from '../../_mixins/settings/gdpr-management.mixin'
import dataManagementMixin from '../../_mixins/settings/data-management.mixin'
import popupService from '@/services/popup.service'

@Component({
  components: { Toggle, FormGroup }
})
export default class GDPR extends Mixins(gdprManagementMixin, dataManagementMixin) {
  @Prop() isTenantAdmin: boolean

  botName = this.$router.history.current.params.botName

  public hasSufficientPermission = false
  private logsPermission = false

  async beforeMount() {
    this.initData(this.botName);
    await this.initGdpr(this.botName);
    [
      this.hasSufficientPermission,
      this.logsPermission
    ] = await permissionsService.hasAllPermissions(this.botName,
      Permissions.ManageGDPRSettings,
      Permissions.ExportLogs
    )
    this.$emit('on-permission-change')
  }

  public save() {
    try {
      this.updateGdpr()
      popupService.showInfo('Saved!')
    } catch (err) {
      const message = err.response ? err.response.data.message : err.message
      popupService.showError('Error!', message)
    }
  }

  addMaskingPattern() {
    this.gdprSettings.maskingPatterns.push({})
  }

  removeMaskingPattern(index: number) {
    this.gdprSettings.maskingPatterns.splice(index, 1)
  }
}
</script>
