<template>
  <div class="variables-panel variables-panel-2">
    <!-- Custom function type -->
    <div class="form-group" v-if="this.currentBlock.category == 'Function'">
      <label for="function-type">
        Type
        <Tooltip title="This dropdown determines the function type." placement="bottom"></Tooltip>
      </label>

      <div class="form-group--inside">
        <Select
          id="type"
          :elements="Object.entries(customFunctionTypes)"
          :key="currentCustomFunctionType"
          keyAttr="0"
          valueAttr="1"
          defaultText="Select a type"
          :disableDefault="true"
          v-model="currentCustomFunctionType"
          @input="changeCustomFunctionType"
          :disabled="!hasSufficientPermission"
        ></Select>
      </div>
    </div>
    <div class="form-group" v-if="functions.length > 0 && currentBlock.category !== 'llm'">
      <label for="function">
        {{ currentBlock.category || 'Function' }}
        <Tooltip title="This dropdown defines which function this block serves." placement="bottom"></Tooltip>
      </label>

      <div class="form-group--inside">
        <div class="square-button-container">
          <div v-for="(type, index) in functions" :key="index">
            <div class="button-box" v-if="!type.hide">
              <button
                class="select-button"
                :disabled="!hasSufficientPermission"
                v-on:click="changeFunction(type.key)"
                :class="{ 'is-active': currentBlock.customData.fc === type.key }"
              >
                <div v-if="type.functionName === 'Dictionary'" class="image icon-dictionary" />
                <div v-if="type.functionName === 'Message'" class="image icon-message" />
                <div v-if="type.functionName === 'Send Image'" class="image icon-image" />
                <div v-if="type.functionName === 'Ask question'" class="image icon-question" />
                <div v-if="type.functionName === 'Rating'" class="image icon-rating" />
                <div v-if="type.functionName === 'Random Answer'" class="image icon-random" />
                <div v-if="type.functionName === 'Multiple Entity check'" class="image icon-entity" />
                <div v-if="type.functionName === 'Send attachment'" class="image icon-send" />
                <div v-if="type.functionName === 'Receive attachment'" class="image icon-receive" />
                <div v-if="type.functionName === 'Show options'" class="image icon-options" />
                <div v-if="type.functionName === 'Unexpected Answer'" class="image icon-unexpected" />
                <div v-if="type.functionName === 'Counter'" class="image icon-counter" />
                <div v-if="type.functionName === 'Simple Calculation'" class="image icon-calc" />
                <div v-if="type.functionName === 'Redirect Call'" class="image icon-phone" />
                <div v-if="type.functionName === 'Set Variable'" class="image icon-variable" />
                <div v-if="type.functionName === 'Set Object'" class="image icon-object" />
                <div v-if="type.functionName === 'Recognize intent'" class="image icon-detect" />
                <div v-if="type.functionName === 'User Statistics'" class="image icon-statistics" />
                <div v-if="type.functionName === 'Replace in variable'" class="image icon-replace" />
                <div v-if="type.functionName === 'Send Conversation History'" class="image icon-history" />
                <div v-if="type.functionName === 'Agent Handover'" class="image icon-agent" />
                <div v-if="type.functionName === 'Send Email'" class="image icon-email" />
                <div v-if="type.functionName === 'Language Translate'" class="image icon-translate" />
                <div v-if="type.functionName === 'Language Detection'" class="image icon-detectlanguage" />
                <div v-if="type.functionName === 'Validation'" class="image icon-validation" />
                <div v-if="type.functionName === 'Delay Block'" class="image icon-pause-circle" />
                <div v-if="type.type === 'rest'" class="image icon-rest" />
                <div v-if="type.type === 'sql'" class="image icon-sql" />
                <div v-if="type.type === 'adaptiveCard'" class="image icon-ac" />
                <div v-if="type.type === 'code'" class="image icon-code" />
                <div v-if="type.type === 'flow'" class="image icon-sub" />
                <div v-if="type.type === 'gpt3'" class="image icon-code" />
                <div class="text">{{ type.functionName }}</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group" v-else-if="currentCustomFunctionType && currentBlock.category !== 'llm'">
        No {{ !this.currentBlock.category || this.currentBlock.category == 'Subs' ? 'subs' : 'function' }} for {{ containerName }} available yet. You can add
        one in the {{ !this.currentBlock.category || this.currentBlock.category == 'Subs' ? 'Subs' : 'Functions' }} tab.</label
      >
    </div>

    <SetObjectExtension
      v-if="currentBlock.customData.fc == 'setObject'"
      :currentBlock="currentBlock"
      :objects="objects"
      :hasSufficientPermission="hasPermissionToEditTexts"
    />

    <div v-if="currentFunction.functionDescription" class="form-group image-dropdown">
      <label for="description">{{ currentFunction.functionDescription }}</label>
    </div>

    <EngineProviderExtension :fc="currentBlock.customData.fc" :settings="settings" />

    <!-- Language selector for multi-language-settings -->
    <div class="form-group" v-if="supportedLanguages.length > 1">
      <label for="function-type">
        Language selector
        <Tooltip title="Select the language of the block." placement="bottom"></Tooltip>
      </label>
      <Dropdown
        :items="supportedLanguages"
        :value="currentLanguage"
        @input="setCurrentLanguage($event)"
        :disabled="!hasSufficientPermission"
      />
    </div>

    <ShowOptionsExtension
      v-if="currentBlock.customData.fc == 'showOptions'"
      :currentBlock="currentBlock"
      :variableNames="variablesAndEntitiesRoles"
      :hasSufficientPermission="hasPermissionToEditTexts"
    />

    <MultipleEntityCheckExtension
      v-if="currentBlock.customData.fc == 'multipleEntityCheck'"
      :currentBlock="currentBlock"
      :entitiesFromExamples="entitiesFromExamples"
      :variableNames="variablesAndEntitiesRoles"
      :hasSufficientPermission="hasPermissionToEditTexts"
    />

    <!-- Fields -->
    <Fields
      :currentBlock="currentBlock"
      :variableNames="variablesAndEntitiesRoles"
      :currentFunction="currentFunction"
      :hasSufficientPermission="hasPermissionToEditTexts"
    />

    <DictionaryExtension
      v-if="currentBlock.customData.fc == 'dictionary'"
      :currentBlock="currentBlock"
      :variables="variablesAndEntities"
      :variableNames="variablesAndEntitiesRoles"
      :hasSufficientPermission="hasPermissionToEditTexts"
    />

    <AdaptiveCardExtension
      v-if="currentBlock.customData.fc == 'adaptiveCard'"
      :currentBlock="currentBlock"
      :hasSufficientPermission="hasPermissionToEditTexts"
    />

    <ShowRatingExtension
      v-if="currentBlock.customData.fc == 'showRating'"
      :currentBlock="currentBlock"
      :variableNames="variablesAndEntitiesRoles"
      :hasSufficientPermission="hasPermissionToEditTexts"
    />

    <ReplaceInVariableExtension
      v-if="currentBlock.customData.fc == 'replaceInVariable'"
      :currentBlock="currentBlock"
      :variables="variablesAndEntities"
      :hasSufficientPermission="hasPermissionToEditTexts"
    />

    <RandomAnswerExtension
      v-if="currentBlock.customData.fc == 'randomAnswer'"
      :currentBlock="currentBlock"
      :variables="variablesAndEntities"
      :hasSufficientPermission="hasPermissionToEditTexts"
    />

    <SimpleCalculationExtension
      v-if="currentBlock.customData.fc == 'simpleCalculationBlock'"
      :currentBlock="currentBlock"
      :variableNames="variablesAndEntitiesRoles"
      :hasSufficientPermission="hasPermissionToEditTexts"
    />

    <UploadFileExtension
      v-if="currentBlock.customData.fc == 'sendAttachment'"
      :containerName="containerName"
      :currentBlock="currentBlock"
      :hasSufficientPermission="hasPermissionToEditTexts"
      :hasSufficientPermissionManageFlows="hasSufficientPermission"
    />

    <UploadFileExtension
      v-if="currentBlock.customData.fc == 'sendGif'"
      :containerName="containerName"
      acceptedFiles=".png, .gif"
      :currentBlock="currentBlock"
      :isGifBlock="true"
      :hasSufficientPermission="hasPermissionToEditTexts"
      :hasSufficientPermissionManageFlows="hasSufficientPermission"
    />

    <ReceiveUserAttachmentExtension
      v-if="currentBlock.customData.fc == 'receiveUserAttachment'"
      :currentBlock="currentBlock"
      :variables="variablesAndEntities"
      :hasSufficientPermission="hasPermissionToEditTexts"
    />

    <UnexpectedAnswerExtension
      v-if="currentBlock.customData.fc == 'unexpectedAnswer'"
      :currentBlock="currentBlock"
      :variableNames="variablesAndEntitiesRoles"
      :hasSufficientPermission="hasPermissionToEditTexts"
    />

    <AskQuestionExtension
      v-if="currentBlock.customData.fc == 'askQuestion'"
      :currentBlock="currentBlock"
      :variableNames="variablesAndEntitiesRoles"
      :hasSufficientPermission="hasPermissionToEditTexts"
    />

    <LLMBlock
      v-if="currentBlock.category === 'llm'"
      @changeFunction="changeFunctionLLM($event)"
      :currentBlock="currentBlock"
      :allLLMs="allLLMs"
      :variables="variablesAndEntitiesRoles"
      :hasSufficientPermission="hasPermissionToEditLLMs"
    />

    <ValidationExtension
      v-if="currentBlock.customData.fc == BlockType.ValidationBlock"
      :intents="intents"
      :currentBlock="currentBlock"
      :bpmnManagement="bpmnManagement"
      :botName="containerName"
      :entities="entities"
      :channels="channels"
      :snippets="snippets"
      :variables="variables"
      :variablesAndEntities="variablesAndEntities"
      :variablesAndEntitiesRoles="variablesAndEntitiesRoles"
    />

    <!-- Outputs -->
    <Outputs
      :key="this.currentFunction.key"
      v-if="(isCustomFunction || (this.currentFunction.variables && this.currentFunction.variables[0])) && functions.length > 0"
      :currentBlock="currentBlock"
      :variables="variablesAndEntities"
      :currentFunction="this.currentFunction"
      :bpmnManagement="bpmnManagement"
      :outputs="currentFunction ? currentFunction.outputs : []"
      :disabled="!hasSufficientPermission"
    />
  </div>
</template>

<script lang="ts">
import axios from 'axios'

import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import { possibleFunctions } from '@/components/admin/flow-editor/modules/block.config'
import { BpmnManagementService } from '@/components/admin/flow-editor/modules/bpmn-management.service'
import FlowComponent from '@/components/admin/flow-editor/modules/FlowComponent'
import NavTabs from '@/components/admin/bots/nav-tabs/NavTabs.vue'

import Fields from './Fields.vue'
import Outputs from './Outputs.vue'

import SetObjectExtension from './block-extensions/SetObjectExtension.vue'
import MultipleEntityCheckExtension from './block-extensions/MultipleEntityCheckExtension.vue'
import DictionaryExtension from './block-extensions/DictionaryExtension.vue'
import ShowOptionsExtension from './block-extensions/ShowOptionsExtension.vue'
import ShowRatingExtension from './block-extensions/ShowRatingExtension.vue'
import UnexpectedAnswerExtension from './block-extensions/UnexpectedAnswerExtension.vue'
import ReplaceInVariableExtension from './block-extensions/ReplaceInVariableExtension.vue'
import RandomAnswerExtension from './block-extensions/RandomAnswerExtension.vue'
import UploadFileExtension from './block-extensions/UploadFileExtension.vue'
import SimpleCalculationExtension from './block-extensions/SimpleCalculationExtension.vue'
import AskQuestionExtension from './block-extensions/AskQuestionExtension.vue'
import EngineProviderExtension from './block-extensions/EngineProviderExtension.vue'
import ValidationExtension from './block-extensions/ValidationExtension.vue'
import ReceiveUserAttachmentExtension from './block-extensions/ReceiveUserAttachmentExtension.vue'

import LLMBlock from '../llm-block/LLMBlock.vue'

import Select from '@/components/helpers/Select.vue'
import Tooltip from '@/components/helpers/Tooltip.vue'
import Dropdown from '@/components/helpers/Dropdown.vue'

import permissionsService from '@/services/tenants/permissions.service'

import { capitalize } from '@/components/filters/string.filters'

import { BlockType } from '@common/enums/block/block-type.enum'
import { BotConnection } from '@common/connections/bot-connection.model'
import { FunctionType } from '@common/enums/bot/functions/function-type.enum'
import { CluAppLanguage } from '@common/enums/microsoft/clu-app.language.enum'
import { Permissions } from '@common/enums/tenant/user-permissions.enum'
import { IBlock } from '@common/blocks/block.interface'

@Component({
  computed: {
    CluAppLanguage() {
      return CluAppLanguage
    }
  },
  methods: { capitalize },
  components: {
    Dropdown,
    NavTabs,
    ReceiveUserAttachmentExtension,
    Fields,
    Outputs,
    Select,
    SetObjectExtension,
    DictionaryExtension,
    MultipleEntityCheckExtension,
    ShowOptionsExtension,
    ShowRatingExtension,
    ReplaceInVariableExtension,
    RandomAnswerExtension,
    Tooltip,
    UploadFileExtension,
    SimpleCalculationExtension,
    UnexpectedAnswerExtension,
    AskQuestionExtension,
    EngineProviderExtension,
    ValidationExtension,
    LLMBlock
  }
})
export default class TaskBlock extends Vue {
  BlockType = BlockType

  readonly customFunctionTypes = {
    REST: FunctionType.REST,
    SQL: FunctionType.SQL,
    'Adaptive Card': FunctionType.AdaptiveCard,
    CODE: FunctionType.Code,
    'Capture Storage (BETA)': FunctionType.CaptureStorage,
    'Send Event (BETA)': FunctionType.SendEvent,
    GPT: FunctionType['GPT-3']
  }

  currentCustomFunctionType = ''
  allLLMs = []
  loadedllms = []
  
  @Prop({ default: () => ({}) }) readonly customFunctions!: any[]
  @Prop({ default: () => ({}) }) readonly currentBlock!: FlowComponent<IBlock>
  @Prop({ default: () => ({}) }) readonly bpmnManagement!: BpmnManagementService
  @Prop({ default: () => [] }) readonly variables!: any[]
  @Prop({ default: () => [] }) readonly variablesAndEntities!: any[]
  @Prop({ default: () => [] }) readonly variablesAndEntitiesRoles!: any[]
  @Prop({ default: () => [] }) readonly entities!: any[]
  @Prop({ default: () => [] }) readonly channels: BotConnection[]
  @Prop({ default: () => [] }) readonly snippets: any[]
  @Prop({ default: () => ({}) }) readonly objects!: {}
  @Prop({ default: () => '' }) readonly containerName!: string
  @Prop({ default: () => [] }) readonly entitiesFromExamples: Array<string>
  @Prop({ default: () => ({}) }) readonly settings: any
  @Prop({ default: () => [] }) readonly intents!: any[]
  @Prop() readonly isWelcomeIntent: boolean

  setCurrentLanguage(tab: string) {
    this.$store.commit('setCurrentLanguage', tab)
  }
  
  get currentLanguage() {
    return this.$store.state.currentLanguage
  }

  get supportedLanguages() {
    return this.$store.state.supportedLanguages
  }

  hasSufficientPermission = false
  hasPermissionToEditTexts = false
  hasPermissionToEditLLMs = false

  async loadLLMs() {
    this.allLLMs = (await axios.get(`${process.env.VUE_APP_BACKEND_URL}/llm`)).data
  }

  get isCustomFunction() {
    return this.currentBlock.category == 'Function' || this.currentBlock.category == 'Subs'
  }

  get functions() {
    let functions = this.isCustomFunction
      ? this.customFunctions.filter((f) => f.type == this.currentCustomFunctionType)
      : Object.values(possibleFunctions).filter((f) => f.category == this.currentBlock.category)

    if (this.isWelcomeIntent && !this.isCustomFunction) {
      functions = functions.filter((f) => !f.hideInWelcome)
    }
    return functions
  }

  get currentFunction() {
    return (
      this.functions.find((f) => f.key == this.currentBlock.customData.fc) || {
        variables: []
      }
    )
  }

  get permissions() {
    return Permissions
  }

  async created() {
    this.loadLLMs()
    this.hasSufficientPermission = await permissionsService.hasPermission(this.containerName, this.permissions.ManageFlowsInDialogManager)
    this.hasPermissionToEditTexts = await permissionsService.hasPermission(this.containerName, this.permissions.ManageFlowsInDialogManager)
    this.hasPermissionToEditLLMs = await permissionsService.hasPermission(this.containerName, this.permissions.ManageLLMs)

    const el = this.customFunctions.find((e) => e.functionName === this.currentBlock.customData.fc)
    this.setCurrentCustomFunctionType(el)
    this.changeFunction(this.currentBlock.customData.fc)
  }

  setCurrentCustomFunctionType(el) {
    if (this.currentBlock.category == 'Subs' || !this.currentBlock.category) {
      this.currentCustomFunctionType = 'flow'
    } else this.currentCustomFunctionType = el && el.type ? el.type : ''
  }

  // Choses the first function from the current type, if any
  changeCustomFunctionType() {
    this.$parent.$emit('updateFunctionsRestrictions', {
      type: this.currentCustomFunctionType,
      data: this.currentBlock
    })
    this.changeFunction(this.functions.length > 0 ? this.functions[0].key : '')
  }

  changeFunction(newFc: string) {
    let oldFc
    for (const lang of this.$store.state.supportedLanguages) {
      console.log('lang', lang)
      oldFc = this.currentBlock._customData[lang].fc
      this.currentBlock._customData[lang].fc = newFc

      if (oldFc !== newFc) {
        this.currentBlock._customData[lang]['fc-output'] = this.currentBlock._customData[lang]['fc-output-default'] = []
      }
    }

    this.updateLabel(this.currentBlock.id, oldFc, newFc)
    this.$emit('blockFieldChange')
  }

  changeFunctionLLM(options) {
    const { newFc, llmId, llmInput, llmContext, recognizerExit, exitInactivity, maxMessages } = options

    let oldFc
    for (const lang of this.$store.state.supportedLanguages) {
      oldFc = this.currentBlock._customData[lang].fc
      this.currentBlock._customData[lang].fc = newFc
      this.currentBlock._customData[lang]['llmId'] = llmId
      this.currentBlock._customData[lang]['llmInput'] = llmInput
      this.currentBlock._customData[lang]['llmContext'] = llmContext
      this.currentBlock._customData[lang]['recognizerExit'] = recognizerExit
      this.currentBlock._customData[lang]['exitInactivity'] = exitInactivity
      this.currentBlock._customData[lang]['maxMessages'] = maxMessages

      if (oldFc !== newFc) {
        this.currentBlock._customData[lang]['fc-output'] = this.currentBlock._customData[lang]['fc-output-default'] = []
      }
    }

    this.updateLabel(this.currentBlock.id, oldFc, newFc)
    this.$emit('blockFieldChange')
  }

  @Emit()
  updateLabel(id: string, oldFc: string, newFc: string) {
    const elementTextContent = this.currentBlock.gfx.firstChild.textContent

    const text = oldFc === newFc && this.currentBlock.category !== elementTextContent ? elementTextContent : this.currentFunction.functionName

    return { id, text }
  }
}
</script>

<style lang="scss">
@import '../../../../../../assets/scss/variables';

.variables-panel .form-group {
  position: unset !important;
}

.add-new {
  padding: 10px;
  text-align: center;
  border: 1px dotted $main;
  color: $main;
  cursor: pointer;
}

.square-button-container {
  width: 285px;
  display: inline-block;
}

.language-selection-tab {
  margin-bottom: 20px;
}

.button-box {
  .select-button {
    width: 85px;
    height: 85px;
    margin: 5px;
    background-color: $blue;
    float: left;
    border-radius: 6px;
    border: 0 solid transparent;
    overflow: hidden;

    .icon-message {
      background: url('/public/assets/icons/icon-dm-message.svg') no-repeat;
    }

    .icon-dictionary {
      background: url('/public/assets/icons/icon-dm-dictionary.svg') no-repeat;
    }

    .icon-image {
      background: url('/public/assets/icons/icon-dm-image.svg') no-repeat;
    }

    .icon-question {
      background: url('/public/assets/icons/icon-dm-question.svg') no-repeat;
    }

    .icon-random {
      background: url('/public/assets/icons/icon-dm-random.svg') no-repeat;
    }

    .icon-rating {
      background: url('/public/assets/icons/icon-dm-rating.svg') no-repeat;
    }

    .icon-entity {
      background: url('/public/assets/icons/icon-dm-entity.svg') no-repeat;
    }

    .icon-send {
      background: url('/public/assets/icons/icon-dm-sendat.svg') no-repeat;
    }

    .icon-receive {
      background: url('/public/assets/icons/icon-dm-receiveat.svg') no-repeat;
    }

    .icon-options {
      background: url('/public/assets/icons/icon-dm-options.svg') no-repeat;
      margin-top: 14px;
      margin-left: 26px;
      margin-bottom: -4px;
    }

    .icon-unexpected {
      background: url('/public/assets/icons/icon-dm-unexpected.svg') no-repeat;
      margin-left: 30px;
    }

    .icon-counter {
      background: url('/public/assets/icons/icon-dm-counter.svg') no-repeat;
      margin-left: 26px;
    }

    .icon-calc {
      background: url('/public/assets/icons/icon-dm-calc.svg') no-repeat;
      margin-left: 27px;
      margin-bottom: -4px;
    }

    .icon-phone {
      background: url('/public/assets/icons/icon-dm-phone.svg') no-repeat;
    }

    .icon-agent {
      background: url('/public/assets/icons/icon-dm-agent.svg') no-repeat;
      margin-bottom: -5px;
    }

    .icon-detect {
      background: url('/public/assets/icons/icon-dm-detect.svg') no-repeat;
      margin-bottom: -4px;
    }

    .icon-history {
      background: url('/public/assets/icons/icon-dm-history.svg') no-repeat;
      margin-left: 26px;
      margin-bottom: -6px;
    }

    .icon-email {
      background: url('/public/assets/icons/icon-dm-mail.svg') no-repeat;
      margin-left: 26px;
      margin-bottom: -4px;
    }

    .icon-object {
      background: url('/public/assets/icons/icon-dm-object.svg') no-repeat;
      margin-left: 28px;
    }

    .icon-variable {
      background: url('/public/assets/icons/icon-dm-variable.svg') no-repeat;
      margin-left: 26px;
    }

    .icon-statistics {
      background: url('/public/assets/icons/icon-dm-statistics.svg') no-repeat;
      margin-left: 26px;
      margin-bottom: -6px;
    }

    .icon-replace {
      background: url('/public/assets/icons/icon-dm-replace.svg') no-repeat;
      margin-bottom: -4px;
    }

    .icon-sql {
      background: url('/public/assets/icons/icon-dm-sql.svg') no-repeat;
      margin-left: 26px;
    }

    .icon-rest {
      background: url('/public/assets/icons/icon-dm-rest.svg') no-repeat;
    }

    .icon-code {
      background: url('/public/assets/icons/icon-dm-code.svg') no-repeat;
    }

    .icon-ac {
      background: url('/public/assets/icons/icon-dm-ac.svg') no-repeat;
    }

    .icon-sub {
      background: url('/public/assets/icons/icon-dm-sub.svg') no-repeat;
      margin-left: 26px;
    }

    .icon-equals {
      background: url('/public/assets/icons/icon-dm-equals.svg') no-repeat;
      margin-left: 26px;
    }

    .icon-exists {
      background: url('/public/assets/icons/icon-dm-exists.svg') no-repeat;
      margin-bottom: -5px;
      margin-left: 28px;
    }

    .icon-similar {
      background: url('/public/assets/icons/icon-dm-similar.svg') no-repeat;
      margin-left: 26px;
    }

    .icon-entity2 {
      background: url('/public/assets/icons/icon-dm-entity2.svg') no-repeat;
      margin-left: 24px;
    }

    .icon-type {
      background: url('/public/assets/icons/icon-dm-type.svg') no-repeat;
      margin-left: 26px;
    }

    .icon-set {
      background: url('/public/assets/icons/icon-dm-set.svg') no-repeat;
      margin-left: 26px;
    }

    .icon-channel {
      background: url('/public/assets/icons/icon-dm-channel.svg') no-repeat;
      margin-left: 24px;
    }

    .icon-user {
      background: url('/public/assets/icons/icon-dm-user.svg') no-repeat;
      margin-left: 24px;
    }

    .icon-regex {
      background: url('/public/assets/icons/icon-regex.svg') no-repeat;
      margin-left: 24px;
    }

    .icon-contains {
      background: url('/public/assets/icons/icon-dm-contains.svg') no-repeat;
      margin-left: 24px;
    }

    .icon-translate {
      background: url('/public/assets/icons/icon-dm-translate.svg') no-repeat;
      margin-left: 24px;
    }

    .icon-detectlanguage {
      background: url('/public/assets/icons/icon-dm-detectlanguage.svg') no-repeat;
      margin-left: 20px;
      margin-bottom: -5px;
    }

    .icon-validation {
      background: url('/public/assets/icons/icon-validation.svg') no-repeat;
      margin-left: 25px;
    }

    .icon-pause-circle {
      background: url('/public/assets/icons/icon-dm-pause-circle.svg') no-repeat;
      margin-left: 25px;
    }

    &:active,
    &:hover,
    &:focus,
    &.is-active {
      border: 2px solid $main;

      .text {
        color: $main;
      }

      .icon-message {
        background: url('/public/assets/icons/icon-dm-message-active.svg') no-repeat;
      }

      .icon-dictionary {
        background: url('/public/assets/icons/icon-dm-dictionary-active.svg') no-repeat;
      }

      .icon-image {
        background: url('/public/assets/icons/icon-dm-image-active.svg') no-repeat;
      }

      .icon-question {
        background: url('/public/assets/icons/icon-dm-question-active.svg') no-repeat;
      }

      .icon-random {
        background: url('/public/assets/icons/icon-dm-random-active.svg') no-repeat;
      }

      .icon-rating {
        background: url('/public/assets/icons/icon-dm-rating-active.svg') no-repeat;
      }

      .icon-entity {
        background: url('/public/assets/icons/icon-dm-entity-active.svg') no-repeat;
      }

      .icon-send {
        background: url('/public/assets/icons/icon-dm-sendat-active.svg') no-repeat;
      }

      .icon-receive {
        background: url('/public/assets/icons/icon-dm-receiveat-active.svg') no-repeat;
      }

      .icon-options {
        background: url('/public/assets/icons/icon-dm-options-active.svg') no-repeat;
      }

      .icon-counter {
        background: url('/public/assets/icons/icon-dm-counter-active.svg') no-repeat;
      }

      .icon-calc {
        background: url('/public/assets/icons/icon-dm-calc-active.svg') no-repeat;
      }

      .icon-phone {
        background: url('/public/assets/icons/icon-dm-phone-active.svg') no-repeat;
      }

      .icon-phone {
        background: url('/public/assets/icons/icon-dm-phone-active.svg') no-repeat;
      }

      .icon-agent {
        background: url('/public/assets/icons/icon-dm-agent-active.svg') no-repeat;
        margin-bottom: -5px;
      }

      .icon-detect {
        background: url('/public/assets/icons/icon-dm-detect-active.svg') no-repeat;
      }

      .icon-history {
        background: url('/public/assets/icons/icon-dm-history-active.svg') no-repeat;
      }

      .icon-email {
        background: url('/public/assets/icons/icon-dm-mail-active.svg') no-repeat;
      }

      .icon-object {
        background: url('/public/assets/icons/icon-dm-object-active.svg') no-repeat;
      }

      .icon-variable {
        background: url('/public/assets/icons/icon-dm-variable-active.svg') no-repeat;
      }

      .icon-statistics {
        background: url('/public/assets/icons/icon-dm-statistics-active.svg') no-repeat;
      }

      .icon-replace {
        background: url('/public/assets/icons/icon-dm-replace-active.svg') no-repeat;
      }

      .icon-sql {
        background: url('/public/assets/icons/icon-dm-sql-active.svg') no-repeat;
        margin-left: 26px;
      }

      .icon-rest {
        background: url('/public/assets/icons/icon-dm-rest-active.svg') no-repeat;
      }

      .icon-code {
        background: url('/public/assets/icons/icon-dm-code-active.svg') no-repeat;
      }

      .icon-ac {
        background: url('/public/assets/icons/icon-dm-ac-active.svg') no-repeat;
      }

      .icon-sub {
        background: url('/public/assets/icons/icon-dm-sub-active.svg') no-repeat;
      }

      .icon-equals {
        background: url('/public/assets/icons/icon-dm-equals-active.svg') no-repeat;
        margin-left: 24px;
      }

      .icon-exists {
        background: url('/public/assets/icons/icon-dm-exists-active.svg') no-repeat;
        margin-bottom: -5px;
        margin-left: 26px;
      }

      .icon-similar {
        background: url('/public/assets/icons/icon-dm-similar-active.svg') no-repeat;
        margin-left: 24px;
      }

      .icon-entity2 {
        background: url('/public/assets/icons/icon-dm-entity2-active.svg') no-repeat;
        margin-left: 22px;
      }

      .icon-type {
        background: url('/public/assets/icons/icon-dm-type-active.svg') no-repeat;
        margin-left: 24px;
      }

      .icon-set {
        background: url('/public/assets/icons/icon-dm-set-active.svg') no-repeat;
        margin-left: 24px;
      }

      .icon-channel {
        background: url('/public/assets/icons/icon-dm-channel-active.svg') no-repeat;
        margin-left: 22px;
      }

      .icon-user {
        background: url('/public/assets/icons/icon-dm-user-active.svg') no-repeat;
        margin-left: 22px;
      }

      .icon-regex {
        background: url('/public/assets/icons/icon-regex-active.svg') no-repeat;
        margin-left: 24px;
      }

      .icon-contains {
        background: url('/public/assets/icons/icon-dm-contains-active.svg') no-repeat;
        margin-left: 22px;
      }

      .icon-translate {
        background: url('/public/assets/icons/icon-dm-translate-active.svg') no-repeat;
        margin-left: 22px;
      }

      .icon-detectlanguage {
        background: url('/public/assets/icons/icon-dm-detectlanguage-active.svg') no-repeat;
        margin-left: 18px;
        margin-bottom: -5px;
      }

      .icon-validation {
        background: url('/public/assets/icons/icon-validation-active.svg') no-repeat;
      }

      .icon-pause-circle {
        background: url('/public/assets/icons/icon-dm-pause-circle-active.svg') no-repeat;
      }
    }
  }

  .text {
    font-size: 12px;
    text-align: center;
    color: $drag-drop2;
    line-height: normal;
    word-break: break-word;
    hyphens: auto;
  }

  .image {
    height: 40%;
    margin-top: 10px;
    margin-left: 23px;
  }
}
</style>
