var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form--wrapper__column form--wrapper__column--enlarged"},[_vm._m(0),_c('div',{staticClass:"form--wrapper__list form--wrapper__list--background"},_vm._l((_vm.engines),function(engine,index){return _c('div',{key:index,staticClass:"list-option list-option--selected list-option--dropdown",class:{ disabled: engine.isDisabled }},[_c('div',{staticClass:"header"},[_c('h4',[_vm._v(" "+_vm._s(engine.name)),(
                _vm.isAppSumo(_vm.tenantPlan) &&
                (engine.name == 'Intent Recognizer' ||
                  engine.name == 'Entity Extractor')
              )?_c('Tooltip',{attrs:{"title":"If you select a premium Engine like Microsoft Luis, Google DialogFlow and more in your bot's Settings -> Configuration page. <br>\nPlease note that each premium Engine you select will be charged separately at Euro 29 per month per bot. <br>\nFor example, if you select Google as Intent Recognizer and Microsoft as Entity Extractor for a bot, you will be charged Euro 2*29 = 58 per month for that bot. <br>\nIf on the other hand you select Google as Intent Recognizer and as Entity Extractor for a bot, you will be charged Euro 1*29 = 29 per month for that bot.","placement":"right"}}):_vm._e()],1),(_vm.hasSufficientPermission)?_c('div',{staticClass:"dropdown list-option--actions"},[_c('a',{staticClass:"dropdown-toggle",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.dropdownClick(engine.name)}}},[_c('span'),_c('span'),_c('span')]),_c('div',{staticClass:"dropdown-menu",class:{show:_vm.dropdownOpen === engine.name}},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openSettings(index)}}},[_c('i',{staticClass:"icon icon-settings"}),_vm._v("Settings ")])])]):_vm._e()]),_c('div',{staticClass:"dropdown__wrapper"},[_c('div',{staticClass:"dropdown dropdown--custom"},[_c('a',{staticClass:"dropdown-toggle",class:{ 'disable-dropdown': !_vm.hasSufficientPermission },attrs:{"href":"#","role":"button","aria-haspopup":"true","aria-expanded":"false"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.dropdownClick(engine.name+'-options')}}},[_c('span',_vm._l((engine.options),function(option,index){return _c('div',{key:index},[(engine.selectedProvider == option.provider)?_c('div',{staticClass:"dropdown-selected-item"},[_c('img',{attrs:{"src":option.imgSrc}}),_vm._v(" "+_vm._s(option.name)+" "),(
                        _vm.isAppSumo(_vm.tenantPlan) &&
                        (engine.name == 'Intent Recognizer' ||
                          engine.name == 'Entity Extractor') &&
                        option.name === 'Microsoft LUIS (premium)'
                      )?_c('Tooltip',{attrs:{"title":"Try this Engine free until end of December 2022.","placement":"left"}}):_vm._e()],1):_vm._e()])}),0)]),(_vm.hasSufficientPermission)?_c('div',{staticClass:"dropdown-menu",class:{show:_vm.dropdownOpen === engine.name+'-options'}},_vm._l((engine.options),function(option,index){return _c('li',{key:index},[(!option.isDisabled)?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.dropdownClick(''); engine.selectedProvider = option.provider}}},[_c('img',{attrs:{"src":option.imgSrc}}),_vm._v(" "+_vm._s(option.name)+" "),(
                      _vm.isAppSumo(_vm.tenantPlan) &&
                      (engine.name == 'Intent Recognizer' ||
                        engine.name == 'Entity Extractor') &&
                      option.name === 'Microsoft LUIS (premium)'
                    )?_c('Tooltip',{attrs:{"title":"Try this Engine free until end of December 2022.","placement":"left"}}):_vm._e()],1):_c('span',{staticClass:"dropdown-item disabled"},[_c('img',{attrs:{"src":option.imgSrc}}),_vm._v(" "+_vm._s(option.name)+" ")])])}),0):_vm._e()])])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"column-header"},[_c('h2',[_vm._v("Engines")])])
}]

export { render, staticRenderFns }