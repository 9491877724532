import { Component, Vue } from 'vue-property-decorator'
import { ConfigurationService } from '@/services/bots/settings/configuration.service'
import { engines } from '../../config/engines.config'
import { Engine } from '../../interfaces/engine'
import { getRelativeLanguage } from '../../../../../../../../common/helpers/nlu-language.helper'
import popupService from '@/services/popup.service'
import { nluLanguages } from '../../../../../../../../common/constants/nlu-languages.constant'
import { removedChannels } from '../../../../../../../../common/constants/removedChannels.constant'
import { IBotSettings } from '../../../../../../../../common/interfaces/bot-settings.interface'
import { SttProvider } from '../../../../../../../../common/types/stt-provider'
import { TtsProvider } from '../../../../../../../../common/types/tts-provider'
import { EntityExtractorProvider } from '../../../../../../../../common/types/entity-extractor-provider'
import { NluProvider } from '../../../../../../../../common/types/nlu-provider'
import { LanguageDetectionProvider } from '../../../../../../../../common/types/language-detection-provider'
import { LanguageTranslationProvider } from '../../../../../../../../common/types/language-translation-provider'
import { SentimentProvider } from '../../../../../../../../common/types/sentiment-provider'

/** @description manages the different channels/engines/systems */
@Component
export default class ConfigurationManagementMixin extends Vue {
  configurationService: ConfigurationService
  settings: IBotSettings
  engines: Engine[] = JSON.parse(JSON.stringify(engines))
  channels = []
  systems = []
  botName: string

  initConfiguration(botName: string) {
    this.botName = botName
    this.configurationService = new ConfigurationService(botName)
  }

  async getConfiguration() {
    const data = await this.configurationService.getConfiguration()
    this.channels = data.channels.filter((ch) => !removedChannels.includes(ch.name))
    this.systems = data.systems
  }

  async updateConfiguration() {
    const data: IBotSettings = {
      entityExtractorProvider: this.engines.find((e) => e.id == 'ENTITY_EXTRACTOR').selectedProvider as EntityExtractorProvider,
      intentRecognizerProvider: this.engines.find((e) => e.id == 'INTENT_RECOGNIZER').selectedProvider as NluProvider,
      language: this.settings.language,
      languageDetectionProvider: this.engines.find((e) => e.id == 'LANGUAGE_DETECTION').selectedProvider as LanguageDetectionProvider,
      languageTranslationProvider: this.engines.find((e) => e.id == 'LANGUAGE_TRANSLATION').selectedProvider as LanguageTranslationProvider,
      sentimentAnalysisProvider: this.engines.find((e) => e.id == 'SENTIMENT_ANALYZER').selectedProvider as SentimentProvider,
      speechToTextLanguageCode: this.settings.speechToTextLanguageCode,
      speechToTextProvider: this.engines.find((e) => e.id == 'STT').selectedProvider as SttProvider,
      textToSpeechLanguageCode: this.settings.textToSpeechLanguageCode,
      textToSpeechModel: this.settings.textToSpeechModel,
      textToSpeechPreferredVoiceType: this.settings.textToSpeechPreferredVoiceType,
      textToSpeechProvider: this.engines.find((e) => e.id == 'TTS').selectedProvider as TtsProvider,
      textToSpeechResponseFormat: this.settings.textToSpeechResponseFormat,
      textToSpeechSpeed: this.settings.textToSpeechSpeed,
      textToSpeechStreaming: this.settings.textToSpeechStreaming,
      idleTimeout: this.settings.idleTimeout,
      idleText: this.settings.idleText,
    }
    // eslint-disable-next-line no-console
    await this.configurationService.updateConfiguration(data, this.channels, this.systems)

    if (!getRelativeLanguage(this.settings.language, nluLanguages[data.intentRecognizerProvider])) {
      popupService.showWarning('Warning', 'The current NLU does NOT support your language', 200)
    }
  }
}
