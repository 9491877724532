import AuthService from '@/services/auth.service'

type WebSocketEvent = 'open' | 'message' | 'close' | 'error';

interface EventListeners {
  [event: string]: Array<(data?: any) => void>;
}
import axios from 'axios'


class WebSocketService {
  private socket: WebSocket | null = null
  private eventListeners: EventListeners = {}

  /**
   * Connect to the WebSocket server.
   * @param appName - Name of the application. provided to the server. Default: 'blits'
   */
  async connect(appName = 'blits'): Promise<void> {
    const token = btoa(AuthService.getPlatformToken())
    const url = `${await this.getWebsocketHost()}/?appName=${appName}&token=${token}`

    this.socket = new WebSocket(url)

    // Add event listeners for WebSocket events
    this.socket.onopen = () => {
      this.dispatchEvent('open')
    }

    this.socket.onmessage = (event: MessageEvent) => {
      const message = JSON.parse(event.data)
      this.dispatchEvent('message', message)
    }

    this.socket.onclose = () => {
      this.dispatchEvent('close')
    }

    this.socket.onerror = (error: Event) => {
      console.error('WebSocket error', error)
      this.dispatchEvent('error', error)
    }
  }

  async getWebsocketHost() {
    const websocketUrl = await this.getWebsocketUrl()
    console.log('websocketUrl', websocketUrl)
    if(websocketUrl) {
      return websocketUrl
    }

    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws'
    const host = window.location.hostname
    const port = window.location.port
    return `${protocol}://${host}:${port}${process.env.VUE_APP_BACKEND_URL}`
  }

  /**
   * Send a message through the WebSocket.
   * @param data - The data to send.
   */
  send(data: Record<string, any>): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(data))
    } else {
      console.error('WebSocket is not open. Unable to send message.')
    }
  }

  /**
   * Add an event listener for a specific WebSocket event.
   * @param event - The event name ('open', 'message', 'close', 'error').
   * @param callback - The callback function.
   */
  on(event: WebSocketEvent, callback: (data?: any) => void): void {
    if (!this.eventListeners[event]) {
      this.eventListeners[event] = []
    }
    this.eventListeners[event].push(callback)
  }

  /**
   * Dispatch an event to all listeners.
   * @param event - The event name.
   * @param data - The event data.
   */
  private dispatchEvent(event: WebSocketEvent, data?: any): void {
    if (this.eventListeners[event]) {
      this.eventListeners[event].forEach((callback) => callback(data))
    }
  }

  /**
   * Close the WebSocket connection.
   */
  close(): void {
    if (this.socket) {
      this.socket.close()
      this.socket = null
      this.eventListeners = {}
    }
  }

  getWebsocketUrl () {
    return axios.get(`${process.env.VUE_APP_BACKEND_URL}/websocket-url`).then(d => d.data)
  }
}

export default new WebSocketService()
