/**
 * OpenAI Text To Speech supported languages https=//platform.openai.com/docs/guides/text-to-speech/supported-languages
 * ...follows Whisper https=//github.com/openai/whisper?tab=readme-ov-file#available-models-and-languages
 * ...which has this list https=//github.com/openai/whisper/blob/main/whisper/tokenizer.py
 **/
import {SpeechCreateParams} from "../../backend_node_modules/openai@4.77.3/speech";
import {ChatCompletionAudioParam} from "../../backend_node_modules/openai@4.77.3/completions";

export enum OpenAITTSLanguage {
  "Afrikaans" = "Afrikaans",
  "Arabic" = "Arabic",
  "Armenian" = "Armenian",
  "Azerbaijani" = "Azerbaijani",
  "Belarusian" = "Belarusian",
  "Bosnian" = "Bosnian",
  "Bulgarian" = "Bulgarian",
  "Catalan" = "Catalan",
  "Chinese" = "Chinese",
  "Croatian" = "Croatian",
  "Czech" = "Czech",
  "Danish" = "Danish",
  "Dutch" = "Dutch",
  "English" = "English",
  "Estonian" = "Estonian",
  "Finnish" = "Finnish",
  "French" = "French",
  "Galician" = "Galician",
  "German" = "German",
  "Greek" = "Greek",
  "Hebrew" = "Hebrew",
  "Hindi" = "Hindi",
  "Hungarian" = "Hungarian",
  "Icelandic" = "Icelandic",
  "Indonesian" = "Indonesian",
  "Italian" = "Italian",
  "Japanese" = "Japanese",
  "Kannada" = "Kannada",
  "Kazakh" = "Kazakh",
  "Korean" = "Korean",
  "Latvian" = "Latvian",
  "Lithuanian" = "Lithuanian",
  "Macedonian" = "Macedonian",
  "Malay" = "Malay",
  "Marathi" = "Marathi",
  "Maori" = "Maori",
  "Nepali" = "Nepali",
  "Norwegian" = "Norwegian",
  "Persian" = "Persian",
  "Polish" = "Polish",
  "Portuguese" = "Portuguese",
  "Romanian" = "Romanian",
  "Russian" = "Russian",
  "Serbian" = "Serbian",
  "Slovak" = "Slovak",
  "Slovenian" = "Slovenian",
  "Spanish" = "Spanish",
  "Swahili" = "Swahili",
  "Swedish" = "Swedish",
  "Tagalog" = "Tagalog",
  "Tamil" = "Tamil",
  "Thai" = "Thai",
  "Turkish" = "Turkish",
  "Ukrainian" = "Ukrainian",
  "Urdu" = "Urdu",
  "Vietnamese" = "Vietnamese",
  "Welsh" = "Welsh",
}

// See https://platform.openai.com/docs/guides/text-to-speech?utm_source=chatgpt.com#voice-options
// OpenAIVoice is an enum: both a type and const
export type OpenAIVoice = SpeechCreateParams["voice"];
export const OpenAIVoice: { [key in OpenAIVoice]: string } = {
  // --id for OpenAI-- : --label for frontend--
  alloy: "Alloy (male)",
  echo: "Echo (male)",
  fable: "Fable (male)",
  onyx: "Onyx (male)",
  nova: "Nova (female)",
  shimmer: "Shimmer (female)",
}

// Only when "gpt-4o-audio-preview" is selected.
export type OpenAICompletionVoice = ChatCompletionAudioParam['voice'];
export const OpenAICompletionVoice: { [key in OpenAICompletionVoice]: string } = {
  // --id for OpenAI-- : --label for frontend--
  alloy: "Alloy (male)",
  echo: "Echo (male)",
  shimmer: "Shimmer (female)",
  ash: "Ash",
  ballad: "Ballad",
  coral: "Coral",
  sage: "Sage",
  verse: "Verse"
}
// OpenAIResponseFormat is an enum: both a type and const
// Docs https://platform.openai.com/docs/guides/text-to-speech?utm_source=chatgpt.com#supported-output-formats
export type OpenAIResponseFormat = SpeechCreateParams["response_format"]
export const OpenAIResponseFormat:{ [key in OpenAIResponseFormat]: string } = {
  // --id for OpenAI-- : --label for frontend--
  aac: "AAC",
  mp3: "MP3",
  flac: "Flac",
  wav: "Wav",
  pcm: "PCM",
  opus: "Opus",
}

// Only when "gpt-4o-audio-preview" is selected.
export type OpenAICompletionResponseFormat = ChatCompletionAudioParam['format'];
export const OpenAICompletionResponseFormat: { [key in OpenAICompletionResponseFormat]: string } = {
  // --id for OpenAI-- : --label for frontend--
  mp3: "MP3",
  flac: "Flac",
  wav: "Wav",
  opus: "Opus",
  pcm16: "PCM 16"
}
// SpeechModel is an enum: both a type and const
export type OpenAISpeechModel = "tts-1" | "tts-1-hd" | "gpt-4o-audio-preview"

// These models create audio. Check latest at https://platform.openai.com/docs/models
export const OpenAISpeechModel: { [key in OpenAISpeechModel]: string } = {
  // --id for OpenAI-- : --label for frontend--
  "tts-1": "TTS, optimized for speed",          // See https://platform.openai.com/docs/models#tts
  "tts-1-hd": "TTS, optimized for quality",      // See https://platform.openai.com/docs/models#tts
  "gpt-4o-audio-preview": "GPT-4o Audio Preview" // See https://platform.openai.com/docs/models#gpt-4o-audio
}
