<template>
  <div class="container">
    <WarningBanner
      v-if="isMonthLimitExceeded"
      description="You have reached your plan's max interactions. Upgrade your plan to keep your bot working."
      buttonValue="Upgrade plan"
      @buttonAction="$router.push('/configuration/subscription')"
    ></WarningBanner>
    <div class="container--content">
      <div class="content-header" v-if="loadedllms.length > 0">
        <div class="row">
          <div class="col-4">
            <h1>LLM Overview</h1>
          </div>
          <div class="col-8 text-right">
            <button type="button" class="btn btn--purple gradient" @click="openLLMDialog('create-llm')">
              Create a LLM
              <i class="icon icon-add"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="list-options" v-if="openedDialog == 'none'">
        <Loading v-if="!hasLoaded" :title="loadingText"></Loading>
        <Loading v-if="deletingLLMName" :title="`Deleting ${deletingLLMName}`"></Loading>
        <div class="row" v-if="hasLoaded">
          <div v-if="loadedllms.length == 0" class="list-options__empty">
            <div>
              <div class="select-element-icon">
                <i class="icon element-1"></i>
                <i class="icon element-2"></i>
                <i class="icon element-3"></i>
              </div>
              <h1>No LLMs yet!</h1>
              <div class="nobot-text">Let’s create your first, and very own Large Language Model!</div>
              <button type="button" class="btn btn--purple gradient" @click="openLLMDialog('create-llm')">
                Create a LLM
                <i class="icon icon-add"></i>
              </button>
            </div>
          </div>

          <div v-if="loadedllms.length > 0" class="col-lg-4" v-for="(llm, i) in loadedllms" :key="i">
            <router-link :to="`/llms/${llm.llmId}`">
              <div class="list-option list-option--dashboard">
                <div class="list-option--information">
                  <div class="dropdown list-option--actions">
                    <a href="#" class="dropdown-toggle" v-on:click.prevent.stop="dropdownClick(llm.llmDisplayName)">
                      <span></span>
                      <span></span>
                      <span></span>
                    </a>
                    <div class="dropdown-menu" :class="{ show: dropdownOpen === llm.llmDisplayName }">
                      <a v-if="!llm.isComplete" href="#" class="dropdown-item" @click.prevent="trainLLMButton(i)"> <i class="icon icon-ok"></i>Train LLM </a>
                      <a v-if="llm.isComplete" href="#" class="dropdown-item" @click.prevent="trainLLMButton(i)"> <i class="icon icon-ok"></i>Retrain LLM </a>
                      <a href="#" class="dropdown-item red" @click.prevent="deleteFunction(i)"> <i class="icon icon-delete-new"></i>Delete LLM </a>
                    </div>
                  </div>
                  <h4>{{ llm.llmDisplayName }}</h4>
                  <div class="description">{{ llm.description }}</div>
                  <div class="language-container">
                    <label class="language-label"> {{ llm.model }} </label>
                    <div class="engine-icon">
                      <div
                        class="icon"
                        :class="{
                          openai: llm.llmProvider === 'OpenAI',
                          anthropic: llm.llmProvider === 'Anthropic',
                          google: llm.llmProvider === 'Google',
                          huggingface: llm.llmProvider === 'Hugging Face',
                          microsoft: llm.llmProvider === 'Microsoft',
                          mistral: llm.llmProvider === 'Mistral',
                          alibaba: llm.llmProvider === 'Alibaba Cloud',
                          meta: llm.llmProvider === 'Meta'
                        }"
                      />
                    </div>
                  </div>
                  <div class="dot" :class="llm.isComplete ? 'green' : 'red'" />
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <!-- MODAL FOR CREATE -->
      <CreateLLM v-if="openedDialog == 'create-llm'" @create="createNewLLM" @close="reset()" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import CreateLLM from './CreateLLM.vue'
import Loading from '../../helpers/Loading.vue'
import DataManagementMixin from '../bots/settings/_mixins/settings/data-management.mixin'
import WarningBanner from '../bots/warning/WarningBanner.vue'
import tenantService from '../../../services/tenant.service'
import { TenantPlan } from '../../../../../common/interfaces/plans/tenant-plan.interface'
import popupService from '../../../services/popup.service'
import { loadingTexts } from '../../../constants/loading-texts'
import { LLMObject, LLM } from '../../../../../common/types/llm-type'
import LlmManagementMixin from '../../../components/admin/bots/_mixins/llm-management.mixin'
import DropdownMixin from '@/components/admin/bots/_mixins/dropdown.mixin'

@Component({ components: { CreateLLM, Loading, WarningBanner } })
export default class LLMOverview extends Mixins(DataManagementMixin, LlmManagementMixin, DropdownMixin) {
  public openedDialog = 'none'
  public bots = []
  public loadedllms: LLMObject[] = []
  public hasLoaded = false
  public loadingText = `Loading all the llm's`
  public isMonthLimitExceeded = false
  public tenantPlan: TenantPlan | null = null

  created() {
    document.querySelector('body')?.classList.remove('image-background')
  }

  beforeMount() {
    this.randomText()
  }

  async mounted() {
    try {
      await Promise.all([this.loadLLMs(), this.interactionsCalc(), this.getTenantPlan()])
    } finally {
      this.hasLoaded = true
    }
  }

  async createNewLLM(llmData: LLM) {
    await this.createLLM(llmData)
    this.loadLLMs()
  }

  async trainLLMButton(index: number) {
    await this.trainLLM(this.loadedllms[index])
    this.loadLLMs()
  }

  async testLLMButton(index: number) {
    const result = await this.runLLM(this.loadedllms[index], 'tell something about the context we added in this request.')
    this.loadLLMs()
    alert(result)
  }

  async interactionsCalc() {
    this.isMonthLimitExceeded = await tenantService.areInteractionExceeded()
  }

  async getTenantPlan() {
    this.tenantPlan = await tenantService.getPlan()
  }

  async loadLLMs() {
    this.loadedllms = (await axios.get(`${process.env.VUE_APP_BACKEND_URL}/llm`)).data
  }

  randomText() {
    const id = Math.floor(Math.random() * loadingTexts.length)
    this.loadingText = loadingTexts[id]
  }

  async openLLMDialog(dialogName: string) {
    if (!this.tenantPlan) {
      popupService.showDialog('Warning!', 'Your account is missing an active subscription, please contact support', 'ok')
      return
    }
    if (this.bots.length >= this.tenantPlan.maxFreeBots) {
      if (this.tenantPlan.hasAdditionalBotsCost) {
        if (!(await popupService.showDialog('Warning!', 'You are adding more LLMs outside your current plan. This will add additional cost.', 'Continue')))
          return
      } else {
        if (await popupService.showDialog('Error!', "You have reached your plan's max LLMs. Upgrade your plan to add more LLMs.", 'Upgrade'))
          this.$router.push('/configuration/subscription')
        return
      }
    }
    this.openedDialog = dialogName
  }

  reset() {
    this.openedDialog = 'none'
  }

  deletingLLMName = ''
  async deleteFunction(index: number) {
    const name = this.loadedllms[index].llmDisplayName
    const id = this.loadedllms[index].llmId
    if (!(await popupService.showDialog('Warning!', `Do you really want to delete ${name} LLM?`, 'Delete'))) return
    this.deletingLLMName = name
    const success = await this.deleteLLM(this.loadedllms[index])
    if (success) this.loadedllms.splice(index, 1)
    this.deletingLLMName = ''
  }

  displayLanguage(language) {
    return language.toUpperCase().split('-')[0]
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables';

.language-container {
  float: left;
  display: flex;
  align-items: center;
  margin-top: 5px;
  width: 100%;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  bottom: 20px;

  &.red {
    background-color: $red;
  }

  &.green {
    background-color: $green;
  }
}

.engine-icon {
  width: 30px;
  height: 20px;
  margin-left: -10px;
  background-color: transparent;

  .icon {
    height: 100%; /* <------ */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &.openai {
      background-image: url('/public/assets/icons/openai-logo.svg');
    }
    &.anthropic {
      background-image: url('/public//assets/icons/anthropic-logo.svg');
    }
    &.google {
      background-image: url('/public//assets/icons/googleai-logo.svg');
    }
    &.huggingface {
      background-image: url('/public//assets/icons/huggingface-logo.svg');
    }
    &.microsoft {
      background-image: url('/public//assets/img/logo-ms.svg');
    }
    &.mistral {
      background-image: url('/public//assets/icons/mistral-logo.png');
    }
    &.alibaba {
      background-image: url('/public//assets/icons/ali.png');
    }
    &.meta {
      background-image: url('/public//assets/icons/meta-logo.svg');
      margin-left: 5px;
    }
  }
}

label {
  width: fit-content;
  background-color: $default-dark;
  color: $white;
  font-weight: bold;
  border-radius: 5px;
  padding: 1px 9px;

  &.language-label {
    margin-right: 15px;
  }

  &.region-label {
    margin-left: auto;
  }
}

h1 {
  font-size: 36px;
  font-weight: 700;
  color: $default-dark;
  line-height: 50px;
  margin: 0;
  @media only screen and (min-width: 1200px) {
    font-size: 40px;
  }
}

.description {
  color: $grey;
  min-height: 60px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-option {
  border: 0;
  border-left: 10px solid $main;
  min-height: 110px;

  .list-option--information {
    padding-left: 0;
  }
}

.list-option--dashboard li:first-child .flex div {
  margin-right: 15px;
}

.main-fill-color-button {
  margin-left: 15px;
}

.content-header {
  margin-bottom: 30px;
}

.dropdown-toggle {
  margin-right: -20px;
  margin-top: 5px;

  span {
    background: $grey !important;
  }
}
</style>
