<template>
  <div class="form--wrapper__column form--wrapper__column--enlarged">
    <div class="column-header">
      <h2>Engines</h2>
    </div>
    <div class="form--wrapper__list form--wrapper__list--background">
      <div
        :key="index"
        v-for="(engine, index) in engines"
        class="list-option list-option--selected list-option--dropdown"
        :class="{ disabled: engine.isDisabled }"
      >
        <div class="header">
          <h4>
            {{ engine.name
            }}<Tooltip
              v-if="
                isAppSumo(tenantPlan) &&
                (engine.name == 'Intent Recognizer' ||
                  engine.name == 'Entity Extractor')
              "
              title="If you select a premium Engine like Microsoft Luis, Google DialogFlow and more in your bot's Settings -> Configuration page. <br>
Please note that each premium Engine you select will be charged separately at Euro 29 per month per bot. <br>
For example, if you select Google as Intent Recognizer and Microsoft as Entity Extractor for a bot, you will be charged Euro 2*29 = 58 per month for that bot. <br>
If on the other hand you select Google as Intent Recognizer and as Entity Extractor for a bot, you will be charged Euro 1*29 = 29 per month for that bot."
              placement="right"
            ></Tooltip>
          </h4>
          <div
            class="dropdown list-option--actions"
            v-if="hasSufficientPermission"
          >
            <a href="#" class="dropdown-toggle" v-on:click.prevent.stop="dropdownClick(engine.name)">
              <span></span>
              <span></span>
              <span></span>
            </a>
            <div class="dropdown-menu" :class="{show:dropdownOpen === engine.name}">
              <a
                href="#"
                class="dropdown-item"
                @click.prevent="openSettings(index)"
              >
                <i class="icon icon-settings"></i>Settings
              </a>
            </div>
          </div>
        </div>
        <div class="dropdown__wrapper">
          <div class="dropdown dropdown--custom">
            <a
              class="dropdown-toggle"
              :class="{ 'disable-dropdown': !hasSufficientPermission }"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              v-on:click.prevent.stop="dropdownClick(engine.name+'-options')"
            >
              <span>
                <div :key="index" v-for="(option, index) in engine.options">
                  <div
                    class="dropdown-selected-item"
                    v-if="engine.selectedProvider == option.provider"
                  >
                    <img :src="option.imgSrc" />
                    {{ option.name }}
                    <Tooltip
                      v-if="
                        isAppSumo(tenantPlan) &&
                        (engine.name == 'Intent Recognizer' ||
                          engine.name == 'Entity Extractor') &&
                        option.name === 'Microsoft LUIS (premium)'
                      "
                      title="Try this Engine free until end of December 2022."
                      placement="left"
                    ></Tooltip>
                  </div>
                </div>
              </span>
            </a>
            <div class="dropdown-menu" v-if="hasSufficientPermission" :class="{show:dropdownOpen === engine.name+'-options'}">
              <li :key="index" v-for="(option, index) in engine.options">
                <a
                  v-if="!option.isDisabled"
                  class="dropdown-item"
                  href="#"
                  @click.prevent="dropdownClick(''); engine.selectedProvider = option.provider"
                >
                  <img :src="option.imgSrc" />
                  {{ option.name }}
                  <Tooltip
                    v-if="
                      isAppSumo(tenantPlan) &&
                      (engine.name == 'Intent Recognizer' ||
                        engine.name == 'Entity Extractor') &&
                      option.name === 'Microsoft LUIS (premium)'
                    "
                    title="Try this Engine free until end of December 2022."
                    placement="left"
                  ></Tooltip>
                </a>

                <span v-else class="dropdown-item disabled">
                  <img :src="option.imgSrc" />
                  {{ option.name }}
                </span>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Mixins, Prop } from "vue-property-decorator";
import { Engine } from "../../interfaces/engine";
import { Permissions } from "../../../../../../../../common/enums/tenant/user-permissions.enum";
import permissionsService from "../../../../../../services/tenants/permissions.service";
import subscriptionMixin from "../../../../../admin/configuration/_mixins/subscription.mixin";
import { TenantPlan } from "../../../../../../../../common/interfaces/plans/tenant-plan.interface";
import Tooltip from "../../../../../helpers/Tooltip.vue";
import { ExternalProvider } from "../../../../../../../../common/enums/external-provider.enum";
import DropdownMixin from "@/components/admin/bots/_mixins/dropdown.mixin";

@Component({
  components: { Tooltip },
})
export default class Engines extends Mixins(subscriptionMixin, DropdownMixin) {
  @Prop() engines!: Engine[];
  @Prop() botName: string;
  @Prop() tenantPlan: TenantPlan;

  private hasSufficientPermission = false;

  @Emit()
  openSettings(engineIndex: number) {
    const engine = this.engines[engineIndex];
    const option = engine.options.find(
      (opt) => opt.provider == engine.selectedProvider
    );
    return option.name;
  }

  get permissions() {
    return Permissions;
  }

  async beforeMount() {
    this.hasSufficientPermission = await permissionsService.hasPermission(
      this.botName,
      this.permissions.ManageEngines
    );

    // disable Entity Extractor & Intent Recognizer except Rasa & Wit for basic and AppSumo plans
    const allowedEngines = this.getAvailableEngines(this.tenantPlan);
    const isAppSumo = this.isAppSumo(this.tenantPlan);
    const intentRecognizerEngine = this.engines.find(
      (engine) => engine.id === "INTENT_RECOGNIZER"
    );
    const entityExtractorEngine = this.engines.find(
      (engine) => engine.id === "ENTITY_EXTRACTOR"
    );
    intentRecognizerEngine.options.forEach((option, index) => {
      if (
        (allowedEngines &&
          !allowedEngines.includes(option.provider.toLowerCase())) ||
        (isAppSumo &&
          intentRecognizerEngine.options[index].provider ===
            ExternalProvider.Nuance)
      ) {
        intentRecognizerEngine.options[index].isDisabled = true;
      }
      if (
        isAppSumo &&
        intentRecognizerEngine.options[index].provider !==
          ExternalProvider.Nuance &&
        intentRecognizerEngine.options[index].provider !==
          ExternalProvider.Wit &&
        intentRecognizerEngine.options[index].provider !==
          ExternalProvider.Rasa &&
        !intentRecognizerEngine.options[index].name.includes("(premium)")
      )
        intentRecognizerEngine.options[index].name =
          intentRecognizerEngine.options[index].name + " (premium)";
    });
    entityExtractorEngine.options.forEach((option, index) => {
      if (
        (allowedEngines &&
          !allowedEngines.includes(option.provider.toLowerCase())) ||
        (isAppSumo &&
          entityExtractorEngine.options[index].provider ===
            ExternalProvider.Nuance)
      ) {
        entityExtractorEngine.options[index].isDisabled = true;
      }
      if (
        isAppSumo &&
        entityExtractorEngine.options[index].provider !==
          ExternalProvider.Nuance &&
        entityExtractorEngine.options[index].provider !==
          ExternalProvider.Wit &&
        entityExtractorEngine.options[index].provider !==
          ExternalProvider.Rasa &&
        !entityExtractorEngine.options[index].name.includes("(premium)")
      )
        entityExtractorEngine.options[index].name =
          entityExtractorEngine.options[index].name + " (premium)";
    });

    this.$forceUpdate();
  }
}
</script>

<style lang="scss">
@import "../../settings.style";

.list-option {
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    &::before {
      border: 0;
    }
  }
}

.disable-dropdown {
  &::after {
    color: transparent !important;
  }
  cursor: not-allowed !important;
}

.dropdown-selected-item {
  display: flex;
  align-items: center;
}
</style>
