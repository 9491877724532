/** @description maps a language from one NLU to a language from another second NLU */
export function getRelativeLanguage(language: string, nluLanguageEnum: { [key: string]: string }): string | undefined {
  return (
    Object.values(nluLanguageEnum).find((value) => language === value) ||
    Object.values(nluLanguageEnum).find((value) => language.split("-")[0] == value.split("-")[0])
  )
}

export function getRelativeLanguageKey(language: string, nluLanguageEnum: { [key: string]: string }): string | undefined {
  return (
    Object.keys(nluLanguageEnum).find((key) => nluLanguageEnum[key] === language) ||
    Object.keys(nluLanguageEnum).find((key) => language.split("-")[0] == nluLanguageEnum[key].split("-")[0])
  )
}

export function getLanguageKey(language: string, nluLanguageEnum: { [key: string]: string }): string | undefined {
  return nluLanguageEnum[language] || language
}
