export enum MetaModels {
  //llama 3.1
  LLAMA3_1 = "llama3.1", //8b
  LLAMA3_1_70B = "llama3.1:70b",
  LLAMA3_1_405B = "llama3.1:4050b",

  //llama 3.2
  LLAMA3_2 = "llama3.2", //1b
  LLAMA3_2_3B = "llama3.2:3b", //3b

  //llama 3.3
  LLAMA3_3 = "llama3.3", //70b
}

export const MetaModelsEnabled = [MetaModels.LLAMA3_3, MetaModels.LLAMA3_2, MetaModels.LLAMA3_1]
