export enum SyncStep {
    InitializeCache = 'INITIALIZE_CACHE',
    SyncEntities = 'SYNC_ENTITIES',
    SyncIntents = 'SYNC_INTENTS',
    TrainBot = 'TRAIN_BOT',
}

export const SYNC_STATE_MESSAGES = {
    [SyncStep.InitializeCache]: 'Initializing cache',
    [SyncStep.SyncEntities]: 'Syncing entities',
    [SyncStep.SyncIntents]: 'Syncing intents',
    [SyncStep.TrainBot]: 'Training bot',
}
