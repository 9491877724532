import { render, staticRenderFns } from "./Channels.vue?vue&type=template&id=17743b19&scoped=true"
import script from "./Channels.vue?vue&type=script&lang=ts"
export * from "./Channels.vue?vue&type=script&lang=ts"
import style0 from "./Channels.vue?vue&type=style&index=0&id=17743b19&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17743b19",
  null
  
)

export default component.exports