import axios from 'axios'
import { saveAs } from 'file-saver'

export abstract class AnalyticsServiceBase {
    abstract arrangeModules(): Promise<any>;
    abstract getIntentNames(): Promise<any[]>;
    abstract getUnrecognizedUtterances(): Promise<any[]>;
    abstract getServiceStatus(): Promise<any>;
    abstract getMonth(date: Date): Promise<any>;

    async downloadCsv () {
      const response = await axios.get(
            `${process.env.VUE_APP_BACKEND_URL}/utterances`,
            { responseType: 'blob' }
      )
      saveAs(response.data, 'utterances.csv')
    }
}
