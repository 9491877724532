<template>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <div class="form-group--inside">
          <h4>LLM Context</h4>

          <div class="description">Here, you can set the persona and formatting instructions for your LLM to control how it behaves and responds.</div>

          <h4 class="mt">Persona</h4>
          <div class="form-group--inside">
            <Autocomplete :items="variables" name="persona" id="persona" v-model="persona" :rows="5" placeholder="Enter the bot's persona description" />
          </div>

          <h4 class="mt">Format Instructions</h4>
          <div class="form-group--inside">
            <Autocomplete
              :items="variables"
              name="formatInstructions"
              id="formatInstructions"
              v-model="formatInstructions"
              :rows="5"
              placeholder="Enter formatting instructions for the bot"
            />
          </div>

          <div class="form-group--inside">
            <h4 class="mt">Output Type</h4>
            <Dropdown :items="llmOutputTypes" v-model="outputType" @input="setOutputType($event)" placeholder="Select output type" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { LLMObject, llmOutputType } from '../../../../../../common/types/llm-type'
import Autocomplete from '@/components/helpers/Autocomplete.vue'
import Dropdown from '@/components/helpers/Dropdown.vue'

@Component({ components: { Autocomplete, Dropdown } })
export default class LLMContext extends Vue {
  @Prop() LLM: LLMObject
  @Prop() variables: string[]

  llmOutputTypes = ['text', 'json']

  get persona() {
    return this.LLM.persona
  }

  set persona(value: string) {
    this.LLM.persona = value
  }

  get formatInstructions() {
    return this.LLM.formatInstructions
  }

  set formatInstructions(value: string) {
    this.LLM.formatInstructions = value
  }

  get outputType() {
    return this.LLM.output === 'json' ? 'json' : 'text'
  }

  set outputType(value: llmOutputType | string) {
    const outputType = value === 'text' ? 'string' : value
    this.LLM.output = outputType as llmOutputType
  }

  setOutputType(event: any) {
    const outputType = event === 'text' ? 'string' : event
    this.LLM.output = outputType as llmOutputType
  }
}
</script>

<style scoped lang="scss">
@import '../../../../assets/scss/variables';

.description {
  font-size: 14px;
  margin-bottom: 15px;
}

.mt {
  margin-top: 20px;
}

.dot-wrapper {
  display: flex;
  margin: 0px 0px -15px 0px;
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;

    &.red {
      background-color: $red;
    }

    &.green {
      background-color: $green;
    }
  }
  .description {
    margin-left: 10px;
    margin-top: -5px;
  }
}
</style>
