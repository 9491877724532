<template>
  <div class="row sidenav">
    <div class="col-3">
      <div class="content-intents__sidebar">
        <ul class="intents-list">
          <li v-for="([key, item]) in Object.entries(items)" :key="key"
              :class="{'active' : selectedItem == key, 'disabled': item.enabled == false }">
            <button type="button" @click.prevent="item.enabled != false && (selectedItem = key)" :class="{'disabled': item.enabled == false}">
              {{ item.title }}
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-9">
      <ChatBubble
        ref="chat"
        v-show="selectedItem == 'chat'"
        :toggleBranding="toggleBranding"
      />
      <GDPR
        ref="gdpr"
        v-show="selectedItem == 'gdpr'"
        :isTenantAdmin="isTenantAdmin"
        @on-permission-change="onTabChange"
      />
      <MultiLanguageSettings
        ref="language" v-show="selectedItem == 'language'"
        @on-permission-change="onTabChange" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Permissions } from '@common/enums/tenant/user-permissions.enum'
import ChatBubble from '@/components/admin/bots/settings/tabs/features/ChatBubble.vue'
import Vue from 'vue'
import GDPR from '@/components/admin/bots/settings/tabs/features/GDPR.vue'
import Data from '@/components/admin/bots/settings/tabs/Data.vue'
import MultiLanguageSettings from '@/components/admin/bots/settings/tabs/features/MultiLanguageSettings.vue'

@Component({
  components: { MultiLanguageSettings, Data, GDPR, ChatBubble }
})
export default class Features extends Vue {
  @Prop() readonly toggleBranding: boolean
  @Prop() readonly isTenantAdmin: boolean
  @Prop() readonly channels: any[]

  private selectedItem = 'gdpr'
  botName = this.$router.history.current.params.botName

  @Watch('selectedItem')
  onTabChange() {
    this.$emit('on-subtab-change')
  }

  updated() {
    this.$emit('on-subtab-change')
  }

  readonly items = {
    gdpr: { title: 'GDPR', permission: Permissions.ManageGDPRSettings },
    chat: { title: 'Chat Bubble', permission: Permissions.ManageChatBubbles, enabled: this.isChatEnabled() },
    language: { title: 'Multi-Language', permission: Permissions.ManageGeneralSettings }
  }

  public save() {
    const component = this.$refs[this.selectedItem] as any
    if (component?.save) component?.save()
  }

  public hasSufficientPermission() {
    const component = this.$refs[this.selectedItem] as any
    return component?.hasSufficientPermission ?? false
  }

  isChatEnabled() {
    return this.channels.find(ch => ch.name === 'Chat Bubble')?.enabled
  }
}
</script>

<style lang="scss" scoped>
@import "../settings.style";
@import "../../../../../assets/scss/components/toggle";
@import "../../../../../assets/scss/components/_chat.scss";

.intents-list::before {
  border-top: none;
}

hr {
  border-top: 1px solid $light-blue;
}

.bubble {
  padding: 13px;
  width: 50px;
  min-width: 50px;
  height: 50px;
  display: flex;
  background: $sent-background;
  border-radius: 50px;
  border: none;
  -webkit-appearance: none;
  -webkit-box-shadow: 0px 1px 10px $popup-shadow;
  -ms-box-shadow: 0px 1px 10px $popup-shadow;
  box-shadow: 0px 1px 10px $popup-shadow;

  i {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}

p {
  font-size: 14px;
  font-weight: bold;
  color: #979797;
  line-height: 18px;
  margin: 0;
}

i.plus {
  transition: 0.5s all;

  &.expanded {
    transform: rotate(135deg);
  }
}

form {
  textarea.js-snippet {
    height: auto;

    &.code {
      background-color: $code;
      color: $blue;
      font-weight: bold;
      font-size: 15px;
      border-radius: 6px;
      border: 0px solid $code;
    }
  }

  .form-toggle--inside {
    margin: 15px 0 0;
  }
}

.blurred {
  opacity: 0.6;
}

.add-button-widget {
  margin-bottom: 20px;
}

.description-header {
  font-size: 12px;
  float: left;
  font-weight: bold;
  margin-right: 5px;

  &.avatar {
    margin-top: 20px;
    margin-left: 15px;
    margin-bottom: -5px;
    width: 100%;

    &.left {
      margin-left: 7px;
    }
  }
}

.file-input {
  margin-left: -8px;
}

.description-text {
  font-size: 12px;
  color: $grey;
}

.btn-add {
  width: 100%;
  margin-left: 0;
}

.avatar-link {
  overflow-wrap: break-word;
}

.new-bubble-button {
  margin: 20px;
}

.file-input#file-input {
  padding: 0;
  margin-bottom: 10px;
  color: black;
  font-size: 11px;
}

.container-snippet {
  border: 1px solid $light-blue;
  border-radius: 6px;
  background: $default;
  padding: 15px;
  margin-bottom: 15px;
}

.content_info {
  width: 400px;
  padding: 15px 15px 15px 20px;
  overflow: hidden;
}

.content_info img {
  margin-right: 15px;
  float: left;
  max-height: 49px;
  max-width: 49px;
}

.content_info h3,
.content_info p {
  display: block;
  margin: 2px 0 0 0;
  color: $secondary-text;
  font-size: 14px;
}

.section--footer.static {
  margin-top: 10px;
  margin-bottom: 10px;
}

.error {
  color: red;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}
</style>
