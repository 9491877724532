<script lang="ts">
import { Vue, Component, Prop, Mixins } from 'vue-property-decorator'
import TextPill from '@/components/helpers/TextPill.vue'
import FormGroup from '@/components/helpers/FormGroup.vue'
import Dropdown from '@/components/helpers/Dropdown.vue'
import { WitAppLanguage } from '@common/enums/wit/wit-app-language.enum'
import permissionsService from '@/services/tenants/permissions.service'
import { Permissions } from '@common/enums/tenant/user-permissions.enum'
import generalSettingsManagementMixin from '../../_mixins/settings/general-settings-management.mixin'
import popupService from '@/services/popup.service'

@Component({
  components: { Dropdown, FormGroup, TextPill }
})
export default class MultiLanguageSettings extends Mixins(generalSettingsManagementMixin) {

  public hasSufficientPermission = false
  readonly botName = this.$router.history.current.params.botName
  selectedLang: string = 'Select a Language'

  async created() {
    this.initGeneralSettings(this.botName)
    await this.getGeneralSettings()
    this.hasSufficientPermission = await permissionsService.hasPermission(this.botName, this.permissions.ManageGeneralSettings)
    this.$emit('on-permission-change')
  }

  save() {
    try {
      this.updateSettings()
      popupService.showInfo('Saved!')
    } catch (err) {
      const message = err.response ? err.response.data.message : err.message
      popupService.showError('Error!', message)
    }
  }

  get permissions() {
    return Permissions
  }

  private providerSupportedLanguages() {
    return Object.keys(WitAppLanguage).filter((language: string) => {
      return language !== this.defaultLanguage() && !this.languages.includes(language)
    })
  }

  get languages() {
    return this.$store.state.supportedLanguages
      .filter((language: string) => language !== this.defaultLanguage())
  }

  private defaultLanguage() {
    return this.$store.state.defaultLanguage
  }

  addLanguage(language: string) {
    if (!language.length) return
    this.$store.commit('addSupportedLanguage', language)
    this.selectedLang = 'Select a Language'
  }

  deleteLanguage(language: string) {
    this.$store.commit('deleteSupportedLanguage', language)
  }
}
</script>

<template>
  <div class="wrapper-inside multi-language-settings active">
    <div class="settings-wrapper">
      <FormGroup
        label="Supported Languages"
        tooltip="Here you can select the supported languages for your bot</br></br> After selecting your supported languages please go to the Dialog Manager to change the responses based on the detected language</br></br>The default bot language cannot be disabled"
      >
        <select v-model="selectedLang" @change="addLanguage($event.target.value)" :disabled="!hasSufficientPermission">
          <option value="Select a Language" disabled>Select a Language</option>
          <option v-for="(language, key) in providerSupportedLanguages()" :key="key" :value="language">
            {{ language }}
          </option>
        </select>

        <section class="selected-languages">
          <TextPill
            class="default-language"
            :text="defaultLanguage()"
            :id="defaultLanguage()"
            @delete="deleteLanguage($event)"
            :disabledDelete="true"
          />
          <TextPill
            v-for="language in languages"
            :key="language"
            :text="language"
            :id="language"
            @delete="deleteLanguage($event)"
            :disabledDelete="!hasSufficientPermission"
          />
        </section>
      </FormGroup>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../../../../../assets/scss/setup/colors';

.selected-languages {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.default-language {
  background-color: $main;
  color: $white;
  font-weight: bold;
}

.settings-wrapper {
  height: 100%;
}
</style>
