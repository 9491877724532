<template>
  <section id="documents">
    <h4>Your LLM custom data</h4>
    <div class="description">
      Enriching a Large Language Model (LLM) with additional data from uploaded documents or websites can be done here so the model can understand and utilize
      the new information effectively.
    </div>

    <div class="row">
      <div :class="`col-12 row`">
        <h4 class>What kind of document do you want to add?</h4>
        <div :class="`col-4`">
          <div class="form-group">
            <div class="form-group--inside">
              <select v-model="newDocument.type" class="white-select">
                <option v-for="type in Object.values(LlmDocumentType)" :key="type" :value="type">{{ type }}</option>
              </select>
              <i class="dropdown-toggle x"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mt-2">
        <h4 class>{{ newDocument.type === LlmDocumentType.FILE ? 'Upload your documents' : 'Add your URLs' }}</h4>
        <div class="form-group">
          <div class="form-group--inside" style="margin-right: 27px">
            <div v-if="newDocument.type === LlmDocumentType.FILE">
              <div :class="`col-4`">
                <button class="btn btn--white dialogmanager" type="button" @click="triggerFileUpload">
                  Upload new file
                  <input hidden :accept="llmSupportedFiletypes()" id="file-input" type="file" ref="llmfile" @change="handleFileUpload()" />
                </button>
                <!-- <div type="button" class="copybutton dark" style="margin-top: -12px" @click="addDocument()">Add</div> -->
              </div>
            </div>
            <div v-else-if="newDocument.type === LlmDocumentType.URL">
              <Autocomplete
                v-model="newDocument.content"
                :rows="1"
                class="code padding-input"
                spellcheck="false"
                placeholder="for example: https://www.blits.ai"
              ></Autocomplete>
              <div type="button" class="copybutton" @click="addDocument()">Add</div>
            </div>
          </div>
        </div>

        <h4 class="m-b-20">Added documents</h4>
        <div class="row" v-for="(doc, index) in current.documents" :key="index" :id="'document-' + index">
          <div class="col-12 m-b-20">
            <div class="form-group">
              <span class="form-group--inside documents">
                {{ doc.displayName || doc.name || doc.link || 'unknown' }}
                <i class="icon-delete entity-delete" @click="deleteDocument(index)"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="empty m-b-20" v-if="current.documents.length === 0">Press the add button to add your document</div>

        <h4 class="mt">Embeddings model</h4>
        <div class="form-group--inside">
          <Dropdown :items="embeddingsModels" :value="selectedEmbeddingsModel" @input="setEmbeddingsModel($event)" />
        </div>
      </div>
      <div v-if="docError" class="invalid">{{ docError }}</div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import { LLMObject, LLMDocument } from '../../../../../../common/types/llm-type'
import Autocomplete from '../../../../components/helpers/Autocomplete.vue'
import { LlmDocumentType } from '../../../../../../common/enums/bot/llm/llm-document-type.enum'
import { LLM_MAX_FILESIZE, LLM_SUPPORTED_FILETYPES } from '../../../../../../common/constants/llm-document.constants'
import popupService from '@/services/popup.service'
import LlmManagementMixin from '../../../../components/admin/bots/_mixins/llm-management.mixin'
import { OpenAIEmbeddingsModelsEnabled } from '@common/enums/open-ai-models'
import { NomicEmbeddingsModelsEnabled } from '@common/enums/nomic/embeddings-model.enum'
import Dropdown from '@/components/helpers/Dropdown.vue'

@Component({ components: { Autocomplete, Dropdown } })
export default class LLMDocuments extends Mixins(LlmManagementMixin) {
  @Prop() current: LLMObject
  @Prop() variables: string[]
  @Prop() docError: string

  openAiEmbeddingsModels = OpenAIEmbeddingsModelsEnabled
  nomicEmbeddingsModels = NomicEmbeddingsModelsEnabled

  selectedDocumentType = LlmDocumentType.FILE
  selectedEmbeddingsModel: 'default' | typeof OpenAIEmbeddingsModelsEnabled | typeof NomicEmbeddingsModelsEnabled = 'default'

  newDocument: LLMDocument = {
    name: '',
    type: LlmDocumentType.FILE,
    content: '',
    file: undefined,
    link: undefined,
    displayName: undefined
  }

  get embeddingsModels() {
    return ['default', ...Object.values(OpenAIEmbeddingsModelsEnabled), ...Object.values(NomicEmbeddingsModelsEnabled)]
  }

  get LlmDocumentType() {
    return LlmDocumentType
  }

  mounted() {
    this.selectedEmbeddingsModel = this.current.embeddingModel
  }

  setEmbeddingsModel(model: 'default' | typeof OpenAIEmbeddingsModelsEnabled | typeof NomicEmbeddingsModelsEnabled) {
    this.current.embeddingModel = model
  }

  llmSupportedFiletypes() {
    return LLM_SUPPORTED_FILETYPES
  }

  triggerFileUpload() {
    ;(this.$refs.llmfile as HTMLInputElement).click()
  }

  handleFileUpload() {
    this.$refs.llmfile = this.$refs.llmfile as Vue
    if (this.$refs.llmfile.files.length !== 0) {
      const formData = new FormData()
      formData.append(this.$refs.llmfile.files[0].name, this.$refs.llmfile.files[0])
      formData.forEach((file) => {
        if ((file as File).size > LLM_MAX_FILESIZE) {
          popupService.showError(`File is too big. Accepted size is ${LLM_MAX_FILESIZE / 1024 / 1024}MB`)
          ;(this.$refs.llmfile as HTMLInputElement).value = ''
        } else {
          this.newDocument.name = (this.$refs.llmfile as Vue).files[0].name
          this.newDocument.file = (this.$refs.llmfile as Vue).files[0]
        }
      })
    }
    this.addDocument()
  }

  async addDocument() {
    if (this.newDocument.type === LlmDocumentType.URL) {
      this.newDocument.file = undefined
      this.newDocument.link = this.newDocument.content
      this.newDocument.content = undefined
    } else if (this.newDocument.type === LlmDocumentType.FILE) {
      const fileExtension = this.newDocument.file.name.split('.').pop()?.toLowerCase()

      if (!LLM_SUPPORTED_FILETYPES.includes(`${fileExtension}`)) {
        popupService.showError(`File type ${fileExtension} is not supported`)
        return
      }

      this.newDocument.displayName = this.newDocument.name
      const result = await this.uploadFile(this.current.llmId, this.newDocument.file)

      if (result && result.llmId) {
        this.$emit('refresh')
      }

      this.newDocument.link = undefined
      this.newDocument.content = undefined
      this.newDocument.displayName = undefined
    }

    //@ts-ignore
    this.current.documents.push(this.newDocument)

    this.newDocument = {
      name: '',
      type: LlmDocumentType.FILE,
      content: '',
      file: undefined,
      link: undefined,
      displayName: undefined
    }
  }

  get getDocuments() {
    return this.current.documents
  }

  deleteDocument(index) {
    this.$emit('deleteDocument', index)
  }
}
</script>

<style scoped lang="scss">
@import '../../../../assets/scss/variables';

.description {
  font-size: 14px;
  margin-bottom: 15px;
}

.empty {
  color: $grey;
  font-size: 12px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-b-20 {
  margin-bottom: 20px;
}

.documents {
  background-color: $blue-background;
  padding: 10px 5px 10px 15px;
  border-radius: 12px;
  font-weight: 500;
  color: $default-dark;

  .entity-delete {
    margin-left: 5px;
  }
}
</style>
