<template>
  <section class="content-intents">
    <div class="row sidenav">
      <div class="col-3">
        <BotSideNav
          type="Intent"
          :items="intentNames"
          :hasImportExport="hasImportExport"
          :importText="intentComboText"
          :selected-index.sync="selectedIntent"
          :selected-filter.sync="selectedFilter"
          :botName="botName"
          @delete="deleteIntent"
          @add="
            validateAction()
            $emit('add')
          "
          @import="
            validateAction()
            $emit('import')
          "
          @export="$emit('export', currentIntent)"
          @batch-import="
            validateAction()
            $emit('batch-import')
          "
          :isRedirect="isRedirect ? true : false"
          :hasBatchImport="true"
        ></BotSideNav>
      </div>
      <div class="col-9">
        <div class="content-intents__main" v-if="intentNames && intentNames[0]">
          <div class="content-intents__main-inner">
            <div class="main-header">
              <ComponentName
                :value="currentIntent.name"
                :isEditable="!isNone && hasSufficientPermission"
                :name="currentIntent.name"
                :key="currentIntent.name"
                :botName="botName"
                @input="
                  (data) => {
                    validateAction()
                    $emit('edit-name', { ...this.currentIntent, newName: data })
                  }
                "
              ></ComponentName>
              <Description
                v-model="currentIntent.description"
                :isEditable="!isNone && hasSufficientPermission"
                :description="currentIntent.description"
                :key="currentIntent.description"
                type="intent"
                @input="
                  (data) => {
                    validateAction()
                    $emit('description', { description: data, intentName: currentIntent.name })
                  }
                "
              ></Description>
              <span v-if="currentIntent.type == 'faq'" class="type-card">FAQ Intent</span>
            </div>

            <section v-if="!isRedirect && !isNone" id="entities" class="section section--intents">
              <h4>Entities</h4>
              <form @submit.prevent>
                <ul class="entities">
                  <li v-for="(entity, index) in botEntities" :key="index" class="cursor-pointer" @click="$emit('entity-selected', entity)">
                    {{ entity.name }}
                  </li>
                </ul>
              </form>
            </section>

            <section v-if="!isRedirect && !isNone" id="utterances" class="section section--questions">
              <h4>Utterance</h4>

              <div class="pills">
                <div class="add-question elements pill" v-if="hasSufficientPermission">
                  <div class="form-group">
                    <Replacer
                      v-model="utteranceInput"
                      :opened="isExampleOpened"
                      :key="isExampleOpened"
                      :items="entityNames"
                      placeholder="Type a new utterance or pattern"
                      @enter="addUtterance()"
                      :class="{ 'error-border': error }"
                    />
                    <div type="button" class="copybutton dark intent-utterance" @click="addUtterance()">Add</div>
                  </div>
                </div>
                <div class="error" v-if="error">{{ errorText }}</div>

                <div
                  v-for="(utt, index) in examples"
                  :id="utt[1]"
                  :key="`${index}-${utt.text}`"
                  :class="[editingIndex == index ? 'utterance--edit-mode' : 'utterance', 'pill']"
                  @click="setEditMode(index)"
                >
                  <div :id="utt.ids.internal + '-show'" class="description">
                    <div v-html="highlightTheEntities(utt.text)"></div>
                    <i class="remove" @click="deleteUtterance(utt)" v-show="hasSufficientPermission"></i>
                  </div>

                  <div class="editor" v-if="hasSufficientPermission && index == editingIndex">
                    <form @submit.prevent="editUtterance(utt, index)">
                      <Replacer
                        v-model="utteranceEdit"
                        :items="entityNames"
                        @input="editUtterance(utt, index)"
                        @focusout="editUtterance(utt, index, {})"
                        :autoSizing="true"
                      />
                      <i class="remove opened" @click="deleteUtterance(utt)"></i>
                    </form>
                  </div>
                </div>

                <button :class="`btn btn--gray gradient generate-button ${hasUtteranceAction ? 'disabled' : ''}`" type="button" @click="generateUtterances">
                  Auto generate <i class="icon icon-magic-wand"></i>
                </button>
              </div>
              <SmallLoading key="loading" title="Loading your bot's data" v-if="hasUtteranceAction"></SmallLoading>
            </section>
            <section v-if="currentIntent.type === 'faq'" id="utterances" class="section section--questions">
              <h4>FAQ Answer</h4>
              <ul class="utterances">
                <li
                  :class="[isAnswerEditMode ? 'utterance--edit-mode' : 'utterance', 'faq-answer']"
                  v-if="hasSufficientPermission"
                  @click="setAnswerEditMode()"
                >
                  <div v-if="!isAnswerEditMode" class="description">
                    {{ currentIntent.answer }}
                  </div>

                  <div v-else class="editor">
                    <form @submit.prevent="updateAnswer()">
                      <Replacer
                        v-model="tempAnswer"
                        :opened="isExampleOpened"
                        :key="isExampleOpened"
                        :items="entityNames"
                        :autoSizing="true"
                        :hasAddButton="true"
                        @enter="updateAnswer()"
                        @focusout="updateAnswer()"
                        placeholder="Answer"
                      />
                      <div type="button" class="copybutton dark" @click="updateAnswer()">Add</div>
                    </form>
                  </div>
                </li>
              </ul>
            </section>
          </div>
          <button v-if="currentIntent.type == 'faq'" type="button" class="btn btn--white margin-correct top-bar" @click="changeType">
            Change to normal intent
          </button>
          <BotSectionFooter
            :saveable="false"
            :exportable="!isNone"
            :export-text="intentComboText"
            :isSyncActive="isSyncActive"
            @on-export="exportIntent"
            type="Intent"
          ></BotSectionFooter>
        </div>
        <div v-else class="content-intents__empty">
          <div class="content">
            <div class="space-ship"></div>
            <h3>Start building!</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

import BotSectionFooter from '../footer/BotSectionFooter.vue'
import BotSideNav from '../sidenav/BotSideNav.vue'
import Description from '../../../helpers/Description.vue'
import Replacer from '../../../helpers/Replacer.vue'
import ComponentName from '../../../helpers/ComponentName.vue'
import popupService from '../../../../services/popup.service'
import { Permissions } from '../../../../../../common/enums/tenant/user-permissions.enum'
import permissionsService from '../../../../services/tenants/permissions.service'
import SmallLoading from '../../../helpers/SmallLoading.vue'
import Toggle from '../../../helpers/Toggle.vue'
import { CustomError } from '../../../../../../common/errors'
import TextPill from '@/components/helpers/TextPill.vue'

@Component({
  components: {
    TextPill,
    BotSectionFooter,
    BotSideNav,
    Description,
    Replacer,
    ComponentName,
    SmallLoading,
    Toggle
  }
})
export default class Intents extends Vue {
  @Prop({ default: () => [] }) readonly intents: any[]
  @Prop({ default: () => [] }) readonly entities: any[]
  @Prop({ default: () => [] }) readonly prebuiltEntities: any[]
  @Prop({ default: () => false }) readonly isRedirect: boolean
  @Prop({ default: () => 0 }) readonly active: number
  @Prop({ default: '' }) readonly botName: string
  @Prop({ default: '' }) readonly botId: string

  @Prop({ default: false }) readonly hasEditFinished: boolean
  @Prop({ default: false }) readonly hasAddFinished: boolean
  @Prop() readonly hasImportExport: boolean

  private utteranceInput = ''
  private utteranceEdit = ''
  private patternInput = ''
  private currentIntent: any = {}
  private _selectedIntent = 0
  private isExampleOpened = false
  private saveUtterance = false
  private readonly selectedFilter = 'All'
  private readonly intentComboText = 'Intent + Flow'
  private error = false
  private errorText = 'Placeholder error'
  private editingIndex = -1

  private editingUtterance: any
  private hasUtteranceAction = false

  private hasSufficientPermission = false
  private isAnswerEditMode = false
  private tempAnswer = ''

  get isSyncActive() {
    return this.$store.state.botData[this.botId]?.syncStatus.isSyncActive
  }

  get intentNames() {
    return this.intents.map((i) => i.name)
  }

  get entityNames() {
    const result = []

    for (const entity of this.entities.concat(this.prebuiltEntities)) {
      result.push(entity.name)
      if (entity.roles) {
        result.push(...entity.roles.map((e) => entity.name + ':' + e.name))
      }
    }
    return result
  }

  get botEntities() {
    const usedEntityNames = {}

    const allExamples = (this.currentIntent.utterances || []).concat(this.currentIntent.patterns || [])

    for (const example of allExamples) {
      // Regex copied from examples.service.ts
      // [1] is the entity name:role pair
      const regex = /(?:[^/][[]|^\[)(.*?)(?=\])/gm
      let m

      while ((m = regex.exec(example.text)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++
        }

        const entityName = m[1].split(':')[0]
        usedEntityNames[entityName] = true
      }
    }

    return this.entities.concat(this.prebuiltEntities).filter((e) => usedEntityNames[e.name])
  }

  get selectedIntent() {
    return this.$data._selectedIntent
  }

  set selectedIntent(newIndex) {
    this.$data._selectedIntent = newIndex
    this.currentIntent = this.intents.length > newIndex ? this.intents[newIndex] : {}

    this.isExampleOpened = false
    this.utteranceInput = ''
    this.editingIndex = -1
    this.saveUtterance = false
    this.$emit('select', this.currentIntent)

    this.$router
      .push({
        name: 'BotTab',
        params: {
          botName: this.botName,
          tab: 'intents',
          element: this.intents[this.selectedIntent].name
        }
      })
      .catch((_) => {})

    this.$emit('updatedSelectedElement', {
      elementTab: 'intents',
      newName: this.intents[this.selectedIntent].name
    })
  }

  get utterances() {
    return this.currentIntent.utterances || []
  }

  get patterns() {
    return this.currentIntent.patterns || []
  }

  get examples() {
    return this.utterances.concat(this.patterns)
  }

  get isNone() {
    return this.currentIntent.name === 'None'
  }

  get permissions() {
    return Permissions
  }

  @Watch('currentIntent', { deep: true })
  private onUtteranceChanged() {
    this.hasUtteranceAction = false
  }

  @Watch('hasEditFinished')
  private onEditFinished() {
    this.hasUtteranceAction = false
  }

  @Watch('intents', { deep: true })
  private onIntentsChanged() {
    this.updateSelectedIndex()
  }

  mounted() {
    this.updateSelectedIndex()
    this.markUsedPrebuiltEntities()
  }

  async changeType() {
    if (await popupService.showDialog('Warning!', 'Are you sure you wan to change the intent type?', 'Change', 'Cancel')) {
      this.currentIntent.type = 'normal'
      this.$emit('change-type', this.currentIntent)
    } else {
      this.currentIntent.type = 'faq'
    }
  }

  private updateSelectedIndex() {
    const index = this.intents.findIndex((i) => i.name == this.$router.currentRoute.params.element)
    this.selectedIntent = index >= 0 ? index : this.active
  }

  async beforeMount() {
    this.hasSufficientPermission = await permissionsService.hasPermission(this.botName, this.permissions.ManageIntents)
  }

  markUsedPrebuiltEntities() {
    for (const entity of this.prebuiltEntities) {
      entity.used = this.entities.some((e) => e.name === entity.name)
    }
  }

  highlightTheEntities(intent: string) {
    return intent.replace(/\[/g, '<span class="entity-label">').replace(/\]/g, '</span>')
  }

  setAnswerEditMode() {
    this.isAnswerEditMode = true
    this.tempAnswer = this.currentIntent.answer
  }

  updateAnswer() {
    this.isAnswerEditMode = false
    if (this.tempAnswer != '') {
      this.currentIntent.answer = this.tempAnswer
      this.$emit('update-answer', this.currentIntent)
    } else {
      popupService.showError('Validation error', 'FAQ Intent answer could not be empty')
    }
  }

  addUtterance() {
    this.validateAction()
    this.error = false
    this.hasUtteranceAction = true
    if (this.utteranceInput.trim().length === 1) {
      this.error = true
      this.errorText = 'Your utterance must contain more than 1 character'
      this.hasUtteranceAction = false
      return
    }

    this.$emit('create-example', {
      intent: this.currentIntent,
      exampleText: this.utteranceInput.trim()
    })

    this.utteranceEdit = ''
    this.editingIndex = -1
  }

  @Watch('hasAddFinished')
  private onAddStatusChange() {
    this.utteranceInput = ''
    this.hasUtteranceAction = false
  }

  openUtterance() {
    if (this.saveUtterance) {
      this.addUtterance()
    } else {
      this.isExampleOpened = true
      this.saveUtterance = true
    }
  }

  private tempUtt = null
  private tempIndex = -1
  private uttText = ''

  editUtterance(utterance, index, focusout?) {
    if (this.utterances[index].text != this.utteranceEdit) {
      this.validateAction()
      if (this.tempIndex != -1 && this.tempIndex != index && this.tempUtt != null) {
        this.tempUtt.text = this.uttText
        this.makeUttUpdate(this.tempUtt)
      }

      if (focusout) {
        this.tempUtt.text = this.uttText
        this.makeUttUpdate(this.tempUtt)
      }

      this.uttText = this.utteranceEdit
      this.editingUtterance = utterance
      this.tempUtt = utterance
      this.tempIndex = index
    } else {
      this.hasUtteranceAction = false
      this.editingIndex = -1
    }
  }

  @Emit('update-example')
  private makeUttUpdate(utterance) {
    this.hasUtteranceAction = true
    return {
      intent: this.currentIntent,
      example: utterance
    }
  }

  @Watch('hasEditFinished')
  private onEditStatusChange() {
    this.editingIndex = -1
  }

  generateUtterances() {
    if (this.hasUtteranceAction) {
      return
    }
    this.hasUtteranceAction = true
    this.$emit('generate-example', {
      intent: this.currentIntent
    })
  }

  deleteUtterance(utterance) {
    this.validateAction()
    popupService.deletePopupEvent('utterance ' + utterance.text, () => {
      this.hasUtteranceAction = true
      this.$emit('delete-example', {
        intent: this.currentIntent,
        example: utterance
      })
    })
  }

  deleteIntent(index: number) {
    this.validateAction()
    const intent = this.intents[index]
    if (intent.name === 'None') {
      popupService.showError('You cannot delete the None intent!')
      return
    }
    popupService.deletePopupEvent('intent ' + intent.name, () => {
      this.currentIntent = this.intents[index - 1]
      this.$emit('delete', intent)
    })
  }

  testForBrackets(string) {
    let counter = 0
    let lastIndex = 0

    for (let i = 0; i < string.length; i++) {
      if (string[i] === '{') {
        counter++

        // Check if this is the second opening consecutive bracket (e.g ....[...[..])
        if (counter >= 2) {
          for (let j = lastIndex + 1; j < string.length; j++) {
            // Cut the string to nearest blank space, other variable, new line or html new line tag
            if (string[j] === ' ' || string[j] === '{' || string[j] === '\n' || string.substr(j, 5) === '<br/>') {
              return {
                varInput: true,
                from: lastIndex,
                to: j
              }
            }
          }
        }

        lastIndex = i
      } else if (string[i] === '}') {
        counter--
      }
    }

    if (counter > 0) {
      return {
        varInput: true,
        from: lastIndex,
        to: string.length
      }
    }

    return {
      varInput: false
    }
  }

  setEditMode(index: number) {
    if (this.hasSufficientPermission) {
      this.validateAction()
      this.utteranceEdit = this.examples[index].text
      this.editingIndex = index
    }
  }

  exportIntent() {
    this.$emit('export', this.currentIntent)
  }

  validateAction() {
    if (this.isSyncActive) throw new CustomError('You cannot add/update/delete intents when publishing!')
  }
}
</script>

<style lang="scss" scoped>
.faq-answer {
  height: 43px;
}
.generate-button {
  height: 41px;
  margin-left: 0;
  padding-right: 10px;
}
</style>
