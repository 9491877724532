import axios from 'axios'
import { ExternalProvider } from '@common/enums/external-provider.enum'
import { SyncObjectQueryResult } from '@common/interfaces/sync-object-query-result'
import { SynchronizationError } from '@common/errors'
import * as events from 'events'

export class SyncService {
  private readonly url: string
  private readonly provider: ExternalProvider
  private readonly syncId: string
  public readonly emitter = new events.EventEmitter()

  private cacheId?: string

  private getConfig(state: { created?: boolean; updated?: boolean; deleted?: boolean } = {}) {
    return {
      params: {
        ...state,
        provider: this.provider,
        syncId: this.syncId,
        cacheId: this.cacheId
      }
    }
  }

  constructor(botName: string, syncId: string, provider: ExternalProvider) {
    this.url = `${process.env.VUE_APP_BACKEND_URL}/synchronization/${botName}`
    this.syncId = syncId
    this.provider = provider
  }

  // All-at-once sync
  async startAllAtOnceSync() {
    try {
      await axios.post(`${this.url}/sync`, {}, this.getConfig())
    } catch (e) {
      if (e.response && e.response.data) {
        if (e.response.data.code && e.response.data.code === 'sync_error') {
          throw new SynchronizationError(e.response.data.message, this.provider, e.response.data.internalMessage)
        }
      }
      throw e
    }
  }

  // Full progressive sync
  async executeFullProgressiveSync() {
    try {
      await axios.post(`${this.url}/full`, {}, this.getConfig({ created: true, updated: true, deleted: true }))
    } catch (e) {
      if (e.code === 'validation_error') {
        console.log('SynchronizationError', e)
        throw new SynchronizationError(e.message, this.provider, 'Sync failed')
      }
      console.log('e', e)
      throw e
    }
  }

  async waitSync() {
    let isDone = false
    do {
      await new Promise(res => setTimeout(res, 4000))
      let res
      try {
        res = await axios.get(`${this.url}/progress`, this.getConfig())
      } catch (e) {
        if (e.response && !e.response.data.code && e.response.status === 500 && this.provider === ExternalProvider.NeuralSpace) {
          isDone = false
          continue
        } else if (e.response && e.response.data) {
          if (e.response.data.code && e.response.data.code === 'sync_error') {
            throw new SynchronizationError(e.response.data.message, this.provider, e.response.data.internalMessage)
          }
        }
        throw e
      }
      isDone = res.data.isDone
      if (res.data.progress !== undefined) {
        this.emitter.emit('progress', res.data.progress)
      }
    } while (!isDone)
    this.emitter.emit('complete')
  }

  // Progressive sync
  async loadCache() {
    const res = await axios.post(`${this.url}/cache`, {}, this.getConfig())
    this.cacheId = res.data.cacheId
  }

  async clearCache() {
    await axios.delete(`${this.url}/cache`, this.getConfig())
  }

  async getEntities(): Promise<SyncObjectQueryResult<any>> {
    return (await axios.get(`${this.url}/entities`, this.getConfig({
      created: true,
      updated: true,
      deleted: true
    }))).data
  }

  async syncDeletedEntities() {
    await axios.put(`${this.url}/entities`, {}, this.getConfig({ deleted: true }))
  }

  async syncCreatedEntity(entityName: string) {
    await axios.post(`${this.url}/entities/${entityName}`, {}, this.getConfig())
  }

  async syncUpdatedEntity(entityName: string) {
    await axios.put(`${this.url}/entities/${entityName}`, {}, this.getConfig())
  }

  async getIntents(): Promise<SyncObjectQueryResult<any>> {
    return (await axios.get(`${this.url}/intents`, this.getConfig({
      created: true,
      updated: true,
      deleted: true
    }))).data
  }

  async syncDeletedIntents() {
    await axios.put(`${this.url}/intents`, {}, this.getConfig({ deleted: true }))
  }

  async syncCreatedIntent(intentName: string) {
    await axios.post(`${this.url}/intents/${intentName}`, {}, this.getConfig())
  }

  async syncUpdatedIntent(intentName: string) {
    await axios.put(`${this.url}/intents/${intentName}`, {}, this.getConfig())
  }

  async startTraining() {
    await axios.put(`${this.url}/train`, {}, this.getConfig())
  }

  static async getSyncStatus(botName: string) {
    return (await axios.get(`${process.env.VUE_APP_BACKEND_URL}/synchronization/${botName}/status`)).data
  }
}
