import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    defaultLanguage: 'English',
    currentLanguage: 'English', // Default language is English
    supportedLanguages: ['English'], // Default language is English
    botData: {
      'bot-id': {
        syncStatus: {
          isSyncActive: false,
          providersBeingPublished: []
        }
      }
    }
  },
  mutations: {
    setBotDefaultLanguage(state, language) {
      state.defaultLanguage = language
    },
    setCurrentLanguage(state, language) {
      state.currentLanguage = language
      // console.log('currentLanguage (main language)', state.currentLanguage)
    },
    setSupportedLanguages(state, languages) {
      state.supportedLanguages = languages
      // console.log('supportedLanguages (supported languages)', state.supportedLanguages)
    },
    addSupportedLanguage(state, language) {
      state.supportedLanguages.push(language)
      // console.log('addSupportedLanguage (add supported language)', state.supportedLanguages)
    },
    deleteSupportedLanguage(state, language) {
      state.supportedLanguages = state.supportedLanguages.filter((lang: string) => lang !== language)
      // console.log('deleteSupportedLanguage (delete supported language)', state.supportedLanguages)
    },
    addBotActivePublisher(state, props: {botId: string, provider: string}) {
     if(!state.botData[props.botId]) {
        Vue.set(state.botData, props.botId,{
          syncStatus: {
            isSyncActive: true,
            providersBeingPublished: [props.provider]
          }
        });
      } else {
        state.botData[props.botId].syncStatus.providersBeingPublished.push(props.provider)
        state.botData[props.botId].syncStatus.isSyncActive = true
      }
   },
    removeBotActivePublisher(state, props: {botId: string, provider: string}) {
      console.log('removing', props)
      const providersBeingPublished = state.botData[props.botId].syncStatus.providersBeingPublished
      const index = providersBeingPublished.indexOf(props.provider)
      providersBeingPublished.splice(index, 1)
      state.botData[props.botId].syncStatus.isSyncActive = providersBeingPublished.length > 0
      console.log(state.botData)
    }
  }
})
