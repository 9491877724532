<template>
  <div class="rect rect--custom" v-if="!!examples?.[0]?.text">
    <h1 style="text-align: left">{{ title }}</h1>
    <div>
      <div class="row" v-for="example of examples" :key="example.text">
        <div class="col-10">
          <h3>{{ example.text }}</h3>
        </div>
        <div class="col-2 text-right">
          <span class="amount"
            >{{ fix(example.formula) }} <span class="suffix">{{ example.suffix ?? '' }}</span></span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { fix } from '../filters/number.filters'

@Component({})
export default class Custom extends Vue {
  @Prop({ default: 'Custom widget' }) readonly title: string
  @Prop({ default: 1 }) readonly decimals: number
  @Prop({ default: () => [] }) readonly examples: Array<any>

  fix(value: number, decimals?: number) {
    return fix(value, decimals)
  }
}
</script>

<style lang="scss" scoped>
@import 'analytics.style';

.text-right {
  .amount {
    color: $default-dark;
    min-width: 30px;
    display: inline-block;
    text-align: right;
    padding: 2px 6px;
    font-size: 12px;
    font-weight: 600;

    .suffix {
      color: $dark-grey;
      font-size: 10px;
      font-weight: 700;
      margin-left: -1.5px;
    }
  }
}

.rect {
  &--custom {
    position: relative;

    h1 {
      color: $main;
      font-size: 14px;
      font-weight: 600;
      line-height: 15px;
      text-transform: uppercase;
    }

    h2,
    h3,
    p {
      margin: 0;
    }

    h2 {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      line-height: 23px;
      margin-bottom: 15px;
    }

    h3 {
      font-size: 12px;
      font-weight: 600;
      color: $secondary-text;
      line-height: 15px;
      text-transform: uppercase;
    }

    p {
      position: relative;
      top: 1px;
      font-size: 14px;
      font-weight: 400;
      color: #000;
    }

    &__border {
      display: inline-block;
      border-top: 1px solid #d8d8d8;
      width: 100%;
      margin: 8px 15px 12px;
    }
  }
}
</style>
