import { Component, Vue } from 'vue-property-decorator'

/**
 * Component uses mixin:
 *    export default class Connections extends Mixins(DropdownManagementMixin...
 */
@Component
export default class DropdownMixin extends Vue {
  public dropdownOpen = ''
  private dropdownListenOutside = null
  private static all: DropdownMixin[] = []

  constructor() {
    super()
    DropdownMixin.all.push(this)
    document.addEventListener('click', this.dropdownClickOutside)
  }

  destroyed() {
    const index = DropdownMixin.all.indexOf(this)
    if (index !== -1) {
      DropdownMixin.all.splice(index, 1)
    }
    document.removeEventListener('click', this.dropdownClickOutside)
  }

  /**
   * Toggle uses dropdownClick:
   *    <a href="#" class="dropdown-toggle" v-on:click.prevent.stop="dropdownClick('myDropdownName')">
   */
  dropdownClick(name: string) {
    // Configuration page has several components with DropdownMixin,
    // ...but only one of them may show a dropdown.
    DropdownMixin.all.forEach((d) => d.handleClick(name))
  }
  handleClick(name: string) {
    /**
     * Menu uses dropdownOpen:
     *  <div class="dropdown-menu" :class="{show:dropdownOpen === 'myDropdownName'}">
     */

    if (this.dropdownOpen === name) {
      this.dropdownOpen = '' // close after clicking toggle again
    } else {
      this.dropdownOpen = name
    }
  }

  dropdownClickOutside(event: PointerEvent) {
    // close dropdown when clicking anywhere except the toggle button
    if (event.target instanceof Element) {
      if (event.target.closest('.dropdown-toggle')) {
        return
      }
    }
    this.dropdownOpen = ''
  }
}
