<template>
  <Modal :title="`${provider} Text-to-speech Settings`" :show="true" @close="$emit('close')">
    <div class="form--wrapper general">
      <div class="form-group">
        <div class="form-group--inside">
          <label>Language</label>
          <Select :elements="options.textToSpeechLanguageCode"
                  v-model="newSettings.textToSpeechLanguageCode"
                  keyAttr="0" valueAttr="1" class="white-select"></Select>
        </div>
        <div v-if="!newSettings.textToSpeechLanguageCode" class="invalid">Please select a language</div>

        <div class="form-group--inside">
          <label>Preferred voice type</label>
          <Select :elements="options.textToSpeechPreferredVoiceType"
                  v-model="newSettings.textToSpeechPreferredVoiceType"
                  @change="checkSettings"
                  keyAttr="label" valueAttr="id" class="white-select"></Select>
        </div>

        <div v-show="options.textToSpeechSpeed" class="form-group--inside">
          <label>Speed</label><br />
          <input type="range" :value="newSettings.textToSpeechSpeed" :min="speedMin" :max="speedMax" :step="speedStep" @change="changeSpeed" />
          {{ newSettings.textToSpeechSpeed }}
        </div>

        <div v-show="options.textToSpeechResponseFormat" class="form-group--inside">
          <label>Format</label>
          <Select :elements="options.textToSpeechResponseFormat"
                  v-model="newSettings.textToSpeechResponseFormat"
                  @change="otherOptionsCouldChange"
                  keyAttr="label" valueAttr="id" class="white-select"></Select>
        </div>

        <div class="form-group--inside">
          <label>Silence until idle speech.</label><br />
          <input type="range" :value="newSettings.idleTimeout" :min="0.5" :max="10" :step="0.1" @change="changeIdleTimeout" />
          {{ newSettings.idleTimeout }} seconds
        </div>

        <div class="form-group--inside">
          <label>Random idle phrases to fill silence. Separate with enter.</label><br />
          <textarea :value="newSettings.idleText" @change="changeIdleText"></textarea>
        </div>

        <div v-show="options.textToSpeechModel" class="form-group--inside">
          <label>Model</label>
          <Select :elements="options.textToSpeechModel"
                  v-model="newSettings.textToSpeechModel"
                  @change="otherOptionsCouldChange"
                  keyAttr="label" valueAttr="id" class="white-select"></Select>
        </div>

        <div v-show="options.textToSpeechStreaming" class="form-group--inside">
          <Toggle v-model="newSettings.textToSpeechStreaming"
                  @change="otherOptionsCouldChange"
                  label="streaming" ></Toggle>
        </div>

      </div>
      <div v-show="invalidSettings.length > 0" class="form-group--inside invalid">
        Please check: <span v-for="(_el, index) in invalidSettings">
        {{ invalidSettings[index] }}
      </span>
      </div>
      <button class="btn btn--purple gradient" type="button" @click="save()" :disabled="invalidSettings.length > 0">Save settings <i class="icon icon-save"></i></button>
    </div>
  </Modal>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator'
import FormGroup from '../../../../../helpers/FormGroup.vue'
import Autocomplete from '../../../../../helpers/Autocomplete.vue'
import Select from '../../../../../helpers/Select.vue'
import Modal from '../../../../../helpers/Modal.vue'
import Toggle from '../../../../../helpers/Toggle.vue'
import {ExternalProvider} from '@common/enums/external-provider.enum'
import {IBotSettings} from '@common/interfaces/bot-settings.interface'
import {SettingsHelper, TTSOptions, TTSSettings} from "@common/helpers/settings.helper";

@Component({
  computed: {
    ExternalProvider() {
      return ExternalProvider
    }
  },
  components: {
    FormGroup,
    Autocomplete,
    Select,
    Toggle,
    Modal
  }
})
export default class TTSConfiguration extends Vue {
  @Prop({ default: ExternalProvider.Microsoft }) provider: ExternalProvider
  @Prop() settings: TTSSettings
  newSettings: TTSSettings
  defaultSettings: TTSSettings
  options: TTSOptions
  invalidSettings = [];

  speedMin = 0.3
  speedMax = 4.0
  speedStep = 0.1

  // Reactive properties
  data() {
    return {
      newSettings: JSON.parse(JSON.stringify(this.settings ?? {})) // deep clone settings.
    }
  }

  created() {
    this.defaultSettings = SettingsHelper.getTTSDefault(this.provider);
    this.options = SettingsHelper.getTTSOptions(this.provider, this.settings);
    this.checkSettings()
  }

  save() {
    const diff: Partial<IBotSettings> = {}
    for (const key of Object.keys(this.defaultSettings)) {
      if (this.newSettings[key]!==this.settings[key]) {
        diff[key] = this.newSettings[key]
      }
    }
    // eslint-disable-next-line no-console
    this.$emit('save', diff) // --> Configuration.save --> Settings.saveSettings --> onFormSubmit --> ConfigurationManagementMixin.updateConfiguration
    this.$emit('close')
  }

  changeSpeed(e: InputEvent) {
    this.newSettings.textToSpeechSpeed = parseFloat((e.target as HTMLInputElement).value)
    console.log('changeSpeed', this.newSettings.textToSpeechSpeed)
  }

  changeIdleTimeout(e: InputEvent) {
    this.newSettings.idleTimeout = parseFloat((e.target as HTMLInputElement).value)
  }

  changeIdleText(e: InputEvent) {
    this.newSettings.idleText = (e.target as HTMLInputElement).value
  }

  /**
   * Certain option change other options,
   * e.g. streaming with TTS model --> output format is only mp3
   */
  otherOptionsCouldChange() {
    this.options = SettingsHelper.getTTSOptions(this.provider, this.newSettings);
    this.checkSettings()
  }
  checkSettings() {
    this.invalidSettings = [];
    // Check if all settings have an allowed value.
    for (const key of Object.keys(this.options)) {
      const currentValue = this.newSettings[key];
      const allowedValues = this.options[key];
      if (Array.isArray(allowedValues)) {
        // Multiple choice
        if (!allowedValues.some(item=>Object.values(item).includes(currentValue))) {
          // The current value is no longer valid because
          // a. it was set for a different TTS providers.
          // b. some other option restricted the allowed values.
          this.newSettings[key] = null; // clear current value and force select menu to change.
          this.invalidSettings.push(key)
          return;
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.invalid {
  color: red;
}
</style>