<template>
  <div class="background">
    <div :class="`banner ${type}`">
      {{ description }}
      <div class="d-flex flex-column ms-2">
        <a v-if="infoLink && infoText"
          class="info-link"
          :href="infoLink"
          target="_blank"
        >
          {{ infoText}}
        </a>
        <button
          v-if="buttonValue"
          class="btn btn--purple gradient narrow mt-2"
          type="button"
          :disabled="disabled"
          @click="$emit('buttonAction')"
        >
          <i v-if="publishIcon" class="icon icon-paperplane"></i>
          {{ buttonValue }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class WarningBanner extends Vue {
  @Prop({ default: '' }) readonly description: string
  @Prop({ default: '' }) readonly buttonValue: string
  @Prop({ default: '' }) readonly infoLink: string
  @Prop({ default: '' }) readonly infoText: string
  @Prop({ default: 'normal' }) readonly type: 'normal' | 'warning' | 'error'
  @Prop({ default: false }) readonly publishIcon: boolean
  @Prop({ default: false }) readonly disabled: boolean
}
</script>
