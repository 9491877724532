import Vue from 'vue'
import Router from 'vue-router'

import Bots from '@/components/admin/bots/Bots'
import Bot from '@/components/admin/bots/Bot'
import LLMs from '@/components/admin/llms/LLMs'
import LLM from '@/components/admin/llms/LLM'
import FlowEditor from '@/components/admin/flow-editor/FlowEditor'
import NotFound from '@/components/auth/404'
import UserManual from '@/components/admin/user-manual/UserManual'
import GoogleSetup from '@/components/client/GoogleSetup'
import ExternalTestChat from '@/components/client/chat/external/ExternalTestChat'
import ConfigurationPage from '@/components/admin/configuration/ConfigurationPage'
import MasterConfigurationPage from '@/components/admin/configuration/MasterConfigurationPage'
import ConversationLog from '@/components/client/ConversationLog'
import LogIn from '@/components/auth/LogIn'
import SignUp from '@/components/auth/SignUp'
import LogOut from '@/components/auth/LogOut'
import ForgotPassword from '@/components/auth/ForgotPassword'
import PasswordReset from '@/components/auth/PasswordReset'
import StyleDoc from '../components/admin/docs/StyleDoc'
import authService from '../services/auth.service'

Vue.use(Router)

const routes = [
  {
    path: '/bots/:botName/conversation/log',
    name: 'ConversationLog',
    component: ConversationLog,
    meta: {
      title: 'Blits.ai | Conversation Log',
      requireAuth: true,
      defaultBackground: false
    }
  },
  {
    path: '/bots/:botName/conversation/log/:conversationId',
    name: 'ConversationLogDetail',
    component: ConversationLog,
    meta: {
      title: 'Blits.ai | Conversation Log',
      requireAuth: true,
      defaultBackground: false
    }
  },
  {
    path: '/configuration/:page?',
    name: 'ConfigurationPage',
    component: ConfigurationPage,
    meta: {
      title: 'Blits.ai | User Administration',
      requireAuth: true,
      defaultBackground: false
    }
  },
  {
    path: '/master-configuration/:page?',
    name: 'MasterConfigurationPage',
    component: MasterConfigurationPage,
    meta: {
      title: 'Blits.ai | Tenant Administration',
      requireAuth: true,
      defaultBackground: false
    }
  },
  {
    path: '/llms',
    name: 'Blits.ai | LLMs',
    component: LLMs,
    meta: {
      name: 'Blits.ai | LLMs',
      requireAuth: true,
      defaultBackground: false
    }
  },
  {
    path: '/llms/:llmid',
    name: 'LLM',
    component: LLM,
    meta: {
      title: 'Blits.ai | LLM',
      requireAuth: true,
      defaultBackground: false
    }
  },
  {
    path: '/bots',
    name: 'Bots',
    component: Bots,
    meta: {
      title: 'Blits.ai | Bots',
      requireAuth: true
      // requireRoles: [
      //   'FlowAdmin'
      // ]
    }
  },
  {
    path: '/google/setup',
    name: 'GoogleSetup',
    component: GoogleSetup,
    meta: {
      title: 'Blits.ai | Google Cloud Project Setup',
      requireAuth: true
      // requireRoles: [
      //   'FlowAdmin'
      // ]
    }
  },
  {
    path: '/bots/:botName/flow',
    name: 'FlowEditor',
    component: FlowEditor,
    props: true,
    meta: {
      title: 'Blits.ai | Dialog Manager',
      requireAuth: true
      // requireRoles: [
      //   'FlowAdmin'
      // ]
    }
  },
  {
    path: '/bots/:botName',
    redirect: '/bots/:botName/analytics'
  },
  {
    path: '/bots/:botName/:tab',
    name: 'Bot',
    component: Bot,
    props: (route) => ({ tab: route.params.tab }),
    meta: {
      title: 'Blits.ai | Bot',
      requireAuth: true
    }
  },
  {
    path: '/bots/:botName/settings/:settingsTab',
    name: 'BotSettings',
    component: Bot,
    props: (route) => ({
      tab: 'settings',
      settingsTab: route.params.settingsTab
    }),
    meta: {
      title: 'Blits.ai | Bot Settings',
      requireAuth: true
    }
  },
  {
    path: '/bots/:botName/:tab/:element',
    name: 'BotTab',
    component: Bot,
    props: (route) => ({ tab: route.params.tab }),
    meta: {
      title: 'Blits.ai | Bot',
      requireAuth: true
    }
  },
  // {
  //   path: '/external/:tenantId',
  //   name: 'External Chat',
  //   component: ExternalChat,
  //   meta: {
  //     title: 'Blits.ai | External Chat',
  //     requireAuth: false
  //   }
  // },
  {
    path: '/login',
    name: 'LogIn',
    component: LogIn,
    meta: {
      title: 'Blits.ai | Log In'
    }
  },
  // {
  //   path: '/signup',
  //   name: 'SignUp',
  //   component: SignUp,
  //   meta: {
  //     title: 'Blits.ai | Sign Up'
  //   }
  // },
  // {
  //   path: '/signup/:prod_name/coupon/:coupon_id/title/:title',
  //   name: 'SignUp with Coupon',
  //   component: SignUp,
  //   meta: {
  //     title: 'Blits.ai | Sign Up'
  //   }
  // },
  // {
  //   path: '/signup/:plan_id/:uuid/:activation_email/:invoice_item_uuid',
  //   name: 'SignUp with Plan',
  //   component: SignUp,
  //   meta: {
  //     title: 'Blits.ai | Sign Up'
  //   }
  // },
  {
    path: '/logout',
    name: 'LogOut',
    component: LogOut,
    meta: {
      title: 'Blits.ai | Log Out'
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: 'Blits.ai | Forgot Password'
    }
  },
  {
    path: '/password/reset/:id',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: {
      title: 'Blits.ai | Password Reset'
    }
  },
  {
    path: '/user-manual',
    name: 'Blits.ai Manual Doc',
    component: UserManual,
    meta: {
      title: 'Blits.ai | Manual',
      requireAuth: false
    }
  },
  {
    path: '/user-manual/**',
    name: 'Blits.ai Manual',
    component: UserManual,
    meta: {
      title: 'Blits.ai | Manual',
      requireAuth: false
    }
  },
  {
    path: '/test-chat',
    name: 'Blits.ai Builder Test Chat',
    component: ExternalTestChat,
    meta: {
      title: 'Blits.ai Builder | Test Chat',
      requireAuth: false
    }
  },
  {
    path: '/style-guide',
    name: 'Blits.ai Style Guide',
    component: StyleDoc,
    isOnlyLocal: true,
    meta: {
      title: 'Blits.ai Builder | Test Chat',
      requireAuth: false
    }
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/404',
    name: 'Page not found',
    component: NotFound,
    meta: {
      title: 'Blits.ai | Page not found'
    }
  },
  {
    path: '/',
    redirect: '/login',
    meta: {
      requireAuth: false
    }
  }
]

const router = new Router({
  mode: 'history',
  routes: process.env.NODE_ENV === 'production' ? routes.filter((e) => !e.isOnlyLocal) : routes
})

// Global route guard
router.beforeEach((to, from, next) => {
  // add page title
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title
  next()
})

router.beforeEach(async (to, from, next) => {
  if (!to.meta.requireAuth || (await authService.isAuthenticated())) {
    next()
  } else {
    next('/login')
  }
})

export default router
