<template>
  <div v-if="current" class="form--wrapper">
    <section id="common">
      <div class="page" v-if="page === 1">
        <h4>Please choose a provider from the options below</h4>
        <div class="description">
          Here, you can select your preferred Large Language Model (LLM) provider to get started with building and deploying your own custom language model.
        </div>
        <div class="selector-wrapper">
          <div v-for="(provider, index) in providers">
            <div v-if="provider.enabled" class="provider" :class="provider.selected ? 'selected' : ''" @click="selectProvider(provider)">
              <div class="provider-content">
                <img class="image" :src="provider.icon" alt="provider.name" />
                <div class="text-content">
                  <div class="name">{{ provider.name }}</div>
                  <div class="description">{{ provider.description }}</div>
                </div>
              </div>
            </div>

            <div v-else class="provider disabled">
              <div class="provider-content">
                <img class="image" :src="provider.icon" alt="provider.name" />
                <div class="text-content">
                  <div class="name">{{ provider.name }}</div>
                  <div class="description">Following soon as part of our ecosystem</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page" v-if="page === 2">
        <LLMdetails :newLLM="current" :variables="variables" />
      </div>

      <footer class="modal-footer-button">
        <button v-if="page !== 2" class="btn btn--purple gradient" @click="page = page + 1" type="button">
          Next step
          <i class="icon icon-arrow-right"></i>
        </button>

        <button v-if="page === 2" class="btn btn--purple gradient" :disabled="disabled" @click="createLLM" type="button">
          Create LLM
          <i class="icon icon-arrow-right"></i>
        </button>

        <button v-if="page !== 1" class="btn btn--white blue" style="margin-right: 20px" @click="page = page - 1" type="button">Back</button>
      </footer>
    </section>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { LlmProviderEnum } from '../../../../../common/enums/bot/llm/llm-provider.enum'
import Autocomplete from '@/components/helpers/Autocomplete.vue'
import UploadFileExtension from '@/components/admin/flow-editor/block-config/block-types/task-block/block-extensions/UploadFileExtension.vue'
import { LLM } from '../../../../../common/types/llm-type'
import LLMdetails from './components/LLMdetails.vue'
import { providers } from '@common/enums/bot/llm/blits-enabled-providers'

@Component({ components: { UploadFileExtension, Autocomplete, LLMdetails } })
export default class newLLM extends Vue {
  @Prop() variables
  @Prop() selectedFilter

  page = 1
  disabled = false
  providers = providers

  current: LLM = {
    llmDisplayName: '',
    description: '',
    model: 'default',
    embeddingModel: 'default',
    isComplete: false,
    llmProvider: LlmProviderEnum.OpenAI,
    documents: []
  }

  newData(_) {
    this.page = 2
    this.disabled = false
  }

  get LlmProviderEnum() {
    return LlmProviderEnum
  }

  selectProvider(provider) {
    for (let prof of this.providers) {
      prof.selected = false
    }
    provider.selected = true
    this.current.llmProvider = provider.enum
  }

  createLLM() {
    this.disabled = true
    this.$emit('createLLM', this.current)

    setTimeout(() => {
      this.disabled = false
    }, 5000)
  }
}
</script>

<style scoped lang="scss">
@import '../../../assets/scss/variables';

.empty {
  font-size: 14px;
  color: $grey;
  margin-top: 20px;
}

.page {
  min-height: 400px;
}

.selector-wrapper {
  min-height: 200px;
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .provider {
    background-color: white;
    border: 2px solid $light-blue;
    width: 100%;
    height: 100px;
    padding: 0px 15px;
    border-radius: 12px;
    display: flex;

    cursor: pointer;
    transition: all 0.2s ease;

    .provider-content {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .text-content {
      flex: 1;
    }

    .name {
      font-size: 18px;
      font-weight: bold;
    }

    .description {
      font-size: 14px;
      color: $grey;
    }

    &.disabled {
      background-color: #d0d0d0;
      cursor: not-allowed;
    }

    &.selected {
      background-color: $main;
      border-color: $main;

      .name {
        color: $white;
      }

      .description {
        color: $white;
      }
    }

    .image {
      height: 50px;
      width: 50px;
      object-fit: contain;
    }
  }
}
</style>
