<template>
  <div class="app-dropdown show">
    <button
      type="button"
      aria-haspopup="true"
      aria-expanded="false"
      class="btn intents dropdown-toggle dropdown-toggle-split"
      v-on:click.prevent.stop="dropdownClick('app-dropdown')"
    >{{ displayTitle }}</button>
    <div class="dropdown-menu dropdown-menu--options"
         :class="{show:dropdownOpen === 'myDropdownName'}"
         x-placement="bottom-start">
      <div v-for="(item, index) in items" :key="index" class="dropdown-item-editable">
        <a
          href="#"
          class="dropdown-item"
          @click.prevent="$emit('input', item)"
        >
          {{item.value || item}}
        </a>
        <i @click="$emit('delete', item)" class="icon-delete"></i>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Mixins, Prop, Vue} from 'vue-property-decorator'
import DropdownMixin from "@/components/admin/bots/_mixins/dropdown.mixin";

@Component
export default class DropdownEditable extends Mixins(Vue, DropdownMixin) {
  @Prop({ default: 'Choose an option' }) readonly title: string;
  @Prop({ default: () => [] }) readonly items: any[];
  @Prop({ default: 'All' }) readonly value: string;

  get displayTitle () {
    return this.value || this.title
  }
}
</script>

<style lang="scss">
.dropdown-item-editable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  a {
    width: 90%;
    padding: 0.25rem 0;
  }
}
</style>
