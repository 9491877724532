<template>
  <div class="configuration-tabs no-overflow full line">
    <div class="configuration-tabs--inner">
      <button
        v-for="([key, tab], index) in Object.entries(tabs)"
        :key="index"
        type="button"
        class="tab"
        :class="{ 'tab--active': selectedTab == key}"

        @click="onTabClick(key)"
      >
        {{ tab.title }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Tab, TabPage } from '@/components/admin/bots/settings/_mixins/settings-management.mixin'

@Component
export default class AdminPannel extends Vue {

  @Prop() readonly botName!: string
  @Prop({ default: () => ({}) }) readonly tabs!: { [key: string]: Tab }
  @Prop() settingsTab: string

  private selectedTab = this.$router.history.current.params.settingsTab ?? TabPage.general

  @Emit('change')
  changeTab(key: TabPage) {
    this.selectedTab = key
    return key
  }

  onTabClick(key: TabPage) {
    this.changeTab(key);
    this.$router
      .push({
        name: 'BotSettings',
        params: {
          botName: this.botName,
          settingsTab: key
        }
      })
      .catch((_) => {
      })
  }
}
</script>
