/**
 * These helpers are (indirectly) required for pagination.
 * Based on bootstrap-vue pagination
 * https://github.com/bootstrap-vue/bootstrap-vue/blob/dev/src/components/pagination/pagination.js
 */

// Return the Bounding Client Rect of an element
// Returns `null` if not an element
/* istanbul ignore next: getBoundingClientRect() doesn't work in JSDOM */
export const getBCR = el => (isElement(el) ? el.getBoundingClientRect() : null)

// Get an style property value from an element
// Returns `null` if not found
export const getStyle = (el, prop) => (prop && isElement(el) ? el.style[prop] || null : null)

export const toType = value => typeof value

export const isFunction = value => toType(value) === 'function'

// Returns true if the parent element contains the child element
export const contains = (parent, child) =>
  parent && isFunction(parent.contains) ? parent.contains(child) : false


// Determine if an HTML element is visible - Faster than CSS check
export const isVisible = el => {
  if (!isElement(el) || !el.parentNode || !contains(document.body, el)) {
    // Note this can fail for shadow dom elements since they
    // are not a direct descendant of document.body
    return false
  }
  if (getStyle(el, 'display') === 'none') {
    // We do this check to help with vue-test-utils when using v-show
    /* istanbul ignore next */
    return false
  }
  // All browsers support getBoundingClientRect(), except JSDOM as it returns all 0's for values :(
  // So any tests that need isVisible will fail in JSDOM
  // Except when we override the getBCR prototype in some tests
  const bcr = getBCR(el)
  return !!(bcr && bcr.height > 0 && bcr.width > 0)
}

export const isUndefined = value => value === undefined
export const isNull = value => value === null
export const isUndefinedOrNull = value => isUndefined(value) || isNull(value)

// Determine if an element is an HTML element
export const isElement = el => !!(el && el.nodeType === Node.ELEMENT_NODE)

// Get the currently active HTML element
export const getActiveElement = (excludes = []) => {
  const { activeElement } = document
  return activeElement && !excludes.some(el => el === activeElement) ? activeElement : null
}

// Determine if an HTML element is the currently active element
export const isActiveElement = el => isElement(el) && el === getActiveElement()

// Attempt to focus an element, and return `true` if successful
export const attemptFocus = (el, options = {}) => {
  try {
    el.focus(options)
  } catch {}
  return isActiveElement(el)
}
