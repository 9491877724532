export enum LlmProviderEnum {
  OpenAI = "OpenAI", // ChatGPT
  Google = "Google", // Gemma
  Anthropic = "Anthropic", // Claude
  Microsoft = "Microsoft", // Phi
  Meta = "Meta", // Llama
  Mistral = "Mistral", // Mistral
  HuggingFace = "Hugging Face", // Custom models
  Alibaba = "Alibaba Cloud", // Qwen
}

export enum llmProvidersEmbeddedEnum {
  OpenAI = "OpenAI", // Current default
  Nomic = "Nomic", // New default
}
