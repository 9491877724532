<template>
  <div>
    <div class="form--wrapper settings">
      <form @submit.prevent="onFormSubmit">
        <div class="settings-title">
          <div class="title">{{ tabs[currentTab].title }}</div>
          <div class="settings-button settings-button-bots row" v-if="!!tabs[currentTab]?.save && hasSavePermission">
            <button class="btn btn--purple gradient" type="submit">
              <i class="icon icon-save"></i>
              Save settings
            </button>
          </div>
        </div>

        <div class="settings-nav">
          <AdminPannel :tabs="tabs" :bot-name="settingsBotName" :settings-tab="settingsTab" :channels="channels"
                       @change="onTabChanged"></AdminPannel>
        </div>

        <template v-if="hasLoaded">
          <GeneralSettings
            v-if="currentTab == TabPage.general"
            :settings="settings"
            :displayName="displayName"
            :botName="settingsBotName"
            :botAvatar="botAvatar"
            @update-avatar="updateAvatar"
            @update-display-name="updateDisplayName"
          />

          <Configuration
            v-if="currentTab == TabPage.configuration && isConfigurationLoaded"
            :botName="settingsBotName"
            :isTenantAdmin="isTenantAdmin"
            :language="settings.language"
            :sttProvider="sttProvider"
            :sttLanguageCode="settings.speechToTextLanguageCode"
            :ttsProvider="ttsProvider"
            :settings="settings"
            :engines="engines"
            :channels="channels"
            :systems="systems"
            :isRunningTask="isRunningTaskInConfiguration"
            :customEngineContracts="customEngineContracts"
            :tenantPlan="tenantPlan"
            @update="() => {updateConfiguration(); (isRunningTaskInConfiguration = false)}"
            @save="saveSettings"
            @startTaskRunnig="isRunningTaskInConfiguration = true"
          />

          <CustomWidgets
            v-if="currentTab == TabPage.widgets"
            :widgetLists="widgetLists"
            :customWidgets="customWidgets"
            :variables="variables"
            @get-widgets="getWidgets"
          />

          <Data
            v-if="currentTab == TabPage.nluPerformance"
            :isDeletionInProgress="isDeletionInProgress"
            :botName="settingsBotName"
            :settings="settings"
            :hasNluPerformance="hasNluPerformance"
            @export-bot="exportBot"
            @delete-bot="deleteBot"
            @run-nlp="
            onFormSubmit()
            runNlp()
          "
            @upload-nlp-dataset="uploadNlpDataset"
            @download-nlp-dataset="downloadNlpDataset"
            @download-nlp-results="downloadNlpResults"
            :isNlpTestRunning="isNlpTestRunning"
          />

          <HandoverSettings v-if="currentTab == TabPage.handover" :handoverSettings="handoverSettings"
                            :botName="settingsBotName" />

          <Features
            ref="features"
            :toggle-branding="toggleBranding"
            :is-tenant-admin="isTenantAdmin"
            :channels="channels"
            @on-subtab-change="getSavePermission"
            v-show="currentTab === TabPage.features" />
          <NluStatus v-if="currentTab === TabPage.nluStatus" :botName="settingsBotName"
                     :tenantPlan="tenantPlan"></NluStatus>
        </template>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import AdminPannel from './AdminPannel.vue'
import GeneralSettings from './tabs/GeneralSettings.vue'
import Configuration from './tabs/Configuration.vue'
import CustomWidgets from './tabs/CustomWidgets.vue'
import Data from './tabs/Data.vue'
import HandoverSettings from './tabs/HandoverSettings.vue'
import { Permissions } from '../../../../../../common/enums/tenant/user-permissions.enum'
import settingsManagementMixin, { TabPage } from './_mixins/settings-management.mixin'
import permissionsService from '../../../../services/tenants/permissions.service'
import NluStatus from './tabs/NluStatus.vue'
import tenantService from '../../../../services/tenant.service'
import { TenantPlan } from '../../../../../../common/interfaces/plans/tenant-plan.interface'
import { IBotSettings } from '../../../../../../common/interfaces/bot-settings.interface'
import Features from '@/components/admin/bots/settings/tabs/Features.vue'

@Component({
  components: {
    Features,
    AdminPannel,
    GeneralSettings,
    Configuration,
    CustomWidgets,
    Data,
    HandoverSettings,
    NluStatus
  }
})
export default class Settings extends Mixins(settingsManagementMixin) {
  @Prop({ default: '' }) readonly settingsBotName: string
  @Prop() settingsTab: string
  @Prop() readonly toggleBranding: boolean
  @Prop() readonly hasNluPerformance: boolean
  @Prop() readonly customEngineContracts: boolean

  private hasSavePermission = false
  private isRunningTaskInConfiguration = false

  TabPage = TabPage

  hasLoaded = false
  tenantPlan?: TenantPlan = null

  async beforeMount() {
    this.tenantPlan = await tenantService.getPlan()
    await this.initSettings(this.settingsBotName)
    await this.getData()
    await this.getSavePermission()
    this.hasLoaded = true
  }

  @Watch('currentTab')
  async getSavePermission() {
    switch (this.currentTab) {
      case TabPage.general:
        this.hasSavePermission = await permissionsService.hasPermission(this.settingsBotName, Permissions.ManageGeneralSettings)
        break
      case TabPage.widgets:
        this.hasSavePermission = await permissionsService.hasPermission(this.settingsBotName, Permissions.ManageDashboardWidgets)
        break
      case TabPage.nluPerformance:
        this.hasSavePermission = (
          await Promise.all([
            permissionsService.hasPermission(this.settingsBotName, Permissions.NlpPerformanceMeasuring),
            permissionsService.hasPermission(this.settingsBotName, Permissions.NlpPerformanceTesting)
          ])
        ).some((p) => p == true)
        break
      case TabPage.handover:
        this.hasSavePermission = await permissionsService.hasPermission(this.settingsBotName, Permissions.ManageHandoverSettings)
        break
      case TabPage.configuration:
        this.hasSavePermission = await permissionsService.hasPermission(this.settingsBotName, Permissions.ManageEngines)
        break
      case TabPage.features:
        this.hasSavePermission = (this.$refs.features as any)?.hasSufficientPermission()
        break
      default:
        return false
    }
  }

  //TODO: sure if this is the right way to save settings and if it's working
  async saveSettings(data: IBotSettings) {
    this.settings = { ...this.settings, ...data }
    await this.onFormSubmit()
  }
}
</script>
