import axios from 'axios'

export interface RecognizerLanguages {
  recognizers: string[];
  languages: { [recognizer: string]: string[] };
}

export class IntentsService {
  /**
   * @param {string} containerName - Bot name
   */
  private intentsUrl(containerName: string) {
    return `${process.env.VUE_APP_BACKEND_URL}/${containerName}/intents`
  }

  /**
   * Creates an intent in a given bot
   * @param {string} containerName - Bot name
   * @param {{name:string, isRedirect:boolean, description: string, template? : string }} intent
   */
  async create(containerName: string, intent: any) {
    const created = await axios.post(this.intentsUrl(containerName), intent)
    return created.data
  }

  /**
   * Deletes an intent from a given bot
   * @param {string} containerName - Bot name
   * @param {string} intentName
   */
  async delete(containerName: string, intentName: string) {
    await axios.delete(`${this.intentsUrl(containerName)}/${encodeURIComponent(intentName)}`)
  }

  async export(containerName: string, intentName: string) {
    return (await axios.get(`${this.intentsUrl(containerName)}/${encodeURIComponent(intentName)}/export`,
      { responseType: 'blob' })).data
  }

  validateImport(file) {
    const formData = new FormData()
    formData.append('intent-file', file, file.name)

    return axios
      .post(`${process.env.VUE_APP_BACKEND_URL}/intents/import/staging`, formData)
      .then(res => res.data)
  }

  /**
   * @description returns the new imported intent
   */
  async import(file: File, containerName: string, intentName: string) {
    const form = new FormData()
    form.append('intent-file', file, file.name)

    return (await axios.post(`${this.intentsUrl(containerName)}/${intentName}/import`, form)).data
  }

  /**
   * Creates an intent in a given bot
   * @param {string} containerName - Bot name
   * @param {{name:string, isRedirect:boolean, description: string, template? : string, newName : string }} extendedIntent
   */
  editName(containerName: string, extendedIntent: any) {
    return axios.put(`${this.intentsUrl(containerName)}/change-name`, extendedIntent)
  }

  async importFaq(file: File, containerName: string) {
    const formData = new FormData()
    formData.append('faq-file', file, file.name)

    const res = await axios.post(`${this.intentsUrl(containerName)}/import-faq`, formData)
    return res.data
  }

  async patchIntent(containerName: string, intentName: string, data: any) {
    await axios.patch(`${this.intentsUrl(containerName)}/${encodeURIComponent(intentName)}`, data)
  }

  async generateIntentUtterances(containerName: string, intentName: string): Promise<{ utterances: string[], patterns: string[] }> {
    return axios.put<any[]>(`${this.intentsUrl(containerName)}/${encodeURIComponent(intentName)}/utterances/generate`).then(e => e.data instanceof Array ? { utterances: e.data, patterns: [] } : e.data)
  }

  async generateUtterances(containerName: string, intentName: string, intentDescription: string, utterances: string[]) {
    return axios.post<string[]>(`${this.intentsUrl(containerName)}/utterances/generate`, {
      intentName,
      intentDescription,
      utterances
    }).then(e => e.data instanceof Array ? { utterances: e.data, patterns: [] } : e.data)
  }
}

export default new IntentsService()
