import axios from 'axios'
import { AnalyticsServiceBase } from './analytics.service.base'

export class AnalyticsService extends AnalyticsServiceBase {
  private readonly backendURL: string

  constructor(private botName: string) {
    super()
    this.backendURL = `${process.env.VUE_APP_BACKEND_URL}/${this.botName}`
  }

  getIntentNames() {
    return axios.get(`${this.backendURL}/intents`).then(r => r.data.map(i => i.name))
  }

  getUnrecognizedUtterances() {
    return axios.get(`${this.backendURL}/statistics/unrecognized/utterances`).then(r => r.data)
  }

  getServiceStatus() {
    return axios.get(`${process.env.VUE_APP_BACKEND_URL}/tenant/statistics/bots/${this.botName}/service/status`).then(r => r.data)
  }

  async getMonth(date: Date) {
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    return axios.get(`${process.env.VUE_APP_BACKEND_URL}/${this.botName}/statistics/${month}/${date.getFullYear()}`).then(r => r.data)
  }

  async arrangeModules() {
    return (await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${this.botName}/dashboard/calculated`)).data.customWidget
  }

  async deleteUntrainedExample(id: string) {
    axios.delete(`${process.env.VUE_APP_BACKEND_URL}/${this.botName}/statistics/unrecognized/${id}`)
  }

  async getBotLastStatisticPeriodValues() {
    return await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${this.botName}/statistics/last/values`).then(res => res.data)
  }

  async getConversationCustomStatistics(month: number, year: number, day?: number) {
    return await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${this.botName}/chats/custom-statistics?month=${month}&year=${year}${day ? '&day=' + day : ''}`).then(res => res.data)
  }
}
