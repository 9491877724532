<template lang="html">
  <div>
    <div class="row margin-top-25">
      <div class="col-4">
        <h2 class="rect__subtitle">
          INTERACTIONS
        </h2>
        <div class="rect__stats">
          <h1>
            {{ currMonth.interactions | addK }}
            <div :class="[currMonth.interactions > prevMonth.interactions ? 'up' : 'down']"></div>
          </h1>
          <p>{{selectedPeriod == 'monthly' ? 'Last month' : 'Yesterday'}}<br>{{ prevMonth.interactions | addK }}</p>
        </div>
      </div>
      <div class="col-4">
        <h2 class="rect__subtitle">
          INTENTS RECOGNIZED
        </h2>
        <div class="rect__stats border-box">
          <h1>
            {{ currMonth.questionsRecognized | round(currMonth.questionsAsked) }}%
            <div :class="[currMonth.questionsRecognized > prevMonth.questionsRecognized ? 'up' : 'down']"></div>
          </h1>
          <p>{{selectedPeriod == 'monthly' ? 'Last month' : 'Yesterday'}}<br>{{ prevMonth.questionsRecognized | round(prevMonth.questionsAsked) }}%</p>
        </div>
      </div>
      <div class="col-4">
        <h2 class="rect__subtitle">
          TOUCHLESS HANDLING
        </h2>
        <div class="rect__stats border-box">
          <h1>{{ currMonth.touchlessHandling | fix }}%</h1>
          <p>{{selectedPeriod == 'monthly' ? 'Last month' : 'Yesterday'}}<br>{{ prevMonth.touchlessHandling | fix(1) }}%</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { Component, Prop, Vue } from 'vue-property-decorator'
import { addK, fix, round } from '../filters/number.filters'

@Component({
  filters: {
    fix: fix,
    round: round,
    addK: addK
  }
})
export default class Interactions extends Vue {
  @Prop({ default: {} }) readonly currMonth: any;
  @Prop({ default: {} }) readonly prevMonth: any;
  @Prop({ default: 'monthly' }) readonly selectedPeriod: 'monthly' | 'daily';
}
</script>

<style lang="scss" scoped>
@import "analytics.style";

.light-p {
  font-size: 42px;
}

p {
  font-size: 14px
}

.rect__subtitle {
  color: white;
}
</style>
