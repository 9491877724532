<template>
  <div class="wrapper-inside general-settings active">
    <div class="form-section">
      <div class="row">
        <div class="col-12">
          <h4 >Custom Widgets</h4>
        </div>
      </div>
      <form @submit.prevent="addItem">
        <CustomWidgetInput :isFirstInput="true" :variables="variables" @submit="addItem"></CustomWidgetInput>

        <CustomWidgetInput
          :key="index"
          v-for="(item, index) of customWidgets"
          :variables="variables"
          :customWidget="item"
          @remove="removeItem(index)"
        ></CustomWidgetInput>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import CustomWidgetInput from './inputs/CustomWidgetInput.vue'

@Component({ components: { CustomWidgetInput } })
export default class CustomWidgets extends Vue {
  @Prop({ default: [] }) widgetLists: any[];
  @Prop({ default: [] }) customWidgets: any[];
  @Prop({ default: [] }) variables: any[];

  beforeCreate () {
    this.$emit('get-widgets')
  }

  addItem (item) {
    this.customWidgets.unshift(item)
  }

  removeItem (index) {
    this.customWidgets.splice(index, 1)
  }
}
</script>
