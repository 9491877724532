<template>
  <section class="content-intents">
    <div class="row sidenav">
      <div class="col-3">
        <BotSideNav
          type="Entity"
          :items="entityNames"
          :hasImportExport="hasImportExport && currentEntity && currentEntity.name"
          :selected-index.sync="selectedIndex"
          :selected-filter.sync="selectedFilter"
          :filterOptions="['All', 'Custom', 'Prebuilt']"
          :botName="botName"
          @add="validateAction(); $emit('add')"
          @delete="deleteEntity"
          @import="validateAction(); $emit('import')"
          @export="exportEntity"
        ></BotSideNav>
      </div>
      <div class="col-9">
        <div class="content-intents__main" v-if="entityNames && entityNames[0]">
          <div class="main-header">
            <Toggle
              id="toggle"
              label="Expert Mode"
              v-model="expertMode"
              @input="toggleExpertMode()"
            ></Toggle>
            <ComponentName
              :value="currentEntity.name"
              :name="currentEntity.name"
              :key="'name-'+currentEntity.name"
              :isEditable="hasSufficientPermission"
              @input="data => { validateAction(); $emit('edit-name', {...this.currentEntity,newName:data}) }"
            ></ComponentName>
            <Description
              v-model="currentEntity.description"
              :description="currentEntity.description"
              :isEditable="hasSufficientPermission"
              :key="'desc-'+currentEntity.description"
              :type="'entity'"
              @input="data => { validateAction(); $emit('description',{entityName:currentEntity.name,description:data}) }"
            ></Description>
            <span v-if="currentEntity.sublists" class="type-card">Closed List Entity</span>
            <span v-else-if="currentEntity.regexes" class="type-card">Regex Entity</span>
            <span v-else-if="currentEntity.examples" class="type-card">Simple Entity</span>
          </div>
          <div class="form--wrapper">
            <form>
              <div v-if="currentEntity.type === 'closedlists'">
                <Toggle
                  v-if="expertMode"
                  label="Resolve with canonical"
                  tooltip="When the entity is recognized return the canonical form instead of the actual utterance"
                  v-model="currentEntity.resolveWithCanonical"
                  @input="updateEntityResolution"
                ></Toggle>
                <div class="row">
                  <div class="col-md-4 entity-line-padding">
                    <h4>Canonical</h4>
                  </div>
                  <div class="col-md-8 entity-line-padding">
                    <h4>Utterances</h4>
                  </div>
                </div>
                <div class="row" v-if="hasSufficientPermission">
                  <div class="col-md-4 padding-right form-group">
                    <input
                      class="first-input form-control blue-card"
                      placeholder="Enter the canonical form"
                      v-model="newSublist.canonicalForm"
                      @keyup.enter="addSublist"
                    />
                  </div>
                  <div class="col-md-8 form-group">
                    <input
                      placeholder="Enter synonyms separated with a comma and space"
                      v-model="newSublist.list"
                      class="form-control blue-card first-input"
                      @keyup.enter="addSublist"
                    />
                    <div type="button" class="copybutton dark" @click="addSublist()">Add</div>
                  </div>
                </div>
                <div
                  class="row"
                  v-for="(sublist, index) in currentEntity.sublists"
                  :key="index"
                  :id="'sublist-' + index"
                  :class="{ 'fix-margin': index == 0 }"
                >
                  <div class="col-md-4 padding-right">
                    <div class="form-group">
                      <input
                        type="text"
                        :value="sublist.canonicalForm"
                        :disabled="!hasSufficientPermission"
                        class="form-control blue-card"
                        @keyup.enter="updateSublistCanonical(index, $event)"
                        @input="makeUpdate(index, 'cannonical', $event)"
                        @click="makeUpdate(index, 'cannonical', $event)"
                        @focusout="updateSublistCanonical(index, $event)"
                      />
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="form-group">
                      <input
                        type="text"
                        :value="listValuesToString(sublist.list)"
                        :disabled="!hasSufficientPermission"
                        class="form-control blue-card"
                        @keyup.enter="updateSublistList(index, $event)"
                        @input="makeUpdate(index, 'sublist', $event)"
                        @click="makeUpdate(index, 'sublist', $event)"
                        @focusout="updateSublistList(index, $event)"
                      />
                      <i
                        v-if="hasSufficientPermission"
                        class="icon-delete entity-delete"
                        @click="deleteSublist(index)"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else-if="currentEntity.type == 'entities'">
                <br/>
                <div class="row entity-line-padding">
                  <div class="col-md-12">
                    <h4>Examples</h4>
                  </div>
                </div>
                <div class="form-group" v-if="hasSufficientPermission">
                  <input
                    v-model="newExample"
                    :placeholder="'Type a new example for this entity'"
                    @keyup.enter="addExample()"
                  />
                  <div type="button" class="copybutton dark" @click="addExample()">Add</div>
                </div>
                <br>
                <div
                  class="row"
                  v-for="(_, index) in currentEntity.examples"
                  :key="index"
                  :id="'example-' + index"
                  :class="{ 'fix-margin': index == 0 }"
                >
                  <div class="col-md-12">
                    <div class="form-group">
                      <input
                        type="text"
                        :value="currentEntity.examples[index]"
                        class="form-control blue-card"
                        @keyup.enter="updateExample(index, $event)"
                        @input="makeUpdate(index, 'example', $event)"
                        @click="makeUpdate(index, 'example', $event)"
                        @focusout="updateExample(index, $event)"
                      />
                      <i class="icon-delete entity-delete" @click="deleteExample(index)"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else-if="currentEntity.type == 'prebuilts'">
                <br/>
                <div v-if="prebuildEntitiesExamples" class="row entity-line-padding">
                  <div class="col-md-12">
                    <h4>Examples</h4>
                  </div>
                </div>
                <div
                  class="row"
                  v-for="(value, index) in prebuildEntitiesExamples[currentEntity.name]"
                  :key="index"
                  :id="'example-' + index"
                >
                  <div class="col-md-12">
                    <div class="form-group">
                      <input
                        type="text"
                        :value="value"
                        class="form-control blue-card"
                        readonly
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div v-else-if="currentEntity.type === 'regexentities'">
                <div class="row entity-line-padding">
                  <div class="col-md-12">
                    <h4>Regexes</h4>
                  </div>
                </div>
                <div class="form-group" v-if="hasSufficientPermission">
                  <input
                    v-model="newRegex"
                    :placeholder="'Type a new regex for this entity'"
                    @keyup.enter="addRegex()"
                  />
                  <div type="button" class="copybutton dark" @click="addRegex()">Add</div>
                </div>
                <br>
                <div
                  class="row"
                  v-for="(_, index) in currentEntity.regexes"
                  :key="index + 'regex'"
                  :id="'regex-' + index"
                  :class="{ 'fix-margin': index == 0 }"
                >
                  <div class="col-md-12">
                    <div class="form-group">
                      <input
                        type="text"
                        :value="currentEntity.regexes[index]"
                        class="form-control blue-card"
                        @keyup.enter="updateRegex(index, $event)"
                        @input="makeUpdate(index, 'regex', $event)"
                        @click="makeUpdate(index, 'regex', $event)"
                        @focusout="updateRegex(index, $event)"
                      />
                      <i class="icon-delete entity-delete" @click="deleteRegex(index)"></i>
                    </div>
                  </div>
                </div>
                <div class="row entity-line-padding">
                  <div class="col-md-12">
                    <h4>Examples
                      <Tooltip placement="right" title="Needed for some integrations"></Tooltip>
                    </h4>
                  </div>
                </div>
                <div class="form-group" v-if="hasSufficientPermission">
                  <input
                    v-model="newExample"
                    :placeholder="'Type a new example for this entity'"
                    @keyup.enter="addExample()"
                  />
                  <div type="button" class="copybutton dark" @click="addExample()">Add</div>
                </div>
                <br>
                <div
                  class="row"
                  v-for="(_, index) in currentEntity.examples"
                  :key="index"
                  :id="'example-' + index"
                  :class="{ 'fix-margin': index == 0 }"
                >
                  <div class="col-md-12">
                    <div class="form-group">
                      <input
                        type="text"
                        :value="currentEntity.examples[index]"
                        class="form-control blue-card"
                        @keyup.enter="updateExample(index, $event)"
                        @input="makeUpdate(index, 'example', $event)"
                        @click="makeUpdate(index, 'example', $event)"
                        @focusout="updateExample(index, $event)"
                      />
                      <i class="icon-delete entity-delete" @click="deleteExample(index)"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="expertMode">
                <br>
                <div class="row entity-line-padding">
                  <div class="col-md-12">
                    <h4>Role</h4>
                  </div>
                </div>
                <div class="form-group margin-bot-10" v-if="hasSufficientPermission">
                  <input
                    v-model="newRole.name"
                    :placeholder="'Type a new role for this entity'"
                    @keyup.enter="addRole"
                  />
                  <div type="button" class="copybutton dark" @click="addRole">Add</div>
                </div>
                <br>
                <div
                  class="row"
                  v-for="(role, index) in currentEntity.roles"
                  :key="index"
                  :id="'role-' + index"
                  :class="{ 'fix-margin': index == 0 }"
                >
                  <div class="col-md-12">
                    <div class="form-group">
                      <input
                        type="text"
                        :value="role.name"
                        class="form-control blue-card"
                        :disabled="!hasSufficientPermission"
                        @keyup.enter="updateRole(index, $event)"
                      />
                      <i class="icon-delete entity-delete" @click="deleteRole(index)"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row entity-line-padding margin-top-20">
                <div class="col-md-12">
                  <h4>Supported by</h4>
                  <img
                    src="/assets/icons/icon-logo-ms.png"
                    class="support-logo"
                    alt="Logo Microsoft"
                  />
                  <img
                    src="/assets/img/logo-ibm.png"
                    v-if="currentEntity.sublists || currentEntity.regexes"
                    class="support-logo"
                    alt="Logo IBM"
                  />
                  <img
                    src="/assets/img/logo-google.png"
                    v-if="currentEntity.sublists || currentEntity.regexes"
                    class="support-logo"
                    alt="Logo Goole"
                  />
                  <img
                    src="/assets/img/logo-rasa.png"
                    v-if="currentEntity.sublists || currentEntity.regexes"
                    class="support-logo"
                    alt="Logo Rasa"
                  />
                  <img
                    src="/assets/img/logo-amazon.png"
                    v-if="currentEntity.sublists || amazonLexPrebuiltEntityMappingsKeys.includes(currentEntity.name)"
                    class="support-logo boxed"
                    alt="Logo Amazon"
                  />
                  <img
                    src="/assets/img/logo-wit.png"
                    v-if="currentEntity.sublists"
                    class="support-logo boxed margin-left"
                    alt="Logo Wit"
                  />
                  <img
                    src="/assets/img/logo-neuralspace.svg"
                    v-if="currentEntity.sublists || currentEntity.regexes || neuralspacePrebuiltEntityMappingsKeys.includes(currentEntity.name)"
                    class="support-logo boxed margin-left"
                    alt="Logo NeuralSpace"
                  />
                </div>
              </div>
            </form>
          </div>
          <BotSectionFooter type="Entity" :saveable="false" :isSyncActive="isSyncActive"></BotSectionFooter>
        </div>
        <div v-else class="content-intents__empty">
          <div class="content">
            <div class="space-ship"></div>
            <h3>Start building!</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import BotSectionFooter from '../footer/BotSectionFooter.vue'
import BotSideNav from '../sidenav/BotSideNav.vue'
import Description from '../../../helpers/Description.vue'
import ComponentName from '../../../helpers/ComponentName.vue'
import Toggle from '../../../helpers/Toggle.vue'
import permissionsService from '../../../../services/tenants/permissions.service'
import { Permissions } from '../../../../../../common/enums/tenant/user-permissions.enum'
import { prebuildEntitiesExamples } from '../../../../../../common/constants/prebuild-entities-examples.constant'
import { CustomError } from '../../../../../../common/errors'
import Tooltip from '@/components/helpers/Tooltip.vue'
import popupService from '@/services/popup.service'
import {
  neuralspacePrebuiltEntityMappings
} from '../../../../../../common/constants/neuralspace-prebuilt-entity-mappings'
import { amazonLexPrebuiltEntityMappings } from '../../../../../../common/constants/amazon-lex-prebuilt-entity-mappings'
import { luisPrebuiltEntityMappings } from '../../../../../../common/constants/luis-prebuilt-entity-mappings'

@Component({
  methods: {
    popupService () {
      return popupService
    }
  },
  components: {
    Tooltip,
    BotSectionFooter,
    BotSideNav,
    Description,
    ComponentName,
    Toggle
  }
})
export default class Entities extends Vue {
  @Prop({ type: Array, default: () => [] }) entities;
  @Prop({ type: Array, default: () => [] }) prebuiltEntities;
  @Prop({ type: Number, default: 0 }) active;
  @Prop({ type: String, default: () => '' }) botName;
  @Prop({ type: String, default: () => '' }) botId;
  @Prop() readonly hasImportExport: boolean;

  private hasSufficientPermission = true;

  private _selectedIndex = 0;
  private _selectedFilter = 'Custom';
  private currentEntityList = [];
  private currentEntity: Entity | RegexEntity = {
    name: '',
    type: '',
    sublists: [],
    roles: [],
    examples: []
  };

  private newExample = '';
  private newSublist: { canonicalForm?: string; list?: string } = {};
  private newRegex = '';
  private newRole: { name?: string } = {};
  private expertMode = localStorage.getItem('entitiesExpertMode') == 'true';

  private readonly neuralspacePrebuiltEntityMappingsKeys = Object.keys(neuralspacePrebuiltEntityMappings);
  private readonly amazonLexPrebuiltEntityMappingsKeys = Object.keys(amazonLexPrebuiltEntityMappings);
  private readonly luisPrebuiltEntityMappingsKeys = Object.keys(luisPrebuiltEntityMappings);

  created() {
    return this.$data._selectedIndex = this.active;
  }

  @Watch('entities', { deep: true })
  onEntitiesChange () {
    this.selectedFilter = 'Custom' // if entities are changed by parent -> re-eval the custom filter
    this.updateSelectedIndex()
  }

  get isSyncActive() {
    return this.$store.state.botData[this.botId]?.syncStatus.isSyncActive
  }

  get entityNames () {
    return this.currentEntityList.map(i => i.name)
  }

  get selectedIndex () {
    return this.$data._selectedIndex
  }

  set selectedIndex (newIndex) {
    this.$data._selectedIndex = newIndex
    this.currentEntity = this.currentEntityList[newIndex] || {}

    this.$router.push({
      name: 'BotTab',
      params: {
        botName: this.botName,
        tab: 'entities',
        element: this.entities[this.selectedIndex].name
      }
    }).catch(_ => {
    })

    this.$emit('updatedSelectedElement', {
      elementTab: 'entities',
      newName: this.entities[this.selectedIndex].name
    })
  }

  get selectedFilter () {
    return this.$data._selectedFilter
  }

  set selectedFilter (newFilter) {
    this.$data._selectedFilter = newFilter

    if (newFilter === 'Prebuilt') {
      this.currentEntityList = this.prebuiltEntities || []
    } else if (newFilter === 'Custom') {
      this.currentEntityList = this.entities || []
    } else {
      this.currentEntityList = this.entities.concat(this.prebuiltEntities)
    }

    this.currentEntity = this.currentEntityList.length
      ? this.currentEntityList[0]
      : {}
  }

  get prebuildEntitiesExamples () {
    return prebuildEntitiesExamples
  }

  mounted () {
    this.updateSelectedIndex()
    this.selectedFilter = 'Custom'
  }

  private updateSelectedIndex () {
    const index = this.entities.findIndex(
      i => i.name == this.$router.currentRoute.params.element
    )

    this.selectedIndex = index >= 0 ? index : this.active
  }

  async beforeMount () {
    this.hasSufficientPermission = await permissionsService.hasPermission(
      this.botName,
      Permissions.ManageEntities
    )
  }

  updateEntityResolution (value) {
    this.$emit('update-entity-resolution', {
      name: this.currentEntity.name,
      value
    })
  }

  addExample () {
    this.validateAction()
    if ((this.currentEntity as RegexEntity).regexes && !(this.currentEntity as RegexEntity).regexes.some(r => this.newExample.match(new RegExp(r)))) {
      throw new CustomError('The example must match at least one of the regexes')
    }
    this.$emit('add-example', {
      name: this.currentEntity.name,
      example: this.newExample
    })
    this.newExample = ''
  }

  private tempIndex = -1;
  private tempCanEvent = null;
  private tempSublEvent = null;
  private tempExamplEvent = null;
  private tempRegexEvent = null;
  private tempType = ''

  makeUpdate (index, type, event) {
    if (this.tempIndex != -1 && this.tempIndex != index) {
      this.validateAction()
      if (this.tempType == 'cannonical' || this.tempType == 'sublist') {
        this.updateSublistAndCanonical(this.tempIndex, this.tempSublEvent, this.tempCanEvent)
      } else if (this.tempType == 'example') {
        this.updateExample(this.tempIndex, this.tempExamplEvent)
      } else if (this.tempType == 'regex') {
        this.updateRegex(this.tempIndex, this.tempRegexEvent)
      }
    }

    if (type == 'cannonical') {
      this.tempCanEvent = event
    } else if (type == 'sublist') {
      this.tempSublEvent = event
    } else if (type == 'example') {
      this.tempExamplEvent = event
    } else if (type == 'regex') {
      this.tempRegexEvent = event
    }

    this.tempType = type
    this.tempIndex = index
  }

  updateExample (index, event) {
    this.validateAction()
    const example = event.target.value
    event.target.blur()
    if ((this.currentEntity as RegexEntity).regexes && !(this.currentEntity as RegexEntity).regexes.some(r => example.match(new RegExp(r)))) {
      throw new CustomError('The example must match at least one of the regexes')
    }
    this.$emit('update-example', {
      name: this.currentEntity.name,
      index,
      example
    })
  }

  deleteExample (index) {
    this.validateAction()
    popupService.deletePopupEvent(
      'example ' + this.currentEntity.examples[index],
      () => {
        this.$emit('delete-example', { name: this.currentEntity.name, index })
        this.updateExample(this.tempIndex, this.tempExamplEvent)
      }
    )
  }

  addSublist () {
    if (
      this.newSublist.canonicalForm.trim() == '' ||
      this.newSublist.list.trim() == ''
    ) return
    this.validateAction()
    this.$emit('add-sublist', {
      name: this.currentEntity.name,
      sublist: {
        canonicalForm: this.newSublist.canonicalForm,
        list: this.stringToListValues(this.newSublist.list)
      }
    })
    this.newSublist = {}
  }

  updateSublistCanonical (index, event) {
    this.validateAction()
    const canonicalForm = event.target.value
    const sublist = JSON.parse(
      JSON.stringify((this.currentEntity as Entity).sublists[index])
    )
    sublist.canonicalForm = canonicalForm
    event.target.blur()
    this.$emit('update-sublist', {
      name: this.currentEntity.name,
      index,
      sublist
    })
  }

  updateSublistAndCanonical (index, eventSubl, eventCan) {
    this.validateAction()
    const sublist = JSON.parse(
      JSON.stringify((this.currentEntity as Entity).sublists[index])
    )
    if (eventSubl) {
      const strList = eventSubl.target.value
      sublist.list = this.stringToListValues(strList)
      eventSubl.target.blur()
    }
    if (eventCan) {
      const canonicalForm = eventCan.target.value
      sublist.canonicalForm = canonicalForm
      eventCan.target.blur()
    }
    this.$emit('update-sublist', {
      name: this.currentEntity.name,
      index,
      sublist
    })
  }

  updateSublistList (index, event) {
    this.validateAction()
    const strList = event.target.value
    const sublist = JSON.parse(
      JSON.stringify((this.currentEntity as Entity).sublists[index])
    )
    sublist.list = this.stringToListValues(strList)
    event.target.blur()
    this.$emit('update-sublist', {
      name: this.currentEntity.name,
      index,
      sublist
    })
  }

  deleteSublist (index) {
    this.validateAction()
    popupService.deletePopupEvent(
      'sublist ' + (this.currentEntity as Entity).sublists[index].canonicalForm,
      () => {
        this.$emit('delete-sublist', { name: this.currentEntity.name, index })
        this.updateSublistAndCanonical(this.tempIndex, this.tempSublEvent, this.tempCanEvent)
      }
    )
  }

  addRegex () {
    this.validateAction()
    this.$emit('add-regex', {
      name: this.currentEntity.name,
      regex: this.newRegex
    })
  }

  updateRegex (index, event) {
    this.validateAction()
    const regex = event.target.value
    event.target.blur()
    this.$emit('update-regex', {
      name: this.currentEntity.name,
      index,
      regex
    })
  }

  deleteRegex (index) {
    this.validateAction()
    popupService.deletePopupEvent(
      'regex ' + this.currentEntity.name,
      () => {
        this.$emit('delete-regex', { name: this.currentEntity.name, index })
        this.updateRegex(this.tempIndex, this.tempRegexEvent)
      }
    )
  }

  addRole () {
    if (this.newRole.name.trim() == '') return
    this.validateAction()
    this.$emit('add-role', {
      name: this.currentEntity.name,
      role: this.newRole
    })
    this.newRole = {}
  }

  updateRole (index, event) {
    this.validateAction()
    const roleName = event.target.value
    event.target.blur()
    this.$emit('update-role', {
      name: this.currentEntity.name,
      index,
      role: { name: roleName }
    })
  }

  deleteRole (index) {
    this.validateAction()
    popupService.deletePopupEvent(
      'role ' + this.currentEntity.roles[index].name,
      () => this.$emit('delete-role', { name: this.currentEntity.name, index })
    )
  }

  deleteEntity (index) {
    this.validateAction()
    const entity = this.currentEntityList[index]
    popupService.deletePopupEvent(
      'entity ' + entity.name,
      () => this.$emit('delete', entity)
    )
  }

  listValuesToString (list: string[]) {
    return (list || []).join(', ')
  }

  stringToListValues (str: string) {
    return (str || '').split(', ')
  }

  exportEntity () {
    this.$emit('export', this.currentEntity)
  }

  toggleExpertMode () {
    localStorage.setItem('entitiesExpertMode', `${this.expertMode}`)
  }

  validateAction () {
    if (this.isSyncActive) throw new CustomError('You cannot add/update/delete entities when publishing!')
  }
}

interface Entity {
  name: string;
  type: string;
  sublists: any[];
  roles: any[];
  examples: any[];
}

interface RegexEntity {
  name: string;
  type: string;
  regexes: any[];
  examples: any[];
  roles: any[];
}

</script>
<style lang="scss">
@import "../../../../assets/scss/setup/_colors.scss";

#toggle {
  float: right;
  margin: 0 0 0 5px;

  p {
    font-size: 12px;
    margin: 0 0 0 10px;
    color: $grey;
  }
}

.fix-margin {
  margin-top: -17px;
}

</style>
