<template>
  <nav v-if="numberOfPages > 1">
    <ul class="pagination">
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <button class="page-link" @click="goToFirst">
          <slot name="first-text">First</slot>
        </button>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <button class="page-link" @click="goToPrev">
          <slot name="prev-text">Prev</slot>
        </button>
      </li>
      <li v-for="page in numberOfPages" :key="page" :class="['page-item', { active: currentPage === page }]">
        <button class="page-link" @click="onClick($event, page)">
          {{ page }}
        </button>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === numberOfPages }">
        <button class="page-link" @click="goToNext">
          <slot name="next-text">Next</slot>
        </button>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === numberOfPages }">
        <button class="page-link" @click="goToLast">
          <slot name="last-text">Last</slot>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { attemptFocus, isUndefinedOrNull, isVisible } from './pagination.helpers'

/**
 * Based on bootstrap-vue https://github.com/bootstrap-vue/bootstrap-vue/blob/dev/src/components/pagination/pagination.js
 */
@Component
export default class Pagination extends Vue {
  @Prop({ default: 20 }) readonly perPage!: number
  @Prop({ default: 0 }) readonly totalRows!: number
  @Prop() readonly value!: number // For v-model

  currentPage = 1

  // Computed property for the number of pages
  get numberOfPages() {
    const result = Math.ceil(this.totalRows / this.perPage)
    return result < 1 ? 1 : result
  }

  // Navigation methods
  goToFirst() {
    if (this.currentPage > 1) this.currentPage = 1
    this.$emit('change', this.currentPage)
  }

  goToPrev() {
    if (this.currentPage > 1) this.currentPage -= 1
    this.$emit('change', this.currentPage)
  }

  goToNext() {
    if (this.currentPage < this.numberOfPages) this.currentPage += 1
    this.$emit('change', this.currentPage)
  }

  goToLast() {
    if (this.currentPage < this.numberOfPages) this.currentPage = this.numberOfPages
    this.$emit('change', this.currentPage)
  }

  onClick(event: Event, pageNumber: number) {
    if (pageNumber === this.currentPage) return

    const { target } = event as any // Explicit type casting
    this.currentPage = pageNumber

    this.$emit('page-click', event, pageNumber)
    this.$emit('change', this.currentPage)

    this.$nextTick(() => {
      if (isVisible(target) && this.$el.contains(target)) {
        attemptFocus(target)
      }
    })
  }
}
</script>
