<template>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <div class="form-group--inside">
          <h4>General LLM settings</h4>
          <div class="description">Here, you can set the name and description of your LLM so you identify the model across all your projects and bots.</div>

          <div class="span" v-if="!this.newLLM">
            <h4 class="mt">Status</h4>
            <div class="form-group--inside">
              <div class="dot-wrapper">
                <div class="dot" :class="isComplete ? 'green' : 'red'" />
                <div class="description trained">LLM {{ isComplete ? '' : 'not' }} trained</div>
              </div>
            </div>
          </div>

          <h4 class="mt">Provider</h4>
          <div class="form-group--inside">
            <div class="description">{{ getProvider }}</div>
          </div>

          <h4 class="mt">Model</h4>
          <div class="form-group--inside">
            <Dropdown :items="models" :value="selectedModel" @input="setModel($event)" />
          </div>

          <h4 class="mt">Display name</h4>
          <Autocomplete :items="variables" id="botName" v-model="name" placeholder="Enter your LLM's name" :rows="1" spellcheck="false" />

          <h4 class="mt">Description</h4>
          <div class="form-group--inside">
            <Autocomplete :items="variables" name="desc" id="desc" v-model="description" :rows="1" placeholder="Enter a description for your LLM" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { LLM, LLMObject } from '../../../../../../common/types/llm-type'
import Autocomplete from '@/components/helpers/Autocomplete.vue'
import Dropdown from '@/components/helpers/Dropdown.vue'
import { LlmProviderEnum } from '@common/enums/bot/llm/llm-provider.enum'

import { OpenAIModelsEnabled, OpenAIEmbeddingsModelsEnabled } from '../../../../../../common/enums/open-ai-models'
import { AnthropicModelsEnabled } from '../../../../../../common/enums/anthropic/claude-models.enum'
import { AliModelsEnabled } from '../../../../../../common/enums/ali/ali.models.enum'
import { MistralModelsEnabled } from '../../../../../../common/enums/mistral/mistral-enum'
import { GoogleModelsEnabled } from '../../../../../../common/enums/google/google-modals.enum'
import { MetaModelsEnabled } from '../../../../../../common/enums/meta/meta-llama-enum'
import { MicrosoftModelsEnabled } from '../../../../../../common/enums/microsoft/phi-model-enum'
import { NomicEmbeddingsModelsEnabled } from '../../../../../../common/enums/nomic/embeddings-model.enum'

@Component({ components: { Autocomplete, Dropdown } })
export default class LLMdetails extends Vue {
  @Prop() newLLM: LLM
  @Prop() LLM: LLMObject
  @Prop() variables: string[]

  openAiModels = OpenAIModelsEnabled
  anthropicModels = AnthropicModelsEnabled
  ailModels = AliModelsEnabled
  mistralModels = MistralModelsEnabled
  googleModels = GoogleModelsEnabled
  metaModels = MetaModelsEnabled
  claudeModels = AnthropicModelsEnabled
  microsoftModels = MicrosoftModelsEnabled

  nomicModels = NomicEmbeddingsModelsEnabled
  openAiEmbeddingsModels = OpenAIEmbeddingsModelsEnabled

  selectedModel:
    | 'default'
    | typeof OpenAIModelsEnabled
    | typeof GoogleModelsEnabled
    | typeof AnthropicModelsEnabled
    | typeof AliModelsEnabled
    | typeof MistralModelsEnabled
    | typeof MetaModelsEnabled
    | typeof OpenAIModelsEnabled = 'default'
  selectedEmbeddingsModel: 'default' | typeof OpenAIEmbeddingsModelsEnabled | typeof NomicEmbeddingsModelsEnabled = 'default'

  mounted() {
    if (this.LLM) {
      if (this.selectedModel) this.selectedModel = this.LLM.model
      if (this.selectedEmbeddingsModel) this.selectedEmbeddingsModel = this.LLM.embeddingModel
    }
  }

  get models() {
    if (this.getProvider === LlmProviderEnum.OpenAI) {
      return ['default', ...this.openAiModels]
    }
    if (this.getProvider === LlmProviderEnum.Anthropic) {
      return ['default', ...this.anthropicModels]
    }
    if (this.getProvider === LlmProviderEnum.Alibaba) {
      return ['default', ...this.ailModels]
    }
    if (this.getProvider === LlmProviderEnum.Mistral) {
      return ['default', ...this.mistralModels]
    }
    if (this.getProvider === LlmProviderEnum.Google) {
      return ['default', ...this.googleModels]
    }
    if (this.getProvider === LlmProviderEnum.Meta) {
      return ['default', ...this.metaModels]
    }
    if (this.getProvider === LlmProviderEnum.Microsoft) {
      return ['default', ...this.microsoftModels]
    }
  }

  get isComplete() {
    return this.newLLM ? false : this.LLM.isComplete
  }

  get name() {
    return this.newLLM ? this.newLLM.llmDisplayName : this.LLM.llmDisplayName
  }

  set name(value: string) {
    if (this.newLLM) {
      this.newLLM.llmDisplayName = value
    } else {
      this.LLM.llmDisplayName = value
    }
  }

  get description() {
    return this.newLLM ? this.newLLM.description : this.LLM.description
  }

  set description(value: string) {
    if (this.newLLM) {
      this.newLLM.description = value
    } else {
      this.LLM.description = value
    }
  }

  get getProvider() {
    return this.newLLM ? this.newLLM.llmProvider : this.LLM.llmProvider
  }

  get getModel() {
    return this.newLLM ? this.newLLM.model : this.LLM.model
  }

  setModel(event) {
    if (!event) return
    this.selectedModel = event
    if (this.newLLM) {
      this.newLLM.model = event
    } else {
      this.LLM.model = event
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../assets/scss/variables';

.description {
  font-size: 14px;
  margin-bottom: 15px;
}

.mt {
  margin-top: 20px;
}

.dot-wrapper {
  display: flex;
  margin: 0px 0px -15px 0px;
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;

    &.red {
      background-color: $red;
    }

    &.green {
      background-color: $green;
    }
  }
  .description {
    margin-left: 10px;
    margin-top: -5px;
  }
}
</style>
