import { Component, Mixins } from 'vue-property-decorator'
import generalSettingsManagementMixin from './settings/general-settings-management.mixin'
import configurationManagementMixin from './settings/configuration-management.mixin'
import customWidgetsManagementMixin from './settings/custom-widgets-management.mixin'
import dataManagementMixin from './settings/data-management.mixin'
import handoverManagementMixin from './settings/handover-management.mixin'
import popupService from '@/services/popup.service'
import { Permissions } from '../../../../../../../common/enums/tenant/user-permissions.enum'

/** The Mixins ctor supports a maximum of 5 arguments */
@Component
class MixinsA extends Mixins(
  generalSettingsManagementMixin,
  configurationManagementMixin,
  customWidgetsManagementMixin,
  dataManagementMixin,
  handoverManagementMixin
) {
}

export interface Tab {
  title: string
  permissions?: Permissions[]
  save?: () => void
}

export enum TabPage {
  general = 'general',
  configuration = 'configuration',
  widgets = 'widgets',
  nluStatus = 'nluStatus',
  nluPerformance = 'nluPerformance',
  handover = 'handover',
  features = 'features'
}

@Component
export default class BotSettingsManagementMixin extends Mixins(
  MixinsA,
) {
  readonly tabs: {[key: string]: Tab} = {
    [TabPage.general]: { title: 'General Settings', save: () => this.updateSettings() },
    [TabPage.configuration]: { title: 'Configuration', save: () => this.updateConfiguration() },
    [TabPage.widgets]: { title: 'Custom Widgets', permissions: [Permissions.ManageDashboardWidgets], save: () => this.updateWidgets() },
    [TabPage.nluStatus]: { title: 'NLU Status' },
    [TabPage.nluPerformance]: { title: 'NLU Performance', save: () => this.updateSettings() },
    [TabPage.handover]: { title: 'Handover', save: () => this.updateHandover() },
    [TabPage.features]: { title: 'Features', save: () => (this.$refs.features as any)?.save() }
  }
  currentTab: string = this.$router.history.current.params.settingsTab ?? TabPage.general
  isConfigurationLoaded = false

  async initSettings(botName: string) {
    this.initGeneralSettings(botName)
    this.initConfiguration(botName)
    this.initWidgets(botName)
    this.initData(botName)
    this.initHandover(botName)
  }

  async getData() {
    await Promise.all([
      this.getConfiguration(),
      this.getGeneralSettings()
    ])
    this.prepareEngines()
    this.isConfigurationLoaded = true
  }

  prepareEngines() {
    this.engines.find(e => e.id == 'INTENT_RECOGNIZER').selectedProvider = this.settings.intentRecognizerProvider
    this.engines.find(e => e.id == 'ENTITY_EXTRACTOR').selectedProvider = this.settings.entityExtractorProvider
    this.engines.find(e => e.id == 'TTS').selectedProvider = this.settings.textToSpeechProvider
    this.engines.find(e => e.id == 'STT').selectedProvider = this.settings.speechToTextProvider
    this.engines.find(e => e.id == 'SENTIMENT_ANALYZER').selectedProvider = this.settings.sentimentAnalysisProvider
  }

  onTabChanged(newTab: string) {
    this.currentTab = newTab
  }

  get ttsProvider() {
    return this.engines.find(e => e.id == 'TTS').selectedProvider
  }

  get sttProvider() {
    return this.engines.find(e => e.id == 'STT').selectedProvider
  }

  async onFormSubmit() {
    try {
      if (!this.tabs[this.currentTab]?.save) return
      this.tabs[this.currentTab!].save!()
      popupService.showInfo('Saved!')
    } catch (err) {
      const message = err.response ? err.response.data.message : err.message
      popupService.showError('Error!', message)
    }
  }
}
