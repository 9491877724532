<template lang="html">
  <div class="rect rect--costs">
    <h1 style="text-align: left">Fab User Feedback</h1>
    <div class="content-wrapper">
      <div class="row">
        <div class="row">
          <div class="col-8">
            <h3>Average Rating</h3>
          </div>
          <div class="col-4 text-right">{{ averageRating || 'No ratings yet' }}</div>
          <div class="col-8">
            <h3>People left rating</h3>
          </div>
          <div class="col-4 text-right">{{ totalRatings }}</div>
        </div>
      </div>

      <div class="feedback-list">
        <div v-if="feedback.length">
          <div v-for="(item, index) in filteredFeedback" :key="index">
            <div class="feedback-item" v-if="item.comment">
              <div class="feedback-content">
                <div class="feedback-comment">{{ item.comment }}</div>
                <div class="feedback-actions">
                  <span class="rating-pill">{{ item.rating }} ★</span>
                  <button class="view-conversation-btn" @click="goToConversation(item.conversationId)">View Conversation</button>
                </div>
              </div>
              <div class="feedback-timestamp">{{ formatDate(item.timestamp) }}</div>
            </div>
          </div>
        </div>
        <div v-else class="no-feedback">No feedback available</div>
      </div>
      <Pagination
        v-if="feedback.length"
        @change="onPageChanged($event)"
        :total-rows="totalRows"
        :per-page="perPage"
        v-model="currentPage"
        aria-controls="feedback-table"
        class="page-controls"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { IChatMessage } from '@common/interfaces/chat-message.interface'
import Pagination from '@/components/helpers/Pagination.vue'

@Component({
  components: { Pagination }
})
export default class Rating extends Vue {
  @Prop({ type: Array, required: true }) readonly allMessages!: IChatMessage[]
  @Prop({ type: Boolean, required: true }) readonly loadingMessages!: boolean

  private perPage = 5
  currentPage = 1

  formatDate(timestamp: string) {
    return new Date(timestamp).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    })
  }

  get feedback() {
    const conversations = this.groupConversations()
    return conversations
      .filter((conv) => conv.rating && conv.comment)
      .map((conv) => ({
        rating: conv.rating,
        comment: conv.comment,
        timestamp: conv.messages[conv.messages.length - 1].timestamp,
        conversationId: conv.messages[0].conversationId
      }))
  }

  get filteredFeedback() {
    return this.feedback.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage)
  }

  get averageRating() {
    const ratings = this.feedback.map((f) => f.rating)
    if (!ratings.length) return null
    return (ratings.reduce((a, b) => a + b, 0) / ratings.length).toFixed(1)
  }

  get totalRatings() {
    return this.feedback.length
  }

  get totalRows() {
    return this.feedback.length
  }

  onPageChanged(page) {
    this.currentPage = page
  }

  private groupConversations() {
    const conversations = new Map()

    this.allMessages.forEach((message) => {
      if (!conversations.has(message.conversationId)) {
        conversations.set(message.conversationId, {
          messages: [],
          rating: null,
          comment: null
        })
      }

      const conversation = conversations.get(message.conversationId)
      conversation.messages.push(message)

      // Check for rating message from bot
      if (message.type === 'sent' && message.message === 'RATING') {
        // Look for rating in subsequent messages
        const laterMessages = this.allMessages.filter(
          (m) =>
            m.conversationId === message.conversationId && new Date(m.timestamp) > new Date(message.timestamp) && m.type === 'received' && m.message !== '_'
        )

        // First non-underscore message after RATING should be the rating
        const ratingMessage = laterMessages[0]
        if (ratingMessage && /^[1-5]$/.test(ratingMessage.message)) {
          conversation.rating = parseInt(ratingMessage.message)

          // Next message might be feedback
          if (laterMessages[1] && laterMessages[1].message !== '_') {
            conversation.comment = laterMessages[1].message
          }
        }
      }
    })

    return Array.from(conversations.values())
  }

  goToConversation(conversationId: string) {
    this.$router.push(`/bots/${this.$route.params.botName}/conversation/log/${conversationId}`)
  }
}
</script>

<style lang="scss" scoped>
@import '../analytics.style.scss';
@import '../../../assets/scss/setup/_colors.scss';

.rect {
  min-height: 500px;
  max-height: 500px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.feedback-comment {
  font-size: 14px;
  font-weight: 500;
  color: $default-dark;
}

.text-right {
  color: $default-dark;
  font-size: 12px;
  font-weight: 600;
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.feedback-list {
  flex: 1;
  overflow-y: auto;
  padding-right: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.page-controls {
  margin-top: 0px;
  padding-bottom: 0px;
}

.line {
  margin-bottom: 5px;
}

.feedback-item {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  border-top: 1px solid $line-color;
  margin-bottom: 5px;
  padding: 5px 0;

  .feedback-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .feedback-timestamp {
    font-size: 12px;
    color: $secondary-text;
    margin-top: 0px;
  }

  .rating-pill {
    background: transparent linear-gradient(283deg, $main 0%, $main-hover 100%) 0% 0% no-repeat padding-box;
    border: 1px solid $main;
    color: #fff;
    font-size: 12px;
    padding: 2px 5px;
    border-radius: 5px;
    margin-left: 8px;
    flex-shrink: 0;
  }
}

.rect {
  &--costs {
    h1 {
      color: $main;
      font-size: 14px;
      font-weight: 600;
      line-height: 15px;
      text-transform: uppercase;
    }
    h2,
    h3,
    p {
      margin: 0;
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      line-height: 23px;
      margin-bottom: 15px;
    }
    h3 {
      font-size: 12px;
      font-weight: 600;
      color: $secondary-text;
      line-height: 15px;
      text-transform: uppercase;
    }
    p {
      position: relative;
      top: 1px;
      font-size: 14px;
      font-weight: 400;
      color: #000;
    }
    &__border {
      display: inline-block;
      border-top: 1px solid #d8d8d8;
      width: 100%;
      margin: 8px 15px 12px;
    }
  }
}

.no-feedback {
  text-align: center;
  color: $secondary-text;
  padding: 20px;
}

.feedback-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.view-conversation-btn {
  background-color: transparent;
  border: 1px solid $main;
  color: $main;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: $main;
    color: white;
  }
}
</style>
