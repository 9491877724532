import { LlmProviderEnum } from "./llm-provider.enum"

export const providers = [
  {
    name: "OpenAI",
    enum: LlmProviderEnum.OpenAI,
    icon: "/assets/icons/openai-logo.svg",
    subtext: "Worlds best GPT models",
    description: "Renowned for its advanced AI models and comprehensive API support",
    selected: true,
    enabled: true,
  },
  {
    name: "Anthropic",
    enum: LlmProviderEnum.Anthropic,
    icon: "/assets/icons/anthropic-logo.svg",
    subtext: "Worlds best GPT models",
    description: "Antropic Claude LLM focuses on creating interpretable and steerable AI systems, ensuring high levels of safety and reliability",
    selected: false,
    enabled: true,
  },
  {
    name: "Google",
    enum: LlmProviderEnum.Google,
    icon: "/assets/icons/googleai-logo.svg",
    subtext: "Worlds best GPT models",
    description: "Offers powerful AI tools backed by Google's extensive resources and research in artificial intelligence",
    selected: false,
    enabled: true,
  },
  {
    name: "Microsoft",
    enum: LlmProviderEnum.Microsoft,
    icon: "/assets/img/logo-ms.svg",
    subtext: "Worlds best GPT models",
    description: "Microsoft's Phi model series represents cutting-edge research in smaller, more efficient language models while maintaining high performance",
    selected: false,
    enabled: true,
  },
  {
    name: "Meta",
    enum: LlmProviderEnum.Meta,
    icon: "/assets/icons/meta-logo.svg",
    subtext: "Worlds best GPT models",
    description: "Meta's LLaMA models are open-source large language models known for their efficiency and strong performance across various tasks",
    selected: false,
    enabled: true,
  },
  {
    name: "Mistral",
    enum: LlmProviderEnum.Mistral,
    icon: "/assets/icons/mistral-logo.png",
    subtext: "Worlds best GPT models",
    description: "Mistral AI specializes in developing efficient and powerful language models with state-of-the-art performance",
    selected: false,
    enabled: true,
  },
  {
    name: "Alibaba Cloud",
    enum: LlmProviderEnum.Alibaba,
    icon: "/assets/icons/ali.png",
    subtext: "Worlds best GPT models",
    description: "A popular choice for community-driven model development with a vast collection of pre-trained models and easy integration options.",
    selected: false,
    enabled: true,
  },
]
