import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import axios from 'axios'
import templateService from '../../../services/templates.service'
import popupService from '../../../services/popup.service'

import intentManagementMixin from './_mixins/intent-management.mixin'
import entityManagementMixin from './_mixins/entity-management.mixin'
import functionManagementMixin from './_mixins/function-management.mixin'
import variableManagementMixin from './_mixins/variable-management.mixin'
import exampleManagementMixin from './_mixins/example-management.mixin'
import validationMangementMixin from './_mixins/validation-management.mixin'
import llmManagementMixin from './_mixins/llm-management.mixin'

import { Permissions } from '@common/enums/tenant/user-permissions.enum'
import chatService from '../../../services/bots/chat.service'

import SynchronizationPage from './sync/SynchronizationPage.vue'
import Intents from './intents/Intents.vue'
import CreateIntent from './intents/CreateIntent.vue'
import ImportIntent from './intents/ImportIntent.vue'
import InternalTestChat from '../../client/chat/internal/InternalTestChat.vue'
import CreateFunction from './functions/CreateFunction.vue'
import ImportFunction from './functions/ImportFunction.vue'
import Entities from './entities/Entities.vue'
import ImportEntity from './entities/ImportEntity.vue'
import CreateEntity from './entities/CreateEntity.vue'
import Functions from './functions/Functions.vue'
import NavTabs from './nav-tabs/NavTabs.vue'
import Variables from './variables/Variables.vue'
import CreateObject from './variables/CreateObject.vue'
import Loading from '../../helpers/Loading.vue'
import Analytics from './analytics/Analytics.vue'
import FlowEditor from '../flow-editor/FlowEditor.vue'
import Settings from './settings/Settings.vue'
import WarningBanner from './warning/WarningBanner.vue'
import permissionsService from '../../../services/tenants/permissions.service'
import { capitalize, uppercase } from '../../filters/string.filters'
import preventBodyScrollMixin from '@/components/_mixins/prevent-body-scroll'
import { TenantPlan } from '@common/interfaces/plans/tenant-plan.interface'
import tenantService from '@/services/tenant.service'
import BatchImportIntents from './intents/BatchImportIntents.vue'
import botService from '@/services/bots/bot.service'
import { botRegionFilter } from './_filters/bot-region.filter'
import { StepStatus } from '@common/enums/bot/sync/step-status.enum'
import { BotSyncStatus, SyncState } from '@common/types/nlu-sync-status.type'
import { SyncStep } from '@common/enums/bot/sync/sync-state.enum'

@Component
class MixinsA extends Mixins(intentManagementMixin, entityManagementMixin, functionManagementMixin, variableManagementMixin, exampleManagementMixin) {}

@Component
class MixinsB extends Mixins(
  validationMangementMixin,
  preventBodyScrollMixin,
  llmManagementMixin
  // more mixins here
) {}

@Component({
  components: {
    SynchronizationPage,
    Intents,
    Entities,
    Functions,
    CreateIntent,
    CreateFunction,
    ImportFunction,
    InternalTestChat,
    CreateEntity,
    Variables,
    Settings,
    NavTabs,
    Loading,
    Analytics,
    FlowEditor,
    CreateObject,
    ImportIntent,
    WarningBanner,
    ImportEntity,
    BatchImportIntents
  },
  filters: {
    capitalize: capitalize,
    uppercase: uppercase,
    botRegionFilter
  }
})
export default class Bot extends Mixins(MixinsA, MixinsB) {
  $refs: {
    entityComponent: any
  }

  @Prop() tab: string
  @Prop() settingsTab: string
  templates = []
  currentTab = ''
  currentIntent: any = {}
  currentFunction = {}
  dialogManagerMode = 'hidden'
  editFlow = false
  hoverText = ''
  name = ''
  activeRedirect = 0
  loads = true
  bot: any = {}
  objectProperties = {}
  objectName = ''
  addDeletePermissions = {}
  showChat = false
  publishBannerDescription = 'You have unpublished intents and/or entities. Would you like to publish them?'
  isChatReady = true
  chatConfigurationDescription = 'Your bot is not responding (yet). You can still build and edit the bot.'
  tenantDisabledDescription =
    'Your bot is disabled due to invalid subscription or failed payment. Please contact us or check the problem manualy from the customer portal.'

  hasPermissionForChat = false

  botValidator: any

  hasEditFinished = false

  hasAddFinished = false
  isOpenedOnce = false

  permissions = this.permissionsEnum

  selectedIntentName = ''

  selectedEntityName = ''

  selectedFunctionName = ''

  selectedSubName = ''

  selectedLLMName = ''

  tabs: string[] = ['analytics', 'intents', 'entities']

  hasPermissionForFunctionsTab = false

  syncKey = 0
  isSyncOpen = false
  syncProviders: { [key: string]: SyncState } = {}
  syncId: string | null = null

  tenantPlan: TenantPlan = null

  isTenantDisabled: boolean = null

  async mounted() {
    this.botValidator = this.getBotValidator()
    this.name = this.$router.history.current.params.botName
    await this.loadBotData()
    this.isTenantDisabled = (await tenantService.isDisabled()).status
    this.currentTab = this.tab || this.currentTab || 'analytics'
    templateService.getTemplates().then((data) => (this.templates = data))
    this.hasPermissionForFunctionsTab = await this.getHasPermissionForFunctionsTab()
    this.hasPermissionForChat = await permissionsService.hasPermission(this.name, this.permissions.TestBot)
    await this.loadTabs()

    if (this.hasPermissionForChat) {
      await this.checkChatAvailability()
    }
  }

  async setupSyncState() {
    this.syncProviders = {}
    const syncStatus = this.bot.syncStatus as BotSyncStatus

    // Check if at least one bot is currently being trained
    // If the sync took longer than an hour, it's considered corrupted and can be re-initialised
    Object.entries(syncStatus.status)
      .filter(([_, value]) => value.isSyncInProgress && Date.now() - value.lastSyncTime < 1000 * 60 * 60)
      .forEach(([provider, _]) => this.$store.commit('addBotActivePublisher', { botId: this.bot.id, provider }))
    if (this.$store.state.botData[this.bot.id]?.syncStatus.isSyncActive) {
      this.syncId = syncStatus.lastSyncId
      this.isSyncOpen = true

      for (const [provider, value] of Object.entries(syncStatus.status)) {
        if (Object.keys(value.state).length) {
          this.syncProviders[provider] = value.state
        }
      }
    }
  }

  @Watch('currentTab')
  function(newVal) {
    // Reset selected intent when tab is changed
    if (newVal !== 'intents' && !newVal.startsWith('create-intent')) {
      this.currentIntent = {}
    }
    if (newVal !== 'intents' && !newVal.startsWith('create-intent')) {
      this.currentIntent = {}
    }
  }

  @Watch('showChat')
  isOpened() {
    this.toggleScroll(this.showChat)
    this.isOpenedOnce = true
  }

  get permissionsEnum() {
    return Permissions
  }

  async getHasPermissionForFunctionsTab() {
    return this.currentTab == 'subs'
      ? await permissionsService.hasPermission(this.name, this.permissions.ManageSubflows)
      : await permissionsService.hasPermission(this.name, this.permissions.ManageFunctions)
  }

  async checkChatAvailability() {
    this.isChatReady = await chatService.isChatAvailable(this.name)
  }

  async loadBot() {
    let response: any = {}
    try {
      response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${this.name}`)
    } catch (error) {
      if (error.code === 'permission_error') {
        this.$router.push('/bots')
        popupService.showWarning('You do not have permission')
      }
    }
    this.bot = response.data
    this.setupSyncState()
  }

  async loadTenantPlan() {
    this.tenantPlan = await tenantService.getPlan()
  }

  get getTabs() {
    return this.tabs
  }

  get hasImportExport() {
    return this.tenantPlan?.metadata?.hasImportExport
  }

  get toggleBranding() {
    return this.tenantPlan?.metadata?.toggleBranding
  }

  get hasNluPerformance() {
    return this.tenantPlan?.metadata?.hasNluPerformance
  }

  get customEngineContracts() {
    return this.tenantPlan?.metadata?.customEngineContracts
  }

  get isSyncActive() {
    return this.$store.state.botData[this.bot.id]?.syncStatus.isSyncActive
  }

  private async loadTabs() {
    if (await permissionsService.hasPermission(this.name, this.permissions.ManageFunctions)) {
      this.tabs.push('functions')
    }

    if (await permissionsService.hasPermission(this.name, this.permissions.ManageSubflows)) {
      this.tabs.push('subs')
    }

    this.tabs.push('variables', 'settings')
  }

  async loadBotData() {
    await Promise.all([this.loadBot(), this.loadTenantPlan(), this.setFunctions(), this.setVariables(), this.setLlms()])

    this.setIntents(this.bot)
    this.setEntities(this.bot)
    this.setExamples()

    if (this.currentTab !== 'analytics') this.loads = false
  }

  openEntity(entity) {
    this.currentTab = 'entities'
    this.$refs.entityComponent.showEntity(entity)
  }

  // Refresh changed examples
  async renameEntity(data) {
    await this.updateEntityName(data)
    await this.loadBot()
    this.setIntents(this.bot)
  }

  private simplifySnippets(snippets: any[]) {
    return snippets.reduce((map, obj) => {
      map[obj.code] = `Chat Bubble - ${obj.name}`
      return map
    }, {})
  }

  updateRoute(tab) {
    switch (tab) {
      case 'intents':
        if (this.selectedIntentName) {
          this.$router
            .push({
              name: 'BotTab',
              params: {
                botName: this.name,
                tab: tab,
                element: this.selectedIntentName
              }
            })
            .catch(() => {})
        } else {
          this.$router
            .push({
              name: 'Bot',
              params: { botName: this.name, tab: tab }
            })
            .catch(() => {})
        }
        break

      case 'entities':
        if (this.selectedEntityName) {
          this.$router
            .push({
              name: 'BotTab',
              params: {
                botName: this.name,
                tab: tab,
                element: this.selectedEntityName
              }
            })
            .catch(() => {})
        } else {
          this.$router
            .push({
              name: 'Bot',
              params: { botName: this.name, tab: tab }
            })
            .catch(() => {})
        }
        break

      case 'functions':
        if (this.selectedFunctionName) {
          this.$router
            .push({
              name: 'BotTab',
              params: {
                botName: this.name,
                tab: tab,
                element: this.selectedFunctionName
              }
            })
            .catch(() => {})
        } else {
          this.$router
            .push({
              name: 'Bot',
              params: { botName: this.name, tab: tab }
            })
            .catch(() => {})
        }
        break

      case 'subs':
        if (this.selectedSubName) {
          this.$router
            .push({
              name: 'BotTab',
              params: {
                botName: this.name,
                tab: tab,
                element: this.selectedSubName
              }
            })
            .catch(() => {})
        } else {
          this.$router
            .push({
              name: 'Bot',
              params: { botName: this.name, tab: tab }
            })
            .catch(() => {})
        }
        break

      default:
        this.$router
          .push({
            name: 'Bot',
            params: { botName: this.name, tab: tab }
          })
          .catch(() => {})
        break
    }
  }

  private updatedSelectedElement(data: { elementTab: string; newName; string }) {
    switch (data.elementTab) {
      case 'intents':
        this.selectedIntentName = data.newName
        break

      case 'entities':
        this.selectedEntityName = data.newName
        break

      case 'functions':
        this.selectedFunctionName = data.newName
        break

      case 'subs':
        this.selectedSubName = data.newName
        break

      default:
        break
    }
  }

  reset(ref, tab) {
    this.$refs[ref].value = ''
    this.currentTab = tab
  }

  publish() {
    this.syncKey++
    this.isSyncOpen = true
  }

  hideSnippetMigrationBanner() {
    this.bot.showSnippetMigrationBanner = false
    botService.hideSnippetMigrationBanner(this.name)
  }

  openDialogManagerForIntent() {
    if (this.currentIntent.type === 'faq') {
      popupService.showWarning('Warning!', "FAQ intents don't have a flow")
    } else {
      this.dialogManagerMode = 'intents'
    }
  }
}
