import {
    SpeechSynthesisOutputFormat
} from "../../backend_node_modules/microsoft-cognitiveservices-speech-sdk/SpeechSynthesisOutputFormat";

/**
 * Microsoft Text To Speech supported languages: https://learn.microsoft.com/en-us/azure/ai-services/speech-service/language-support?tabs=tts
 */
export enum MicrosoftTTSLanguage {
    'Arabic (Egypt)' = 'ar-EG',
    'Arabic (Saudi Arabia)' = 'ar-SA',
    Bulgarian = 'bg-BG',
    Catalan = 'ca-ES',
    Czech = 'cs-CZ',
    Danish = 'da-DK',
    'German (Austria)' = 'de-AT',
    'German (Switzerland)' = 'de-CH',
    'German (Germany)' = 'de-DE',
    Greek = 'el-GR',
    'English (Australia)' = 'en-AU',
    'English (Canada)' = 'en-CA',
    'English (UK)' = 'en-GB',
    'English (Ireland)' = 'en-IE',
    'English (India)' = 'en-IN',
    'English (US)' = 'en-US',
    'Spanish (Spain)' = 'es-ES',
    'Spanish (Mexico)' = 'es-MX',
    Finish = 'fi-FI',
    'French (Canada)' = 'fr-CA',
    'French (Switzerland)' = 'fr-CH',
    'French (France)' = 'fr-FR',
    'Hebrew (Israel)' = 'he-IL',
    'Hindi (India)' = 'hi-IN',
    Croatian = 'hr-HR',
    Hungarian = 'hu-HU',
    Indonesian = 'id-ID',
    Italian = 'it-IT',
    Japanese = 'ja-JP',
    Korean = 'ko-KR',
    Malay = 'ms-MY',
    Norwegian = 'nb-NO',
    Dutch = 'nl-NL',
    Polish = 'pl-PL',
    'Portuguese (Brazil)' = 'pt-BR',
    'Portuguese (Portugal)' = 'pt-PT',
    Romanian = 'ro-RO',
    Russian = 'ru-RU',
    Slovak = 'sk-SK',
    Slovenian = 'sl-SI',
    Swedish = 'sv-SE',
    'Tamil (India)' = 'ta-IN',
    'Telugu (India)' = 'te-IN',
    Thai = 'th-TH',
    'Turkish (Turkey)' = 'tr-TR',
    Vietnamese = 'vi-VN',
    'Chinese (Mandarin, Simplified)' = 'zh-CN',
    'Chinese (Cantonese, Traditional)' = 'zh-HK',
    'Chinese (Taiwanese Mandarin)' = 'zh-TW'
}

// MicrosoftResponseFormat for POST headers
// Docs: https://learn.microsoft.com/en-us/azure/ai-services/speech-service/rest-text-to-speech?tabs=streaming#audio-outputs
// Typescript uses camelcase instead of kebab-case: https://github.com/microsoft/cognitive-services-speech-sdk-js/blob/master/src/sdk/SpeechSynthesisOutputFormat.ts

export const MicrosoftResponseFormat:{[key in SpeechSynthesisOutputFormat]: string}= {
    // --number for Microsoft-- : --label for frontend--
    [SpeechSynthesisOutputFormat.Raw8Khz8BitMonoMULaw]:         'Raw 8Khz 8Bit Mono MULaw',
    [SpeechSynthesisOutputFormat.Riff16Khz16KbpsMonoSiren]:     'Riff 16Khz 16Kbps Mono Siren',
    [SpeechSynthesisOutputFormat.Audio16Khz16KbpsMonoSiren]:    'Audio 16Khz 16Kbps Mono Siren',
    [SpeechSynthesisOutputFormat.Audio16Khz32KBitRateMonoMp3]:  'Audio 16Khz 32KBitRate Mono Mp3',
    [SpeechSynthesisOutputFormat.Audio16Khz128KBitRateMonoMp3]: 'Audio 16Khz 128KBitRate Mono Mp3',
    [SpeechSynthesisOutputFormat.Audio16Khz64KBitRateMonoMp3]:  'Audio 16Khz 64KBitRate Mono Mp3',
    [SpeechSynthesisOutputFormat.Audio24Khz48KBitRateMonoMp3]:  'Audio 24Khz 48KBitRate Mono Mp3',
    [SpeechSynthesisOutputFormat.Audio24Khz96KBitRateMonoMp3]:  'Audio 24Khz 96KBitRate Mono Mp3',
    [SpeechSynthesisOutputFormat.Audio24Khz160KBitRateMonoMp3]: 'Audio 24Khz 160KBitRate Mono Mp3',
    [SpeechSynthesisOutputFormat.Raw16Khz16BitMonoTrueSilk]:    'Raw 16Khz 16Bit Mono True Silk',
    [SpeechSynthesisOutputFormat.Riff16Khz16BitMonoPcm]:        'Riff 16Kh z16Bit Mono Pcm',
    [SpeechSynthesisOutputFormat.Riff8Khz16BitMonoPcm]:         'Riff 8Khz 16Bit Mono Pcm',
    [SpeechSynthesisOutputFormat.Riff24Khz16BitMonoPcm]:        'Riff 24Khz 16Bit Mono Pcm',
    [SpeechSynthesisOutputFormat.Riff8Khz8BitMonoMULaw]:        'Riff 8Khz 8Bit Mono MULaw',
    [SpeechSynthesisOutputFormat.Raw16Khz16BitMonoPcm]:         'Raw 16Khz 16Bit Mono Pcm',
    [SpeechSynthesisOutputFormat.Raw24Khz16BitMonoPcm]:         'Raw 24Khz 16Bit Mono Pcm',
    [SpeechSynthesisOutputFormat.Raw8Khz16BitMonoPcm]:          'Raw 8Khz 16Bit Mono Pcm',
    [SpeechSynthesisOutputFormat.Ogg16Khz16BitMonoOpus]:        'Ogg 16Khz 16Bit Mono Opus',
    [SpeechSynthesisOutputFormat.Ogg24Khz16BitMonoOpus]:        'Ogg 24Khz 16Bit Mono Opus',
    [SpeechSynthesisOutputFormat.Raw48Khz16BitMonoPcm]:         'Raw 48Khz 16Bit Mono Pcm',
    [SpeechSynthesisOutputFormat.Riff48Khz16BitMonoPcm]:        'Riff 48Khz 16Bit Mono Pcm',
    [SpeechSynthesisOutputFormat.Audio48Khz96KBitRateMonoMp3]:  'Audio 48Khz 96KBitRate Mono Mp3',
    [SpeechSynthesisOutputFormat.Audio48Khz192KBitRateMonoMp3]: 'Audio 48Khz 192KBitRate Mono Mp3',
    [SpeechSynthesisOutputFormat.Ogg48Khz16BitMonoOpus]:        'Ogg 48Khz 16Bit Mono Opus',
    [SpeechSynthesisOutputFormat.Webm16Khz16BitMonoOpus]:       'Webm 16Khz 16Bit Mono Opus',
    [SpeechSynthesisOutputFormat.Webm24Khz16BitMonoOpus]:       'Webm 24Khz 16Bit Mono Opus',
    [SpeechSynthesisOutputFormat.Raw24Khz16BitMonoTrueSilk]:    'Raw 24Khz 16Bit Mono True Silk',
    [SpeechSynthesisOutputFormat.Raw8Khz8BitMonoALaw]:          'Raw 8Khz 8Bit Mono ALaw',
    [SpeechSynthesisOutputFormat.Riff8Khz8BitMonoALaw]:         'Riff 8Khz 8Bit Mono ALaw',
    [SpeechSynthesisOutputFormat.Webm24Khz16Bit24KbpsMonoOpus]:     'Webm 24Khz 16Bit 24Kbps Mono Opus',
    [SpeechSynthesisOutputFormat.Audio16Khz16Bit32KbpsMonoOpus]:    'Audio 16Khz 16Bit 32Kbps Mono Opus',
    [SpeechSynthesisOutputFormat.Audio24Khz16Bit48KbpsMonoOpus]:    'Audio 24Khz 16Bit 48Kbps Mono Opus',
    [SpeechSynthesisOutputFormat.Audio24Khz16Bit24KbpsMonoOpus]:    'Audio 24Khz 16Bit 24Kbps Mono Opus',
    [SpeechSynthesisOutputFormat.Raw22050Hz16BitMonoPcm]:       'Raw 22050Hz 16Bit Mono Pcm',
    [SpeechSynthesisOutputFormat.Riff22050Hz16BitMonoPcm]:      'Riff 22050Hz 16Bit Mono Pcm',
    [SpeechSynthesisOutputFormat.Raw44100Hz16BitMonoPcm]:       'Raw 44100Hz 16Bit Mono Pcm',
    [SpeechSynthesisOutputFormat.Riff44100Hz16BitMonoPcm]:      'Riff 44100Hz 16Bit Mono Pcm',
    [SpeechSynthesisOutputFormat.AmrWb16000Hz]:                 'Amr Wb 16000Hz',
    [SpeechSynthesisOutputFormat.G72216Khz64Kbps]:              'G72216 Khz 64Kbps',
}