<template>
  <div class="form-group--inside">
    <select
        :id="id"
        class="form-control"
        :value="value"
        @input="handleInput"
        @change="handleChange"
        :disabled="disabled"
    >
      <option value v-if="showDefault" :disabled="disableDefault">{{ defaultText }}</option>
      <option
          v-for="(el, index) in elements"
          :key="el[keyAttr || valueAttr || show || selectedAttr] || index"
          :value="el[valueAttr || keyAttr || show || selectedAttr] || el"
      >
        {{ el[show || keyAttr || valueAttr || selectedAttr] || el }}
      </option>
    </select>
    <i v-show="showToggle" class="dropdown-toggle"></i>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'Select' })
export default class SelectComponent extends Vue {
  @Prop({ required: false }) value: any;
  @Prop({ required: false }) id: string;
  @Prop({ required: true }) elements: Array<any> | Record<string, any>;
  @Prop({ required: false }) keyAttr: string;
  @Prop({ required: false }) valueAttr: string;
  @Prop({ required: false }) selectedAttr: string;
  @Prop({ required: false }) show: string;

  @Prop({ required: false, default: true }) showDefault: boolean;
  @Prop({ required: false, default: false }) disableDefault: boolean;
  @Prop({ required: false, default: 'Select Option' }) defaultText: string;
  @Prop({ required: false, default: false }) disabled: boolean;
  @Prop({ default: true }) showToggle: boolean;

  // Emit the input event and the update event
  handleInput(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.$emit('input', selectedValue);
    this.$emit('update', selectedValue); // Emit the custom update event
  }

  // Emit the change event explicitly
  handleChange(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.$emit('change', selectedValue); // Emit the change event
  }
}
</script>
