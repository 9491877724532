import { Component } from 'vue-property-decorator'
import Vue from 'vue'
import { LlmsService } from '@/services/bots/llms.services'
import { LLM, LLMObject } from '../../../../../../common/types/llm-type'
import usersService from '@/services/users.service'

@Component
export default class LlmManagementMixin extends Vue {
  llms = []
  activeLlm = 0
  name = ''
  currentTab = ''
  testResponse = ''
  llmService: LlmsService = undefined

  async setLlms() {
    // Don't call endpoint without LLM permission, as it will return 403
    const userInfo = await usersService.getUserInfo()
    if (!userInfo.canSeeLLMs) return

    try {
      this.llmService = new LlmsService()
      this.llms = await this.llmService.getAll()
    } catch (error) {
      this.llms = [] // Set to empty array on error
    }
  }

  async createLLM(llmData: LLM) {
    try {
      this.llmService = new LlmsService()
      const result = await this.llmService.create(llmData)

      for (const newDocument of llmData.documents) {
        if (newDocument.file) {
          await this.llmService.upload(result.llmId, newDocument.file)
        } else {
          await this.llmService.addDocument(result.llmId, newDocument)
        }
      }
      this.llms.push(result)
    } catch (error) {
      console.error('Failed to create LLM:', error)
    }
  }

  async uploadFile(llmId: string, file: File) {
    this.llmService = new LlmsService()
    return await this.llmService.upload(llmId, file)
  }

  async deleteLLM(llmData: LLMObject) {
    this.llmService = new LlmsService()
    await this.llmService.delete(llmData.llmId)
    this.llms = this.llms.filter((llm) => llm.llmId !== llmData.llmId)
    return true
  }

  async updateLLM(llmData: LLMObject) {
    this.llmService = new LlmsService()
    await this.llmService.update(llmData)
  }

  async trainLLM(llmData: LLMObject) {
    this.llmService = new LlmsService()
    return await this.llmService.train(llmData.llmId)
  }

  async runLLM(llmData: LLMObject, text: string) {
    this.llmService = new LlmsService()
    return await this.llmService.runLLM(llmData.llmId, text)
  }
}
