<template>
  <div id="app" class="neat-container">
    <div id="amazon-root"></div>
    <Popup />
    <MainNav />
    <div id="router-view">
      <transition name="fade">
        <router-view />
      </transition>
      <div class="clearfix"></div>
    </div>
    <div class="app-overlay"></div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from 'vue-property-decorator'
import Plausible from 'plausible-tracker'

import './assets/scss/components/containers.scss'
import MainNav from './components/layout/MainNav.vue'
import permissionsService from './services/tenants/permissions.service'
import Popup from './components/helpers/Popup.vue'
import authService from './services/auth.service'
import chatLoader from './components/client/chat/chat-loader'
import onLoggedInMixin from './components/_mixins/on-logged-in.mixin'

@Component({
  components: { MainNav, Popup }
})
export default class App extends Mixins(onLoggedInMixin) {
  private user = null
  private shortUserName = null

  @Watch('$route')
  async function(to, _) {
    if (!(await authService.isAuthenticated())) {
      if (!to.path.startsWith('/user-manual') && to.path !== '/login') this.$router.push('/login')
    }
    const url = window.location.href
    const bot = document.getElementById('bot')

    if (to.meta.defaultBackground && to.meta.defaultBackground === true) {
      document.querySelector('body').classList.add('default-background')
    } else {
      document.querySelector('body').classList.remove('default-background')
    }
    if (bot) {
      const urlArr = url.split('/')
      if (urlArr[urlArr.length - 1] === 'setup') {
        bot.style.display = 'none'
      } else {
        bot.style.display = 'block'
      }
    }
  }

  async beforeMount() {
    chatLoader.loadChatScript()
    this.user = authService.user
    if (!this.user) return
    await permissionsService.setup()

    this.onLoggedIn()

    if (this.$router.history.current.meta.defaultBackground && this.$router.history.current.meta.defaultBackground === true) {
      document.querySelector('body').classList.add('default-background')
    } else {
      document.querySelector('body').classList.remove('default-background')
    }
  }

  mounted() {
    // console.log('domain', window.location.hostname)
    const domain = window.location.hostname === 'platform.blits.ai' ? 'platform.blits.ai' : 'test.platform.blits.ai'
    const { enableAutoPageviews, enableAutoOutboundTracking } = Plausible({
      domain: domain,
      trackLocalhost: true
    })
    enableAutoPageviews()
    enableAutoOutboundTracking()
  }
}
</script>

<style lang="scss">
@import './assets/scss/main';

#adalIdTokenFrame {
  bottom: 0 !important;
}

.variable-suggestions {
  position: absolute;
  color: black;
  background-color: #ccc;
  right: -10px;
  width: 300px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 60;
}

.da-input {
  div {
    outline: none;
  }
}

.variable-suggestions div {
  padding: 5px;
  border-bottom: 1px solid #333;
  cursor: pointer;
}

.variable-suggestions div:last-child {
  border: 0;
}

body {
  background: $blue-background;

  &.image-background {
    background-image: url('/public/assets/img/bg-welcome-page.png');
    background-position: center;
    background-size: cover;
    background-color: transparent;
  }

  &.background-size-auto {
    background-size: auto !important;
  }

  .app-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    background: rgba($dark, 0.4);
  }

  #app {
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    min-width: 100%;
    min-height: 100%;
    margin: 0;

    .container {
      &--content {
        position: relative;
        margin: 30px 0;
      }

      &.wiki {
        width: calc(100% - 85px);
        margin-left: 85px;
        max-width: 2000px;
        padding-right: 0px;
        padding-left: 0px;
        overflow: hidden;
      }

      @media (min-width: 500px) {
        max-width: 500px;
        padding-right: 0px;
        padding-left: 25px;
        padding-bottom: 0;
      }
      @media (min-width: 768px) {
        max-width: 768px;
        padding-right: 25px;
        padding-left: 100px;
        padding-bottom: 10px;
      }
      @media (min-width: 992px) {
        max-width: 992px;
        padding-right: 25px;
        padding-left: 75px;
        padding-bottom: 5px;
      }
      @media (min-width: 1200px) {
        max-width: 1200px;
        padding-right: 50px;
        padding-left: 100px;
      }
      @media (min-width: 1400px) {
        max-width: 1200px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
      }
    }
    .clearfix {
      clear: both;
    }
    #router-view {
      width: 100%;
      // height: calc(100vh - 120px);
      // max-width: 1440px;
      max-width: 100%;
      margin: 0 auto;
      &.dialog-manager-active {
        position: relative;
        z-index: 15;
      }
      .container-fluid {
        max-width: 1440px;
        &.content--flow-editor {
          max-width: 100%;
          padding: 0;
        }
      }
      .fade-enter-active,
      .fade-leave-active {
        transition-property: opacity;
        transition-duration: 0.1s;
        // transition-easing: ease-in-out;
      }

      .fade-enter-active {
        transition-delay: 0.1s;
        transition-duration: 0.2s;
      }

      .fade-enter,
      .fade-leave-active {
        opacity: 0;
      }
    }
  }
}
</style>
