<template>
  <div class="form--wrapper__column">
    <div class="column-header">
      <h2>{{ name }}</h2>
      <div class="dropdown list-option--actions">
        <button
          type="button"
          class="dropdown-toggle"
          v-if="hasSufficientPermission"
          v-on:click.prevent.stop="dropdownClick('actions')"
        >
          <i class="plus"></i>
        </button>
        <div class="dropdown-menu" :class="{show:dropdownOpen==='actions'}">
          <div class="dropdown-menu__top">
            <p>{{ dropdownText }}</p>
            <input
              @input="searchFilter"
              type="text"
              class="form-control"
              :placeholder="getSearchPlaceholder()"
            />
          </div>
          <div v-for="(connection, index) in hiddenConnections" :key="index">
            <a
              href="#"
              class="dropdown-item"
              @click.prevent="addConnection(index)"
              :class="{
                disabled: allowedChannels
                  ? !allowedChannels.includes(connection.name.toLowerCase())
                  : false,
              }"
            >
              <div>
                <img :src="connection.icon" />
                {{ connection.name }}
              </div>
              <button type="button" @click.prevent="addConnection(index)">
                <i class="plus"></i>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="form--wrapper__list">
      <div
        class="list-option"
        v-for="(connection, index) in visibleConnections"
        :key="index"
        :class="{
          'list-option--selected': connection.connected && connection.enabled,
          disabled: allowedChannels
            ? !allowedChannels.includes(connection.name.toLowerCase())
            : false,
        }"
      >
        <div class="list-option--image">
          <img :src="connection.icon" />
        </div>
        <div class="list-option--information">
          <h4>
            {{ connection.name === "Email" ? "Office 365" : connection.name }}
          </h4>
          <p v-if="connection.connected && connection.enabled">
            Connected, Enabled
          </p>
          <p v-else-if="connection.connected && !connection.enabled">
            Connected, Disabled
          </p>
          <p
            v-else-if="
              !connection.connected &&
              connection.enabled &&
              !connection.requested
            "
          >
            Not connected, Enabled
          </p>
          <p v-else-if="!connection.connected && connection.requested">
            Connection requested
          </p>
          <p v-else>Not connected, Disabled</p>
        </div>
        <div
          v-if="!connection.requested && hasSufficientPermission"
          class="dropdown list-option--actions"
        >
          <a href="#" class="dropdown-toggle" v-on:click.prevent.stop="dropdownClick(connection.name)">
            <span></span>
            <span></span>
            <span></span>
          </a>
          <div class="dropdown-menu" :class="{show:dropdownOpen===connection.name}">
            <a
              v-if="areSettingsAvailable(connection.name)"
              href="#"
              class="dropdown-item"
              @click.prevent="$emit('open-settings', connection.name)"
            >
              <i class="icon icon-settings"></i>Settings
            </a>
            <a
              v-if="connection.enabled"
              href="#"
              class="dropdown-item"
              @click.prevent="disableConnection(index)"
            >
              <i class="icon icon-disable"></i>Disable
            </a>
            <a
              v-if="!connection.enabled"
              href="#"
              class="dropdown-item enable"
              @click.prevent="enableConnection(index)"
              :class="{
                disabled: maxChannelsReached && connection.name !== 'Test Chat',
              }"
            >
              <i class="icon icon-enable"></i>Enable
            </a>
            <a
              v-if="
                !connection.connected &&
                !connection.requested &&
                !areSettingsAvailable(connection.name)
              "
              href="#"
              class="dropdown-item"
              @click.prevent="$emit('request-connection', connection.name)"
            >
              <i class="icon icon-request-connect"></i>Request connect
            </a>
            <a
              v-if="
                (!connection.connected || !connection.enabled) &&
                !areSettingsAvailable(connection.name)
              "
              href="#"
              class="dropdown-item remove"
              @click.prevent="removeConnection(index)"
            >
              <i class="icon icon-delete"></i>Remove
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Permissions } from "../../../../../../../../common/enums/tenant/user-permissions.enum";
import permissionsService from "../../../../../../services/tenants/permissions.service";
import { BotConnectionEnum } from "../../../../../../../../common/enums/bot/bot-connection.enum";
import subscriptionMixin from "../../../../configuration/_mixins/subscription.mixin";
import { availableSettingsChannels } from "../../../../../../../../common/constants/availableSettingsChannels.constant";
import { availableSettingsSystems } from "../../../../../../../../common/constants/availableSettingsSystems.constant";
import { TenantPlan } from "../../../../../../../../common/interfaces/plans/tenant-plan.interface";
import DropdownMixin from "@/components/admin/bots/_mixins/dropdown.mixin";

@Component
export default class Connections extends Mixins(subscriptionMixin, DropdownMixin) {
  BotConnection = BotConnectionEnum;

  @Prop() isTenantAdmin: boolean;
  @Prop() name!: string;
  @Prop() dropdownText!: string;
  @Prop() botName!: string;
  @Prop() connections: any[];
  @Prop() readonly isPlanEnterprise: boolean;
  @Prop() tenantPlan: TenantPlan;
  @Prop() requestedConnectionName: string;

  connectionsFiltered: any[] = [];
  allowedChannels = [];
  maxChannels = undefined;
  maxChannelsReached = false;

  private hasSufficientPermission = false;

  @Watch("connections")
  onConnectionChange() {
    this.connectionsFiltered = this.connections || [];
  }

  @Watch("requestedConnectionName")
  requestConnection() {
    this.visibleConnections.find(
      (connection) => connection.name === this.requestedConnectionName
    ).requested = true;
  }

  async beforeMount() {
    this.hasSufficientPermission =
      this.name == "Connectors"
        ? await permissionsService.hasPermission(
            this.botName,
            Permissions.ManageConnections
          )
        : await permissionsService.hasPermission(
            this.botName,
            Permissions.ManageChannels
          );
    this.onConnectionChange();
    if (this.name == "Channels") {
      this.allowedChannels = this.getAllowedChannels(this.tenantPlan);
      this.maxChannels = this.getMaxChannels(this.tenantPlan);
      if (
        this.connections.filter((c) => c.enabled && c.name !== "Test Chat")
          .length >= this.maxChannels
      )
        this.maxChannelsReached = true;
    } else {
      this.allowedChannels = undefined;
    }
  }

  sort(arr: any[]) {
    return [...arr].sort((a, b) => (a.name as string).localeCompare(b.name));
  }

  get visibleConnections() {
    return this.sort(this.connections).filter(
      (ch) => (ch.visible || ch.requested) && ch.name !== "Test Chat"
    );
  }

  get hiddenConnections() {
    return this.sort(this.connectionsFiltered).filter(
      (ch) => !ch.visible && !ch.requested && ch.name !== "Test Chat"
    );
  }

  getSearchPlaceholder() {
    const connection =
      this.connections[Math.floor(Math.random() * this.connections.length)];
    return connection.name;
  }

  addConnection(index: number) {
    this.hiddenConnections[index].visible = true;
  }

  removeConnection(index: number) {
    this.visibleConnections[index].visible = false;
  }

  disableConnection(index: number) {
    this.visibleConnections[index].enabled = false;
    this.checkIfMaxChannelsAreReached();
  }

  enableConnection(index: number) {
    this.visibleConnections[index].enabled = true;
    this.checkIfMaxChannelsAreReached();
  }

  searchFilter(event) {
    if (event.target.value) {
      this.connectionsFiltered = this.connections.filter((x) =>
        x.name.toLowerCase().includes(event.target.value.toLowerCase())
      );
    } else {
      this.connectionsFiltered = this.connections;
    }
  }

  checkIfMaxChannelsAreReached() {
    this.maxChannelsReached =
      this.visibleConnections.filter((c) => c.enabled && c.name !== "Test Chat")
        .length >= this.maxChannels
        ? true
        : false;
  }

  areSettingsAvailable(connectionName: string) {
    if (this.name === "Connectors") {
      return (
        availableSettingsSystems.find((s) => s.name === connectionName)
          ?.availableSettings || false
      );
    } else {
      return (
        availableSettingsChannels.find((ch) => ch.name === connectionName)
          ?.availableSettings || false
      );
    }
  }
}
</script>

<style lang="scss">
@import "../../settings.style";

.list-option {
  &.disabled {
    pointer-events: none;
    opacity: 0.5 !important;
  }
}
</style>
