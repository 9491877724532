<template lang="html">
  <div class="rect rect--untrained-questions" v-if="data[0]">
    <h1>Unexpected Answer</h1>
    <div class="overflow-auto extend-height">
      <div class="untrained-question header">
        <div class="col-2">
          <p>Utterance</p>
        </div>
        <div class="col-2">
          <p>Triggered Intent</p>
        </div>
        <div class="col-2">
          <p>Score</p>
        </div>
        <div class="col-6"></div>
      </div>

      <div class="untrained-question" v-for="(utt, index) in paginatedItems" :key="utt.id">
        <div class="col-2">
          <p>{{ utt.utterance }}</p>
        </div>
        <div class="col-2">
          <p>{{ utt.triggeredIntent }}</p>
        </div>
        <div class="col-2">
          <p>{{ scoreToPercents(utt.score) }}</p>
        </div>
        <div class="col-6 flex-end" style="justify-content: flex-end; align-items: center">
          <button class="create-intent" @click="createIntent(utt)">Create new intent</button>
          <div class="dropdown" style="margin-left: 10px">
            <button
              class="btn btn-secondary dropdown-toggle add-to-intent"
              type="button"
              id="dropdownMenuButton"
              aria-haspopup="true"
              aria-expanded="false"
              v-on:click.prevent.stop="dropdownClick('dropdownMenuButton')"
            >
              Add to intent
            </button>
            <div class="dropdown-menu" :class="{ show: dropdownOpen === 'dropdownMenuButton' }" aria-labelledby="dropdownMenuButton">
              <div class="dropdown-item" v-for="(intent, i) in filteredIntentNames" :key="`${intent}-${i}`" @click="addUtterance(intent, utt, index)">
                {{ intent }}
              </div>
            </div>
          </div>
          <span @click="deleteData(utt.id, index)" class="icon-delete"></span>
        </div>
      </div>
      <Pagination @change="onPageChanged" :total-rows="rows()" :per-page="perPage" v-model="currentPage" />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import Pagination from '@/components/helpers/Pagination.vue'
import DropdownMixin from '@/components/admin/bots/_mixins/dropdown.mixin'

@Component({
  components: { Pagination }
})
export default class UnexpectedAnswer extends Mixins(Vue, DropdownMixin) {
  @Prop({ default: () => [] }) readonly data: UnexpectedAnswer[]
  @Prop({ default: () => [] }) readonly intentNames!: []
  @Prop({ default: () => '' }) readonly botName!: string

  perPage = 5
  currentPage = 1
  paginatedItems = []

  get filteredIntentNames() {
    return this.intentNames.filter((i) => i != 'None')
  }

  mounted() {
    this.exexute(this.data)
  }

  @Watch('data')
  exexute(newVal) {
    // watch it
    let pageIndex = this.currentPage - 1
    // Check if we deleted all utterances on the current page
    if (newVal.length % this.perPage == 0 && pageIndex != 0) pageIndex--
    this.paginate(this.perPage, pageIndex)
  }

  async deleteData(id, indexToRemove) {
    this.$emit('deleteElement', id, this.currentPage, this.perPage, indexToRemove)
  }

  scoreToPercents(score: number) {
    return Math.round(score * 100) + '%'
  }

  rows() {
    return this.data ? this.data.length : 0
  }

  paginate(pageSize, pageIndex) {
    const itemsToParse = this.data
    this.paginatedItems = itemsToParse.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
  }

  onPageChanged(page) {
    this.currentPage = page
    this.paginate(this.perPage, page - 1)
  }

  createIntent(utt) {
    this.$emit('createIntent', {
      utterance: utt,
      allUntrainedUtterances: this.paginatedItems.map((e) => ({
        id: e.id,
        message: e.utterance
      }))
    })
  }

  addUtterance(intent, utt, index) {
    this.$emit('addExample', {
      intentName: intent,
      exampleText: utt.message
    })

    this.deleteData(utt.id, index)
  }
}
</script>
<style lang="scss" scoped>
@import 'analytics.style';
@import '../../assets/scss/variables';
.icon-delete {
  margin: 0 10px 0;
  top: 0;
}
.rect {
  &--untrained-questions {
    min-height: 200px;
    h1 {
      color: $main;
      font-size: 14px;
      font-weight: 600;
      line-height: 15px;
      text-transform: uppercase;
    }
    h2,
    .untrained-question p {
      margin: 0;
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      line-height: 23px;
      margin-bottom: 15px;
    }
    .extend-height {
      padding-bottom: 100px;
    }
    .untrained-question {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 15px 0;
      border-bottom: 1px solid #d8d8d8;
      &:first-child {
        border-top: 1px solid #d8d8d8;
      }
      [class^='col-'] {
        padding: 0;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #000;
        line-height: 18px;
      }
      &.header {
        border-top: 0;
        p {
          font-weight: bold;
        }
      }
    }
  }
}

.extend-height {
  padding-bottom: 100px;
}

.create-intent,
.add-to-intent {
  font-size: 12px;
  font-weight: $bold;
  color: $default;
  line-height: 30px;
  border-radius: 6px;
  padding: 0 12px;
  border: none;
  height: 30px;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.create-intent {
  background: none;
  border: 1px solid $main;
  color: $main;
}
.add-to-intent {
  background: $main;
  &:not(:disabled):not(.disabled) {
    &:focus,
    &:active {
      background: $main;
      box-shadow: none;
    }
  }
  &:after {
    position: relative;
    top: 2px;
  }
}

p {
  margin-bottom: 0;
  margin-top: 5px;
}
.card-body {
  display: flex;
}
.dropdown-menu {
  overflow-y: auto;
  max-height: 400px;
}
</style>
