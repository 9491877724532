<template>
  <div class="form-group" :class="{'no-margin': noMargin}">
    <div class="form-toggle--inside">
      <label class="form-toggle" :class="{'disabled': disabled, 'no-margin': noMargin}" :for="id">
        <input
          :id="id"
          type="checkbox"
          :disabled="disabled"
          :checked="value"
          @input="$emit('input', !value); $emit('change', !value);"
        />
        <i></i>
        {{ label }}
        <span v-if="specialLabel" class="special-label">{{ specialLabel }}</span>
        <Tooltip v-if="tooltip" :title="tooltip"></Tooltip>
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import Tooltip from './Tooltip.vue'

@Component({
  components: { Tooltip }
})
export default class Toggle extends Vue {
  @Prop() label: string;
  @Prop() specialLabel: string;
  @Prop() value: boolean;
  @Prop() tooltip: string;
  @Prop() disabled: boolean;
  @Prop() noMargin: boolean;
  id = Math.random() * 1000000
}
</script>

<style lang="scss" scoped>
label {
  margin: 0 0 8px 5px;
}

.special-label {
  font-size: 10px;
  font-weight: bold;
  margin-left: 10px;
  color: #8743ff;
}

.disabled {
  color: gray;
  cursor: not-allowed !important;
}

.no-margin {
  margin: 0;
}
</style>
