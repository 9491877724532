<template>
  <section class="content-intents">
    <div class="row">
      <div class="col col-lg-9 col-md-12 col-sm-12 mt-4">
        <div class="rect rect--highlight mb-4">
          <div class="rect__title">
            <div class="d-flex justify-content-start">
              <Controls
                :currentDate="currentDate"
                :isEnabled="isEnabled"
                :lastValues="lastValues"
                :selectedPeriod="selectedPeriod"
                @setCurrentDate="setCurrentDate"
                :key="currentDate.getTime()"
              />
              <h1 v-if="isEnabled">
                {{ currentPeriod }}
              </h1>
              <h1 v-else>Test Chat is not enabled.</h1>
            </div>
            <div class="d-flex justify-content-end toggle-wrapper align-self-start">
              <button :class="`btn small duration-toggle ${selectedPeriod == 'daily' ? 'btn--purple gradient' : 'btn--white'}`" @click="togglePeriod('daily')">
                Daily
              </button>
              <button
                :class="`btn small duration-toggle ${selectedPeriod == 'monthly' ? 'btn--purple gradient' : 'btn--white'}`"
                @click="togglePeriod('monthly')"
              >
                Monthly
              </button>
            </div>
          </div>
          <Interactions
            :selectedPeriod="selectedPeriod"
            :currMonth="getInteractionsData(currentPeriodData)"
            :prevMonth="getInteractionsData(previousDateData)"
            class="module interactions"
          />
        </div>
        <UserMetrics
          :sentiment="currentPeriodData.sentiment"
          :satisfaction="currentPeriodData.satisfaction"
          :retention="currentPeriodData.retention"
          :month="currentPeriod"
          class="module user-metrics"
        />
      </div>
      <div class="col col-lg-3 col-md-12 col-sm-12 mt-4">
        <Users
          :users="currentPeriodData.users"
          :chats="currentPeriodData.chats"
          :seconds="currentPeriodData.seconds"
          :month="currentPeriod"
          class="module users flex-lg-column flex-md-row"
        />
      </div>
    </div>
    <div class="row">
      <div class="col col-lg-6 col-md-12 col-sm-12 mt-4">
        <Custom title="LLM Metrics" :examples="getLLMStats" decimals="5" class="module custom-widgets" />
      </div>
      <div class="col col-lg-6 col-md-12 col-sm-12 mt-4">
        <!-- <AnalyticsTable :config="llmEntriesConfig" :cells="llmInteractions"></AnalyticsTable> -->
        <Custom title="FAB Frontend Metrics" :examples="getFabCustomers" class="module custom-widgets" />
      </div>
    </div>

    <div class="row" v-if="isFAB">
      <div class="col col-lg-6 col-md-6 col-sm-12 mt-4">
        <AnalyticsTable :config="fabTopMerchants" :cells="topChatCustomStatisticValues('offersShown')"></AnalyticsTable>
      </div>
      <div class="col col-lg-6 col-md-6 col-sm-12 mt-4">
        <AnalyticsTable :config="fabTopOffersInteracted" :cells="topChatCustomStatisticValues('offerClicked')"></AnalyticsTable>
      </div>
    </div>
    <!-- only show this component for FAB tenant (83400) and testing tenant (800) -->
    <div class="row" v-if="isFAB">
      <div class="col col-lg-6 col-md-12 col-sm-12 mt-4">
        <FabRatingFeedback :allMessages="allMessages" :loadingMessages="loadingMessages" />
      </div>
    </div>

    <div v-if="hasNluPerformance" class="row">
      <div class="col col-lg-6 col-md-12 col-sm-12 mt-4">
        <AnalyticsTable v-if="questionsPerNlu" :config="intentRecognitionConfig" :cells="questionsPerNlu"></AnalyticsTable>
      </div>
      <div class="col col-lg-6 col-md-12 col-sm-12 mt-4">
        <AnalyticsTable v-if="nlpPerformance" :config="nlpPerformanceConfig" :cells="nlpPerformance"></AnalyticsTable>
      </div>
    </div>
    <div class="row fullwidth">
      <div class="col col-12 mt-4">
        <UntrainedQuestions
          :utterances="utterances"
          :intentNames="intentNames"
          :month="currentPeriod"
          :botName="botName"
          class="module untrained-questions"
          @addExample="addExample"
          @createIntent="createIntent"
          @delete-example="deleteExample"
        />
      </div>
    </div>
    <div class="row fullwidth" v-if="unexpectedAnswerData[0]">
      <div class="col col-12 mt-4">
        <UnexpectedAnswer
          :data="unexpectedAnswerData"
          :intentNames="intentNames"
          :botName="botName"
          @deleteElement="deleteUnexpectedAnswerObject"
          @addExample="addExample"
          @createIntent="createIntent"
        />
      </div>
    </div>
    <div class="row">
      <div class="col col-lg-6 col-md-12 col-sm-12 mb-4">
        <div class="module-left">
          <Channels :channels="currentPeriodData.channels" :month="currentPeriod" :snippets="snippets" class="module platform-status channels mt-4" />

          <Rating
            :all="currentPeriodData.presentedRatings"
            :answered="currentPeriodData.answeredRatingCards"
            :results="currentPeriodData.ratingResults"
            class="module rating channels mt-4"
          />

          <Custom :examples="customWidget" class="module custom-widgets mt-4" />

          <PlatformStatus v-if="serviceStatus" :data="serviceStatus" class="module platform-status mt-4" />

          <div class="col col-6"></div>
        </div>
      </div>
      <div class="col col-lg-6 col-md-12 col-sm-12 mb-4 mt-4">
        <AnalyticsTable :config="topIntentsConfig" :cells="topIntents"></AnalyticsTable>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import axios from 'axios'

import Interactions from '../../../analytics/Interactions.vue'
import PlatformStatus from '../../../analytics/PlatformStatus.vue'
import Rating from '../../../analytics/Rating.vue'
import UntrainedQuestions from '../../../analytics/UntrainedQuestions.vue'
import UserMetrics from '../../../analytics/UserMetrics.vue'
import Users from '../../../analytics/Users.vue'
import Channels from '../../../analytics/Channels.vue'
import Custom from '../../../analytics/Custom.vue'
import Controls from '../../../analytics/Controls.vue'
import UnexpectedAnswer from '../../../analytics/UnexpectedAnswer.vue'
import AnalyticsTable from '../../../analytics/AnalyticsTable.vue'
import FabRatingFeedback from '../../../analytics/customerCustom/FabRatingFeedback.vue'

import { AnalyticsService } from '../../../../services/bots/analytics.service'
import { fromMillisecondsToSeconds } from '../../../filters/number.filters'
import { months } from '../../../../../../common/constants/time.constant'
import statisticsService from '../../../../services/bots/statistics.service'
import { AnalyticsTableConfig } from '../../../../models/analytics-table.interface'
import { ExternalProvider } from '../../../../../../common/enums/external-provider.enum'
import { getKeyByValue } from '../../../../../../common/helpers/object.helper'
import { ConversationLogService } from '@/services/conversation-log.service'
import { IChatMessage } from '@common/interfaces/chat-message.interface'
import { LlmsService } from '@/services/bots/llms.services'
import { formatLocaleDate, formatLocaleTime } from '@common/helpers/date.helper'

@Component({
  components: {
    Interactions,
    PlatformStatus,
    Rating,
    UntrainedQuestions,
    UserMetrics,
    Users,
    Channels,
    Custom,
    Controls,
    UnexpectedAnswer,
    AnalyticsTable,
    FabRatingFeedback
  }
})
export default class Analytics extends Vue {
  private dataPerMonth: { [key: string]: any } = {}

  private currentDate = new Date()
  private selectedPeriod: 'daily' | 'monthly' = 'monthly'

  private utterances = []
  private unexpectedAnswerData = []
  private intentNames = []
  private serviceStatus = {}
  private isEnabled = true
  private customWidget = []
  private analyticsService: AnalyticsService
  private llmService: LlmsService = new LlmsService()
  private lastValues = {}
  private customChatValues: any = {}

  private logService: ConversationLogService
  private allLogMessages: IChatMessage[] = []
  loadingMessages = false

  private intentRecognitionConfig: AnalyticsTableConfig = {
    name: 'Intent detection performance',
    headerCells: ['engine', 'amount', 'recognized %'],
    columnSettings: [{ isStrong: true, sortType: 'string' }, { sortType: 'number' }, { isPercent: true, sortType: 'number' }]
  }

  private nlpPerformanceConfig: AnalyticsTableConfig = {
    name: 'NLU model performance',
    headerCells: ['engine', 'trained %', 'untrained %'],
    columnSettings: [
      { isStrong: true, sortType: 'string' },
      { sortType: 'number', isPercent: true },
      { sortType: 'number', isPercent: true }
    ]
  }

  private topIntentsConfig: AnalyticsTableConfig = {
    name: 'Top Intents',
    headerCells: ['intent', 'triggered', 'percentage of total'],
    columnSettings: [{ isStrong: true, sortType: 'string' }, { sortType: 'number' }, { isPercent: true }],
    paginationSettings: { perPage: 9 }
  }

  private fabTopMerchants: AnalyticsTableConfig = {
    name: 'Most shown offers',
    headerCells: ['Offer', 'Times Suggested'],
    columnSettings: [{ isStrong: true, sortType: 'string' }, { sortType: 'number' }],
    paginationSettings: { perPage: 5 }
  }

  private fabTopOffersInteracted: AnalyticsTableConfig = {
    name: 'Top Interacted Offers',
    headerCells: ['offer', 'interactions'],
    columnSettings: [{ isStrong: true, sortType: 'string' }, { sortType: 'number' }],
    paginationSettings: { perPage: 5 }
  }

  private llmEntriesConfig: AnalyticsTableConfig = {
    name: 'LLM Interactions',
    headerCells: ['Date', 'Time-to-first response', 'response time'],
    columnSettings: [{ sortType: 'string' }, { isStrong: true, sortType: 'number', suffix: 'ms' }, { isStrong: true, sortType: 'number', suffix: 'ms' }],
    paginationSettings: { perPage: 5 }
  }

  @Prop({ default: '' }) readonly botName: string
  @Prop({ default: '' }) readonly displayName: string
  @Prop({ default: '' }) readonly tenantId: string
  @Prop() readonly hasNluPerformance: boolean
  @Prop({ default: () => ({}) }) readonly snippets: any

  @Watch('botName')
  async onBotChange() {
    if (!this.botName) return
    this.$emit('loaded', false)

    try {
      this.analyticsService = new AnalyticsService(this.botName)
      const webWidget = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/bots/${this.botName}/web-widget`)
      this.isEnabled = webWidget.data.enabled

      if (this.isEnabled) await this.init()
      else this.$emit('loaded', true)
    } catch (err) {
      this.$emit('loaded', true)
    }

    //get all messages, but this doesn't have to be async
    this.logService = new ConversationLogService(this.botName)
    this.getAllMessages()
  }

  async getAllMessages() {
    this.loadingMessages = true
    try {
      this.allLogMessages = await this.logService.getChatHistory()

      // Sort by timestamp asc. Last conversation comes last.
      this.allLogMessages = this.allMessages.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
    } catch (error) {}
    this.loadingMessages = false
  }

  get isFAB() {
    const tenant = this.tenantId === '800' || this.tenantId === '83400'
    const fab = this.botName.toLowerCase().includes('fab') || this.displayName.toLowerCase().includes('fab')
    return fab && tenant
  }

  //Get all messages for the current month/year
  get allMessages() {
    if (!this.allLogMessages) return []

    const startDate = new Date(this.currentDate)
    const endDate = new Date(this.currentDate)

    if (this.selectedPeriod == 'monthly') {
      startDate.setDate(1)
      endDate.setMonth(endDate.getMonth() + 1)
      endDate.setDate(0)
    }

    startDate.setHours(0, 0, 0, 0)
    endDate.setHours(23, 59, 59, 999)

    return this.allLogMessages.filter((message) => {
      const messageDate = new Date(message.timestamp)
      return messageDate >= startDate && messageDate <= endDate
    })
  }

  get currentPeriod() {
    return this.selectedPeriod == 'monthly'
      ? `${months[this.currentDate.getMonth()]} ${this.currentDate.getFullYear()}`
      : `${this.currentDate.getDate()} ${months[this.currentDate.getMonth()]} ${this.currentDate.getFullYear()}`
  }

  get currentPeriodData() {
    const data = this.dataPerMonth[this.getDateKey(this.currentDate)] ?? {}
    if (this.selectedPeriod == 'monthly') {
      return data
    }
    return data?.dailyStatistics?.[formatLocaleDate(this.currentDate)] ?? {}
  }

  get previousDateData() {
    const previousDate = this.previousDate
    const data = this.dataPerMonth[this.getDateKey(previousDate)] ?? {}
    if (this.selectedPeriod == 'monthly') {
      return data
    }
    return data?.dailyStatistics?.[formatLocaleDate(previousDate)] ?? {}
  }

  get getFabCustomers() {
    if (!this.customChatValues?.items) {
      return [{ text: 'Loading...', formula: '...' }]
    }

    let microphoneUsed = 0
    let textUsed = 0
    let unrecognizedVoice = 0
    let fallbacks = 0
    let loads = 0

    for (const conversation of this.customChatValues.items) {
      if (conversation.inputUsed) {
        if (conversation.inputUsed.filter((e) => e === 'text').length > Math.floor(conversation.inputUsed.length / 2)) {
          textUsed++
        } else {
          microphoneUsed++
        }
      }
      if (conversation.voiceUnrecognized?.length) {
        unrecognizedVoice++
      }
      if (conversation.fallback?.length) {
        fallbacks += conversation.fallback.length
      }
      if (conversation.loaded?.length) {
        loads += conversation.loaded.length
      }
    }
    return [
      { text: 'Total interactions', formula: this.customChatValues?.conversations || 0 },
      { text: 'Customers (text)', formula: textUsed },
      { text: 'Customers (microphone)', formula: microphoneUsed },
      { text: 'Customers with unrecognized voices', formula: unrecognizedVoice },
      { text: 'Fallback Rate (image)', formula: ((fallbacks / (fallbacks + loads || 1)) * 100).toFixed(2), suffix: '%' },
      { text: 'Fallback Rate (response)', formula: '0', suffix: '%' }
    ]
  }

  get getLLMStats() {
    //todo we should be able to switch the index between llms
    let llmData = this.dataPerMonth[this.getDateKey(this.currentDate)]?.llmData[0] ?? {}
    if (this.selectedPeriod == 'daily' && llmData?.dailyMetrics) {
      llmData = llmData.dailyMetrics[formatLocaleDate(this.currentDate)]
    }
    return [
      {
        text: 'Time-to-first document retrieval (avg)',
        formula: fromMillisecondsToSeconds(llmData?.averageTtfr || 0),
        suffix: 's'
      },
      {
        text: 'Guardrail response time (avg)',
        formula: fromMillisecondsToSeconds(llmData?.averageGuardrailsTime || 0),
        suffix: 's'
      },
      {
        text: 'Model response time (avg)',
        formula: fromMillisecondsToSeconds(llmData?.averageLlmResponseTime || 0),
        suffix: 's'
      },
      {
        text: 'Total response time (avg)',
        formula: fromMillisecondsToSeconds(llmData?.averageResponseTime || 0),
        suffix: 's'
      },
      { text: 'Model interactions', formula: llmData?.interactions || 0 }
    ]
  }

  setMonthData(props: { date: Date; data: any; llmData: any }) {
    this.dataPerMonth = {
      ...this.dataPerMonth,
      [this.getDateKey(props.date)]: { ...props.data, llmData: props.llmData }
    }
  }

  getDateKey(date: Date) {
    return `${date.getMonth()}-${date.getFullYear()}`
  }

  togglePeriod(type: 'daily' | 'monthly') {
    this.selectedPeriod = type
  }

  mounted() {
    return this.onBotChange()
  }

  async init() {
    await this.loadPeriodData()
    await Promise.all([
      this.loadIntentNames(),
      this.loadUnrecognizedUtterances(),
      this.loadUnexpectedAnswers(),
      this.loadCustomWidget(),
      this.loadServiceStatus(),
      this.loadLastValues(),
      this.loadConversationStatistics()
    ])
  }

  async loadIntentNames() {
    this.intentNames = (await this.analyticsService.getIntentNames()).filter((name) => name !== 'None')
  }

  async loadUnrecognizedUtterances() {
    this.utterances = await this.analyticsService.getUnrecognizedUtterances()
  }

  async loadUnexpectedAnswers() {
    this.unexpectedAnswerData = await statisticsService.getUnexpectedAnswerData(this.botName)
  }

  async loadPeriodData() {
    await this.setCurrentDate(new Date())
  }

  async loadCustomWidget() {
    this.customWidget = await this.analyticsService.arrangeModules()
  }

  async loadServiceStatus() {
    this.serviceStatus = await this.analyticsService.getServiceStatus()
  }

  async loadLastValues() {
    this.lastValues = await this.analyticsService.getBotLastStatisticPeriodValues()
  }

  @Watch('currentDate')
  @Watch('selectedPeriod')
  async loadConversationStatistics() {
    this.customChatValues = await this.analyticsService.getConversationCustomStatistics(
      this.currentDate.getMonth() + 1,
      this.currentDate.getFullYear(),
      this.selectedPeriod === 'daily' ? this.currentDate.getDate() : undefined
    )
  }

  get topIntents() {
    const intents = this.currentPeriodData.popularIntents || {}
    if (!Object.keys(intents).length) return []
    const count = intents.reduce((result, curr) => result + curr.count, 0)
    return intents.map((i) => [i.name, i.count, (i.count / count) * 100])
  }

  topChatCustomStatisticValues(key: string) {
    if (!this.customChatValues?.items) {
      return [{ text: 'Loading...', formula: '...' }]
    }
    const allOffers = this.customChatValues.items.flatMap((e) => e[key] || [])
    const counts = allOffers.reduce((acc, entry) => {
      acc[entry] = (acc[entry] || 0) + 1
      return acc
    }, {})
    // Convert counts to an array of entries, sort by value, and then convert back to an object
    const sortedCounts = Object.entries(counts)
      .sort((a: any, b: any) => b[1] - a[1])
      .slice(0, 5)
    return sortedCounts
  }

  get llmInteractions() {
    let entries = [...(this.currentPeriodData.llmData?.[0]?.entries || [])]
    if (!entries.length) return []
    if (this.selectedPeriod == 'daily') {
      entries = entries.filter((value) => formatLocaleDate(new Date(value.date)) == formatLocaleDate(this.currentDate))
    }
    return entries.map((i) => [`${formatLocaleDate(new Date(i.date))} ${formatLocaleTime(new Date(i.date))}`, i.properties.ttfr, i.properties.responseTime])
  }

  get questionsPerNlu() {
    const perNlu = this.currentPeriodData.questionsPerNlu
    if (!perNlu) return []
    this.intentRecognitionConfig.columnSettings[0].specialLabelRules = [
      {
        label: 'Primary',
        textToFind: getKeyByValue(ExternalProvider, this.currentPeriodData.intentRecognizerProvider)
      }
    ]
    return Object.keys(perNlu).map((provider) => [
      getKeyByValue(ExternalProvider, provider),
      perNlu[provider].asked,
      (perNlu[provider].recognized / perNlu[provider].asked || 0) * 100
    ])
  }

  get nlpPerformance() {
    const trained = this.currentPeriodData.trainedPerformance
    const untrained = this.currentPeriodData.untrainedPerformance

    if (!trained || !untrained) return null
    this.nlpPerformanceConfig.columnSettings[0].specialLabelRules = [
      {
        label: 'Primary',
        textToFind: getKeyByValue(ExternalProvider, this.currentPeriodData.intentRecognizerProvider)
      }
    ]
    return Object.keys(trained).map((provider) => [getKeyByValue(ExternalProvider, provider), trained[provider], untrained[provider]])
  }

  async deleteUnexpectedAnswerObject(data, currentPage: number, perPage: number, indexToRemove: number) {
    await statisticsService.deleteUnexpectedAnswerObject(this.botName, data)
    this.unexpectedAnswerData.splice((currentPage - 1) * perPage + indexToRemove, 1)
  }

  async setCurrentDate(date: Date) {
    this.currentDate = date
    if (!this.dataPerMonth[this.getDateKey(this.currentDate)]) {
      this.setMonthData({
        date,
        data: await this.analyticsService.getMonth(this.currentDate),
        llmData: await this.llmService.getMetrics(this.botName, this.currentDate)
      })
    }
    await this.loadPreviousDateData()
  }

  addExample(data) {
    this.$emit('addExample', data)
  }

  deleteExample(id) {
    this.analyticsService.deleteUntrainedExample(id)
  }

  createIntent(data) {
    this.$emit('createIntentFromUntrained', data)
  }

  get previousDate() {
    const previousDate = new Date(this.currentDate)
    if (this.selectedPeriod == 'daily') {
      previousDate.setDate(previousDate.getDate() - 1)
    } else {
      previousDate.setMonth(previousDate.getMonth() - 1)
    }
    return previousDate
  }

  getInteractionsData(data: any) {
    return {
      interactions: data.interactions,
      questionsAsked: data.questionsAsked,
      questionsRecognized: data.questionsRecognized,
      touchlessHandling: data.touchlessHandling
    }
  }

  async loadPreviousDateData() {
    const previousDate = this.previousDate
    if (!this.dataPerMonth[this.getDateKey(previousDate)]) {
      this.setMonthData({
        date: previousDate,
        data: await this.analyticsService.getMonth(previousDate),
        llmData: await this.llmService.getMetrics(this.botName, previousDate)
      })
    }
    this.$emit('loaded', true)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../../assets/scss/variables';

.content-intents {
  // padding: 30px;
  background-color: $blue-background;
  box-shadow: none;
}

.toggle-wrapper {
  :first-child {
    border-radius: 6px 0 0 6px;
  }

  :last-child {
    border-radius: 0 6px 6px 0;
  }

  .duration-toggle {
    margin-left: 0;
    border: none;
    background-color: $main;
    color: white;
    transition: all 0.3s ease;

    &.btn--white {
      color: $main;
      background-color: #ffffff;
    }

  }
}

.module-left {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.rect {
  &--highlight {
    color: white;
    width: 100%;
    height: auto;
    padding: 30px 30px 50px;
    background-color: $default-dark;
    border-radius: 10px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .container__date-wrapper + h1 {
      margin-left: 20px;
    }

    h1 {
      font-size: 28px;
      font-weight: 700;
      color: white;
      line-height: 28px;
      margin: 0;

      span {
        font-size: 14px;
      }
    }
  }
}
.custom-widgets {
  height: 100%;
}
</style>
