<template>
  <section class="content">
    <div class="form--wrapper">
      <form v-if="!item" @submit.prevent="$emit('loadItem')">
        <div class="da-input-group">
          <div class="form-group">
            <p><strong>file:</strong> {{ file.name }}</p>
            <p><strong>size:</strong> {{ Math.ceil(file.size / 1024) }} KB</p>
          </div>
        </div>

        <div class="bottom-right-fix">
          <button class="btn btn--purple gradient" type="submit">
            Load {{ mode }} data
            <i class="icon icon-arrow-right"></i>
          </button>
        </div>
      </form>

      <!-- TODO someone REFACTOR - remove these forms from the component -->
      <form v-if="!!item" @submit.prevent="importItem()">
        <Toggle
          v-if="mode == 'function' && item.type != 'flow' && !item.isGlobal && hasPermissionForGlobalToggle"
          :label="'Make the function global'"
          class="toogle toogle-div"
          v-model="isFunctionGlobal"
        ></Toggle>
        <div class="da-input-group">
          <div class="form-group">
            <div class="form-group--inside">
              <label for="name">Name of your {{ mode }}</label>
              <input type="text" id="name" :value="name" @change="$emit('update:name', $event.target.value)" />
            </div>
          </div>

          <div class="form-group" v-if="mode === 'bot'">
            <Toggle label="Change bot region" :tooltip="botRegionTooltip" v-model="showBotRegionSelector"></Toggle>

            <div class="form-group" v-if="showBotRegionSelector">
              <div class="form-group--inside">
                <label for="botTemplate">Bot Region</label>
                <Select
                  :elements="regions"
                  :key="region"
                  :value="region"
                  @input="(input) => $emit('update:region', input)"
                  keyAttr="0"
                  valueAttr="1"
                  class="white-select x"
                  :showDefault="false"
                ></Select>
              </div>
            </div>

            <Toggle label="Blits Automate" :tooltip="nluAutomateTooltip" :value="nluAutomate" @input="(input) => $emit('update:nluAutomate', input)"></Toggle>

            <div v-show="!nluAutomate" class="form-group--inside">
              <label for="entityRecognizer">Initial Intent &amp; Entity Recognizer</label>
              <Select
                :elements="recognizers"
                :key="recognizer"
                id="recognizer"
                keyAttr="1"
                valueAttr="0"
                :value="recognizer"
                @input="(input) => $emit('update:recognizer', input)"
                class="white-select x"
                :showDefault="false"
              ></Select>
            </div>

            <div class="form-group--inside language-dropdown">
              <label for="language">Language</label>
              <Select
                :elements="languages"
                :key="language"
                id="language"
                keyAttr="0"
                valueAttr="1"
                :value="language"
                @input="(input) => $emit('update:language', input)"
                class="white-select x"
                :showDefault="false"
              ></Select>
            </div>
          </div>

          <div v-if="mode === 'bot'">
            <p>
              <strong>Intents:</strong>
              {{ item.intents.length }}
            </p>
            <p>
              <strong>Entities:</strong>
              {{ Object.keys(item.entities).length }}
            </p>
            <p>
              <strong>Functions:</strong>
              {{ Object.keys(item.functions.filter((f) => f.type != 'flow')).length }}
            </p>
            <p>
              <strong>Subs:</strong>
              {{ Object.keys(item.functions.filter((f) => f.type == 'flow')).length }}
            </p>
            <p>
              <strong>Variables/Objects:</strong>
              {{ Object.keys(item.flowVariables).length + Object.keys(item.flowObjects).length }}
            </p>

            <p>
              <strong>Channels:</strong>
              {{ item.channels.filter((e) => e.connected === true).length }}
            </p>
          </div>
          <div v-else-if="mode === 'function'">
            <p>
              <strong>Function type:</strong>
              {{ item.type }}
            </p>
          </div>
        </div>

        <p v-if="isTaken" class="error">This name is already taken!</p>
        <p v-if="!isLanguageValid" class="error">Invalid language</p>

        <div style="display: flex; float: right">
          <SmallLoading v-show="creating && !isTaken && isLanguageValid"></SmallLoading>
          <button class="btn btn--purple gradient" type="submit" :disabled="creating && !isTaken && isLanguageValid">
            Import {{ mode }}
            <i class="icon icon-arrow-right"></i>
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Emit, Prop, Mixins, Vue, Watch } from 'vue-property-decorator'
import SmallLoading from '../../helpers/SmallLoading.vue'
import Select from '../../helpers/Select.vue'
import Toggle from '../../helpers/Toggle.vue'
import permissionsService from '../../../services/tenants/permissions.service'
import { Permissions } from '../../../../../common/enums/tenant/user-permissions.enum'
import { nluAutomateTooltip } from '../../../constants/nlu-automate-tooltip.constant'
import { botRegions, botRegionTooltip } from '../../../constants/bot-region.constants'

import tenantService from '../../../services/tenant.service'
import subscriptionMixin from '../configuration/_mixins/subscription.mixin'
import { nluLanguages } from '../../../../../common/constants/nlu-languages.constant'
import { nluNames } from '../../../../../common/constants/nlu-names.constant'

@Component({ components: { SmallLoading, Select, Toggle } })
export default class ImportItem extends Mixins(subscriptionMixin) {
  @Prop({ default: () => [] }) readonly mode: 'intent' | 'function' | 'bot' | 'entity'
  @Prop({ default: {} }) readonly item: any
  @Prop({ default: '' }) readonly name: string
  @Prop({ default: '' }) readonly region: string
  @Prop({ default: '' }) readonly recognizer: string
  @Prop({ default: '' }) readonly language: string
  @Prop({ default: '' }) readonly nluAutomate: string
  @Prop({ default: false }) readonly isTaken: boolean
  @Prop({ default: true }) readonly isLanguageValid: boolean
  @Prop() readonly file: File
  @Prop({ default: () => [] }) readonly languages: any[]
  @Prop({ default: () => '' }) readonly botName: string

  private creating = false
  private isFunctionGlobal = false
  private hasPermissionForGlobalToggle = false
  showBotRegionSelector = false
  botRegionTooltip = botRegionTooltip
  nluAutomateTooltip = nluAutomateTooltip

  regions = botRegions
  recognizers = []
  recognizerLanguages = nluLanguages

  @Emit('importItem')
  importItem() {
    this.creating = true
    return this.mode === 'function' ? this.isFunctionGlobal : false
  }

  @Watch('item', { deep: true })
  onItemChange() {
    this.isFunctionGlobal = this.item.isGlobal
  }

  async created() {
    this.hasPermissionForGlobalToggle = await permissionsService.hasPermission(this.botName, Permissions.ManageGlobalBotData)
    const plan = await tenantService.getPlan()
    this.recognizers = await this.getAllowedEnginesRecognizers(plan)
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables';

.main-fill-color-button {
  float: right;
}

.error {
  color: red;
}

p {
  font-size: 14px;
  color: $default-dark;
}

.language-dropdown {
  margin-top: 14px;
}
</style>
