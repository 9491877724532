<template>
  <div class="container__date-wrapper">
    <div class="configuration-tabs configuration-tabs--left container--date">
      <div class="configuration-tabs--inner">
        <button
          type="button"
          @click="previous"
          class="btn"
          :class="{ disabled: !isEnabled || (selectedPeriod == 'daily' ? !hasPrevDay() : !hasPrevMonth()) }"
        >
          <span class="arrow"></span>
          Prev
        </button>
        <button
          type="button"
          @click="next"
          class="btn"
          :class="{ disabled: !isEnabled || (selectedPeriod == 'daily' ? !hasNextDay() : !hasNextMonth()) }"
        >
          Next
          <span class="arrow next"></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Controls extends Vue {
  @Prop({ required: true }) readonly currentDate!: Date
  @Prop({ default: false }) readonly isEnabled!: boolean
  @Prop({ default: 'monthly' }) readonly selectedPeriod!: 'monthly' | 'daily'
  @Prop({ default: () => ({}) }) readonly lastValues!: any

  hasNextMonth() {
    const year = new Date().getFullYear()
    if (this.currentDate.getFullYear() == year) {
      return this.currentDate.getMonth() < new Date().getMonth()
    }
    return this.currentDate.getFullYear() < year
  }

  hasPrevMonth() {
    if (this.currentDate.getFullYear() == this.lastValues.year) {
      return this.currentDate.getMonth() > this.lastValues.month
    }
    return this.currentDate.getFullYear() > this.lastValues.year
  }

  hasNextDay() {
    const today = new Date();
    const isBeforeCurrentYear = this.currentDate.getFullYear() < today.getFullYear();
    const isSameYear = this.currentDate.getFullYear() === today.getFullYear();
    const isBeforeCurrentMonth = isSameYear && this.currentDate.getMonth() < today.getMonth();
    const isSameMonth = isSameYear && this.currentDate.getMonth() === today.getMonth();
    const isBeforeCurrentDay = isSameMonth && this.currentDate.getDate() < today.getDate();

    return isBeforeCurrentYear || isBeforeCurrentMonth || isBeforeCurrentDay;
  }

  hasPrevDay() {
    const isAfterLastYear = this.currentDate.getFullYear() > this.lastValues.year;
    const isSameYear = this.currentDate.getFullYear() === this.lastValues.year;
    const isAfterLastMonth = isSameYear && this.currentDate.getMonth() > this.lastValues.month;
    const isSameMonth = isSameYear && this.currentDate.getMonth() + 1 === this.lastValues.month;
    const isAfterLastDay = isSameMonth && this.currentDate.getDate() > 1;
    return isAfterLastYear || isAfterLastMonth || isAfterLastDay;
  }


  previous() {
    const date = new Date(this.currentDate)
    if (this.selectedPeriod === 'daily') {
      if (!this.hasPrevDay()) {
        return
      }
      date.setDate(date.getDate() - 1)
    } else {
      if (!this.hasPrevMonth()) {
        return
      }
      date.setMonth(date.getMonth() - 1)
    }
    this.$emit('setCurrentDate', date)
  }

  next() {
    const date = new Date(this.currentDate)
    if (this.selectedPeriod === 'daily') {
      if (!this.hasNextDay()) {
        return
      }
      date.setDate(date.getDate() + 1)
    } else {
      if (!this.hasNextMonth()) {
        return
      }
      date.setMonth(date.getMonth() + 1)
    }
    this.$emit('setCurrentDate', date)
  }
}
</script>


<style lang="scss" scoped>
@import "../../assets/scss/variables";

.container {
  &__date-wrapper,
  &--date {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }

  .btn {
    &.disabled {
      pointer-events: none;
      opacity: 0.35;
    }
  }

  .arrow {
    display: inline-block;
    background: url("/public/assets/icons/arrow.svg") no-repeat;
    width: 10px;
    height: 10px;
    margin-right: 6px;

    &.next {
      transform: rotate(180deg);
      margin-left: 6px;
      margin-right: 0;
    }
  }

  .current-month {
    font-size: 18px;
    font-weight: $normal;
    color: $default;
    margin-left: 19px;
  }
}
</style>
