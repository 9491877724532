// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Adal from 'vue-adal'
import axios from 'axios'
import VueTippy from 'vue-tippy'
import { handleAxiosError, handleError } from './error-handler'
import popupService from './services/popup.service'
import Popup from './components/helpers/Popup.vue'
import VueClipboard from 'vue-clipboard2'
import authService from './services/auth.service'
import store from '@/store'

Vue.config.productionTip = false
Vue.config.errorHandler = (error: any) => handleError(error)
window.onunhandledrejection = (event) => handleError(event.reason)
Vue.use(VueTippy)

Vue.use(VueClipboard)

Vue.prototype.$tabber = function (event, field) {
  if (event) {
    /* eslint-disable */
    event.preventDefault()
    const startText = eval(`this.${field}`).slice(0, event.target.selectionStart)
    const endText = eval(`this.${field}`).slice(event.target.selectionStart)
    eval(`this.${field} = \`${startText}   ${endText}\``)
    event.target.selectionEnd = event.target.selectionStart + 1
    /* eslint-enable */
  }
}
;(async () => {
  try {
    const config = await authService.getAdalConfig()
    Vue.use(Adal, {
      config,
      store,
      requireAuthOnInitialize: false
    })
    await authService.addTokenToAxiosRequests()
  } catch (err) {
      new Vue({ el: '#app', router, store, render: (h) => h(Popup) })
    popupService.showError('Error!', err.message || err)
    return
  }

  axios.interceptors.response.use(
    (response) => response,
    (err) => handleAxiosError(err)
  )

  new Vue({ el: '#app', router, store, render: (h) => h(App) })
})()
