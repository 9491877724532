<template>
  <div class="sync-process">
    <div class="header">
      <div class="dropdown" :class="{ dropdown__opened: isBig }">
        <i class="dropdown-toggle" @click="isBig = !isBig"></i>
      </div>

      <div style="display: flex">
        <h5 class="title">{{ title }}</h5>
        <i
          v-if="lastWarningInfo"
          class="icon icon-warning"
          style="margin-left: 10px"
        ></i>
      </div>

      <div class="header-buttons">
        <SmallLoading v-if="!isSyncFinished"></SmallLoading>

        <button
          v-if="!isSyncFinished || syncHasErrors"
          :disabled="!isSyncFinished || isSyncActive"
          @click="restartSync"
          class="btn btn--purple gradient narrow"
          type="button"
        >
          <i class="icon icon-paperplane"></i>
          {{ !isSyncFinished ? 'Publishing' : 'Retry' }}
        </button>
        <i
          v-if="isSyncFinished"
          class="icon-delete close-icon"
          @click="$emit('close')"
        ></i>
      </div>
    </div>
    <div v-show="isBig" class="content">
      <div :key="index" v-for="(step, index) in steps" class="step">
        <div class="step-loading">
          <SmallLoading
            v-if="step.status === StepStatus.InProgress"
            class="small-loading"
          ></SmallLoading>
          <i
            v-else
            class="icon"
            :class="{
              'icon-ok': step.status === StepStatus.Succeeded,
              'icon-remove': step.status === StepStatus.Failed,
              'icon-warning': step.status === StepStatus.Warning,
            }"
          ></i>
        </div>

        <p class="step-message">{{ getStateMessage(step.text) || step.text }}</p>
        <span v-if="step.progress" class="step-info">{{ step.progress }}%</span>
        <span v-if="step.info" class="step-info">({{ step.info }})</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/abstracts/_variables.scss";
@import "../../../../assets/scss/setup/_colors.scss";

.sync-process {
  margin-bottom: 15px;
  background: $white;
  min-height: 50px;
  padding-top: 15px;
  border-radius: 6px;
  -webkit-box-shadow: 0px 6px 30px #33333314;
  -ms-box-shadow: 0px 6px 30px #33333314;
  box-shadow: 0px 6px 30px #33333314;
  padding-right: 10px;

  h5 {
    color: $default-dark;
    font-size: 14px;
  }

  .header {
    display: flex;
    align-items: center;

    .dropdown {
      margin-left: 15px;
      transform: scale(1.5, 1.5) rotate(270deg);

      &__opened {
        transform: scale(1.5, 1.5);
      }

      .dropdown-toggle {
        color: $color_main;
        cursor: pointer;
      }
    }

    .title {
      margin-top: 2px;
      margin-left: 10px;
    }

    .header-buttons {
      margin-left: auto;
      display: flex;
      align-items: center;
      margin-top: -7px;

      button {
        margin: 0;
        display: flex;
        align-items: center;

        i {
          top: 0;
        }
      }

      .close-icon {
        margin: 0px 10px;
        margin-top: 2px;
        transform: scale(1.25);
      }
    }
  }

  .content {
    margin: 10px 0 0px 20px;
    padding-bottom: 10px;
    font-size: 12px;

    .step {
      display: flex;
      align-items: center;

      .step-loading {
        display: flex;
        justify-content: center;
        width: 40px;

        .small-loading {
          padding: 0;
          transform: scale(0.7, 0.7);
        }
      }

      .step-message,
      .step-info {
        margin: 0;
        font-style: italic;
      }

      .step-info {
        margin-left: 10px;
        font-size: 95%;
      }
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { SyncService } from '@/services/bots/sync.service'
import { ExternalProvider } from '@common/enums/external-provider.enum'
import { SyncObjectQueryResult } from '@common/interfaces/sync-object-query-result'
import { transformObjectValues } from '@common/helpers/object.helper'
import SmallLoading from '../../../helpers/SmallLoading.vue'
import { nluNames } from '@common/constants/nlu-names.constant'
import { SynchronizationError, SynchronizationWarning, ValidationError } from '@common/errors'
import botNluService from '../../../../services/bots/bot-nlu.service'
import { NluProvider } from '@common/types/nlu-provider'
import popupService from '../../../../services/popup.service'
import { SYNC_STATE_MESSAGES, SyncStep } from '@common/enums/bot/sync/sync-state.enum'
import { StepStatus } from '@common/enums/bot/sync/step-status.enum'

interface Step {
  text: string;
  status: StepStatus;
  info?: string;
  progress?: number;
}

@Component({
  components: { SmallLoading }
})
export default class Synchronization extends Vue {
  private syncService: SyncService

  private title = ''
  private isBig = false
  private steps: Step[] = []
  private StepStatus = StepStatus

  get isSyncFinished() {
    return !this.$store.state.botData[this.botId]?.syncStatus.providersBeingPublished.includes(this.provider)
  }

  get syncHasErrors() {
    return this.steps.some((s) => s.status === StepStatus.Failed)
  }

  get lastWarningInfo() {
    return this.steps.find((s) => s.status === StepStatus.Warning)?.info
  }

  get isSyncActive() {
    return this.$store.state.botData[this.botId]?.syncStatus.isSyncActive
  }

  @Prop({ required: true }) botName: string
  @Prop({ required: true }) botId: string
  @Prop({ required: true }) syncId: string
  @Prop({ required: true }) provider: NluProvider
  @Prop() existingSteps: {}
  @Prop() existingSyncId: string | null
  @Prop() isPublished: boolean

  /**
   * @description adds an ongoing step and resolves it once it finishes
   * @returns whether the step was successful
   */
  private async doStep(text: string, f: () => Promise<any>): Promise<boolean> {
    const step: Step = { text, status: StepStatus.InProgress }
    this.steps.push(step)

    try {
      await f()
      step.status = StepStatus.Succeeded
    } catch (err) {
      if (err instanceof SynchronizationError) {
        step.info = err.message
        step.status = StepStatus.Failed
      } else if (err instanceof SynchronizationWarning) {
        step.info = err.message
        step.status = StepStatus.Warning
      } else if (err instanceof ValidationError) {
        step.status = StepStatus.Failed
        step.info = err.message
        throw new Error(err.message)
      } else {
        step.status = StepStatus.Failed
        throw err
      }
    }

    return step.status === StepStatus.Succeeded
  }

  public finishTraining() {
    this.endSync()
  }

  public updateStep(type: SyncStep, step: Step) {
    step.text = type
    if (this.steps.length) {
      const lastStep = this.steps[this.steps.length - 1]
      if (lastStep.text === type) {
        lastStep.status = step.status
        lastStep.info = step.info
        lastStep.progress = step.progress
        return
      } else if (lastStep.status == StepStatus.InProgress) {
        lastStep.status = StepStatus.Succeeded
      }
    }
    this.steps.push(step)
  }

  private async setStep(text: string, f: () => Promise<any>): Promise<boolean> {
    const step: Step = { text, status: StepStatus.InProgress }
    this.steps.push(step)

    try {
      await f()
      step.status = StepStatus.Succeeded
    } catch (err) {
      if (err instanceof SynchronizationError) {
        step.info = err.message
        step.status = StepStatus.Failed
      } else if (err instanceof SynchronizationWarning) {
        step.info = err.message
        step.status = StepStatus.Warning
      } else if (err instanceof ValidationError) {
        step.status = StepStatus.Failed
        step.info = err.message
        throw new Error(err.message)
      } else {
        step.status = StepStatus.Failed
        throw err
      }
    }

    return step.status === StepStatus.Succeeded
  }

  created() {
    this.syncService = new SyncService(
      this.botName,
      this.syncId,
      this.provider
    )
    this.title = nluNames[this.provider]
    if (!this.existingSyncId) {
      this.startSync()
    } else {
      for (const [step, state] of Object.entries<any>(this.existingSteps)) {
        this.updateStep(step as SyncStep, state)
      }
    }
  }

  getStateMessage(state: string) {
    return SYNC_STATE_MESSAGES[state]
  }

  async restartSync() {
    if (!this.isPublished) {
      popupService.showError(
        'Error!',
        'Your latest NLU model will not be published to all engines. You should restart the whole publish.'
      )
    } else {
      await this.startSync()
    }
  }

  async endSync() {
    this.$emit('end')
  }

  async startSync() {
    this.$emit('start')
    this.steps = []

    try {
      await this.ensureResource()
      if (this.provider === ExternalProvider.Rasa || this.provider === ExternalProvider.MicrosoftCLU) {
        await this.allAtOnceSync()
      } else {
        await this.progressiveSync()
      }
    } catch (err) {
    }
  }

  async ensureResource() {
    let { hasResource, hasNlu } = await botNluService.getNluStatus(
      this.botName,
      this.provider
    )
    if (!hasResource) {
      hasResource = await this.doStep('NLU Resource', () =>
        botNluService.createNluResource(this.botName, this.provider)
      )
    }
    if (hasResource && !hasNlu) {
      hasNlu = await this.doStep('NLU', () =>
        botNluService.createNlu(this.botName, this.provider)
      )
    }
    if (!hasNlu) {
      throw new Error()
    }
  }

  async allAtOnceSync() {
    await this.syncService.startAllAtOnceSync()
    // try {
    //   await this.doStep('NLU Model', async () => {
    //       const currentStep = this.steps[this.steps.length - 1]
    //       this.syncService.emitter.on('progress', (progress) => {
    //         if (progress > 0) {
    //           currentStep.text = `NLU Model: ${Math.round(progress)}%`
    //         }
    //       })
    //       this.syncService.emitter.on('complete', () => {
    //         currentStep.text = currentStep.text.replace(/: \d+%/, '')
    //       })
    //       await this.syncService
    //         .startAllAtOnceSync()
    //         .then((_) => this.syncService.waitSync())
    //     }
    //   )
    // } catch (err) {
    // }
  }

  progressiveSync() {
    return this.syncService.executeFullProgressiveSync()
  }

  async getEntities() {
    const data = await this.syncService.getEntities()
    return this.transformObjectQueryResult(data)
  }

  // Returns intents to create and update
  async getIntents() {
    const data = await this.syncService.getIntents()
    return this.transformObjectQueryResult(data)
  }

  private transformObjectQueryResult(data: SyncObjectQueryResult<any>) {
    return transformObjectValues<any, string>(data, (objArr) =>
      objArr.map((o) => o.name)
    )
  }
}
</script>
