<template>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <div class="form-group--inside">
          <h4>LLM Guardrails</h4>

          <div class="description">Here, you can set the custom guardrails config for your LLM.</div>

          <h4 class="mt">Custom Guardrails Config</h4>
          <div class="form-group--inside">
            <Autocomplete
              :items="variables"
              name="customGuardrailsConfig"
              id="customGuardrailsConfig"
              v-model="customGuardrailsConfig"
              :rows="1"
              placeholder="Enter the custom guardrails config (advance setting, leave empty for default guardrails)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { LLMObject } from '../../../../../../common/types/llm-type'
import Autocomplete from '@/components/helpers/Autocomplete.vue'
import Dropdown from '@/components/helpers/Dropdown.vue'

@Component({ components: { Autocomplete, Dropdown } })
export default class LLMGuardrails extends Vue {
  @Prop() LLM: LLMObject
  @Prop() variables: string[]

  get customGuardrailsConfig() {
    return this.LLM.customGuardrailsConfig
  }

  set customGuardrailsConfig(value: string) {
    this.LLM.customGuardrailsConfig = value
  }
}
</script>

<style scoped lang="scss">
@import '../../../../assets/scss/variables';

.description {
  font-size: 14px;
  margin-bottom: 15px;
}

.mt {
  margin-top: 20px;
}
</style>
