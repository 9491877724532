<template>
  <div class="app-dropdown show">
    <button
      type="button"
      :disabled="items.length === 0 || (disabled !== undefined && disabled)"
      aria-haspopup="true"
      aria-expanded="false"
      class="btn intents dropdown-toggle dropdown-toggle-split"
      v-on:click.prevent.stop="dropdownClick('Dropdown.vue')"
    >
      {{ displayTitle }}
    </button>
    <div class="dropdown-menu dropdown-menu--options" :class="{show:dropdownOpen === 'Dropdown.vue'}">
      <a
        v-for="(item, index) in items"
        :key="index"
        href="#"
        class="dropdown-item"
        :class="{ active: item === displayTitle }"
        @click.prevent="$emit('input', item)"
        >{{ item ? item.value || item : 'None' }}</a
      >
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Mixins, Prop, Vue} from 'vue-property-decorator'
import DropdownMixin from "@/components/admin/bots/_mixins/dropdown.mixin";

@Component
export default class Dropdown extends Mixins(Vue, DropdownMixin) {
  @Prop({ default: 'Choose an option' }) readonly title: string
  @Prop({ default: () => [] }) readonly items: (string | { [key: string]: string })[]
  @Prop() readonly value: string | { [key: string]: string }
  @Prop() disabled: boolean

  get displayTitle() {
    return this.value || this.title
  }
}
</script>

<style lang="scss" scoped>
.dropdown-menu {
  position: absolute;
  width: 100%;
}
</style>
