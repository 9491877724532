<template>
  <Modal title="Create Entity" :show="true" @close="$emit('close')">
    <div
      class="modal-description"
    >Create a new entity for your bot. Start with selecting which entity type you want to use. Some engines may not support all entities types, see our user-manual for more info.</div>
    <section class="content">
      <div class="container-top">
        <div class="configuration-tabs entity">
          <div class="configuration-tabs--inner">
            <button
              :key="index"
              v-for="(e, index) in entityTypes"
              type="button"
              class="tab"
              :class="{ 'tab--active' : selectedEntityId == e.id }"
              @click="selectedEntityId = e.id"
            >{{e.name}}</button>
          </div>
        </div>
      </div>

      <div class="container-center">
        <div id="list-entity" class="step-content">
          <div class="form--wrapper">
            <form @submit.prevent="submitEntity()">
              <div class="form-group">
                <div class="form-group--inside">
                  <label for="enityName">Entity Name</label>
                  <input
                    v-model="entityName"
                    :disabled="isEditMode"
                    :class="{ disabled: isEditMode }"
                    type="text"
                    placeholder="Type an entity name"
                  />
                  <div v-if="nameError" class="invalid">Please provide a valid entity name. Entity name can contain only the following characters: "A-Z a-z 0-9 _"</div>
                </div>
              </div>

              <div class="form-group">
                <div class="form-group--inside">
                  <label for="enityDescription">Entity Description</label>
                  <input
                    v-model="entityDescription"
                    :disabled="isEditMode"
                    :class="{ disabled: isEditMode }"
                    type="text"
                    rows="1"
                    placeholder="Type a description"
                  />
                  <div v-if="descError" class="invalid">Please provide a valid intent description</div>
                </div>
              </div>

              <br />

              <div class="form-group" v-if="selectedEntityId == 1">
                <div class="form-group--inside">
                  <h4 class="underline">Sublists</h4>
                  <div class="margin-bottom-20"></div>
                  <div v-if="sublistsError" class="invalid">Please provide at least 1 valid sublist</div>
                  <div class="row">
                    <div class="col-6 padding-right">
                      <label>Canonical Form</label>
                      <template v-for="(sublist, index) in sublists">
                        <div
                          v-if="sublist.flag != 'deleted'"
                          :key="index"
                          :id="'sublist-' + index"
                        >
                          <input
                            v-model="sublist.canonicalForm"
                            type="text"
                            placeholder="Enter the canonical form."
                            @keyup.enter="addSublist()"
                            class="form-control blue-card margins"
                            :class="{'first-input margin': index === 0 }"
                          />
                        </div>
                      </template>
                    </div>
                    <div class="col-6">
                      <label>Utterances</label>
                      <template v-for="(sublist, index) in sublists">
                        <div
                          v-if="sublist.flag != 'deleted'"
                          :key="index"
                          :id="'sublist-' + index"
                        >
                          <input
                            v-model="sublist.list"
                            :class="{'first-input margin': index === 0, 'fix-padding': index != 0 }"
                            type="text"
                            class="form-control blue-card margins"
                            placeholder="Enter synonyms"
                            @keyup.enter="addSublist()"
                          />
                          <div type="button" class="copybutton dark entities" v-if="index === 0" @click="addSublist()">Add</div>
                          <i
                            class="icon-delete entity-delete add"
                            @click="deleteSublist(index)"
                            :class="{'first-input': index === 0, 'fix-pading-delete': index != 0 }"
                          ></i>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group" v-else-if="selectedEntityId == 2">
                <div class="form-group--inside">
                  <div class="row">
                    <div class="col-12">
                      <h4 class="underline">Simple Entity</h4>
                      <label>Examples</label>

                      <div
                        v-for="(example, index) in examples"
                        :key="index"
                        :id="'examples-' + index"
                      >
                        <input
                          v-model="example.name"
                          type="text"
                          class="form-control blue-card margins"
                          placeholder="Enter the example."
                          :class="{'first-input margin': index === 0, 'fix-padding': index != 0 }"
                          @keyup.enter="addExample()"
                        />
                        <div type="button" class="copybutton dark entities" v-if="index === 0" @click="addExample()">Add</div>
                        <i
                          class="icon-delete entity-delete add"
                          @click="deleteExample(index)"
                          :class="{'first-input': index === 0, 'fix-pading-delete': index != 0 }"
                        ></i>
                      </div>

                      <div v-if="examplesError" class="invalid">Please provide at least 1 example</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group" v-else-if="selectedEntityId == 3">
                <div class="form-group--inside">
                  <div class="row">
                    <div class="col-12">
                      <h4 class="underline">Regex Entity</h4>
                      <label>Regexes</label>

                      <div
                        v-for="(regex, index) in regexes"
                        :key="index"
                        :id="'regex-' + index"
                      >
                        <input
                          v-model="regex.name"
                          type="text"
                          class="form-control blue-card margins"
                          placeholder="Enter regex."
                          :class="{'first-input margin': index === 0, 'fix-padding': index != 0 }"
                          @keyup.enter="addRegex()"
                        />
                        <div type="button" class="copybutton dark entities" v-if="index === 0" @click="addRegex()">Add</div>
                        <i
                          class="icon-delete entity-delete add"
                          @click="deleteRegex(index)"
                          :class="{'first-input': index === 0, 'fix-pading-delete': index != 0 }"
                        ></i>
                      </div>

                      <div v-if="regexesError" class="invalid">Please provide at least 1 regex</div>

                      <label>Examples <Tooltip placement="right" title="Needed for some integrations"></Tooltip></label>
                      <div
                        v-for="(example, index) in examples"
                        :key="index + 'regex'"
                        :id="'examples-' + index"
                      >
                        <input
                          v-model="example.name"
                          type="text"
                          class="form-control blue-card margins"
                          placeholder="Enter the example."
                          :class="{'first-input margin': index === 0, 'fix-padding': index != 0 }"
                          @keyup.enter="addExample()"
                        />
                        <div type="button" class="copybutton dark entities" v-if="index === 0" @click="addExample()">Add</div>
                        <i
                          class="icon-delete entity-delete add"
                          @click="deleteExample(index)"
                          :class="{'first-input': index === 0, 'fix-pading-delete': index != 0 }"
                        ></i>
                      </div>

                      <div v-if="examplesError" class="invalid">Please provide at least 1 example</div>
                      <div v-if="regexExamplesError" class="invalid">All examples must matche at least 1 regex</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="form-group--inside">
                  <h4 class="underline">Roles</h4>

                  <div class="row">
                    <div class="col-12">
                      <template v-for="(role, index) in roles">
                        <div
                          v-if="role.flag != 'deleted'"
                          :key="index"
                          :id="'roles-' + index"
                        >
                          <input
                            v-model="role.name"
                            type="text"
                            class="form-control blue-card margins"
                            :class="{'first-input margin': index === 0, 'fix-padding': index != 0 }"
                            placeholder="Enter the name."
                            @keyup.enter="addRole(index)"
                          />
                          <div type="button" class="copybutton dark entities" v-if="index === 0" @click="addRole(index)">Add</div>
                          <i
                            class="icon-delete entity-delete add"
                            @click="deleteRole(index)"
                            :class="{'first-input': index === 0, 'fix-pading-delete': index != 0 }"
                          ></i>
                        </div>
                      </template>
                    </div>
                  </div>

                  <div
                    v-if="errorMessage"
                    class="alert alert-danger"
                    role="alert"
                  >{{ errorMessage }}</div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="bottom-right-fix">
        <button class="btn btn--purple gradient" @click="submit()">
          Create Entity
          <i class="icon icon-arrow-right"></i>
        </button>
      </div>
    </section>
  </Modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import Loading from '../../../helpers/Loading.vue'
import Modal from '../../../helpers/Modal.vue'
import popupService from '../../../../services/popup.service'
import Tooltip from '@/components/helpers/Tooltip.vue'

@Component({
  components: {
    Tooltip,
    Loading,
    Modal
  }
})
export default class CreateEntity extends Vue {
  @Prop() entities: Array<any>;
  @Prop() botValidator;
  @Prop() intentNames: string[];

  private entityName = '';
  private entityDescription = '';
  private sublists = [
    {
      canonicalForm: '',
      list: '',
      flag: ''
    }
  ];

  private roles = [
    {
      name: '',
      flag: ''
    }
  ];

  private examples = [];
  private regexes = [];

  private entityTypes = [
    { id: 1, name: 'Closed List' },
    { id: 2, name: 'Simple' },
    { id: 3, name: 'Regex' }
  ];

  private selectedEntityId = 1;

  private errorMessage = '';
  private isEditMode = false;
  private nameError = false;
  private descError = false;
  private sublistsError = false;
  private examplesError = false;
  private regexesError = false;
  private regexExamplesError = false;
  private isLoading = false;

  submit () {
    this.isLoading = true
    if (Object.keys(this.entities).some(e => e === this.entityName)) { popupService.showError('Entity with that name already exists') } else if (this.intentNames.includes(this.entityName)) { popupService.showError('Intent with that name already exists') } else {
      const botValidation = this.botValidator.validatorForCreateEntity(
        this.entityName
      )

      if (
        this.botValidator.validateName(this.entityName) ||
        botValidation.length > 0
      ) {
        if (botValidation.length > 0) {
          popupService.showError(botValidation.pop())
        }

        this.nameError = true
      } else {
        this.nameError = false

        if (this.selectedEntityId == 1) {
          this.submitEntity()
        } else if (this.selectedEntityId == 2) {
          this.submitSimpleEntity()
        } else {
          this.submitRegexEntity()
        }
      }
      this.isLoading = false
    }
  }

  submitEntity () {
    // if (this.validateDesc(this.entityDescription)) { this.descError = true; return } else { this.descError = false }
    this.sublists.shift()
    const nonEmptySublists = this.sublists.filter(
      sl => !!sl.canonicalForm && sl.canonicalForm.trim()
    )
    if (nonEmptySublists.length === 0) {
      this.sublists.push({ canonicalForm: '', list: '', flag: '' })
      this.sublistsError = true
      return
    } else {
      this.sublistsError = false
    }

    if (this.roles.length != 0) {
      this.roles.shift()
    }
    const entity = {
      name: this.entityName.trim(),
      description: this.entityDescription.trim(),
      sublists: nonEmptySublists,
      roles: this.roles,
      type: 'closedlists'
    }
    this.$emit('create', entity)
  }

  beforeMount () {
    this.addExample()
    this.addRegex()
  }

  addSublist () {
    if (this.sublists.some(s => s.canonicalForm.trim() == '' || s.list.trim() == '')) return
    this.sublists.unshift({ canonicalForm: '', list: '', flag: 'created' })
  }

  deleteSublist (index) {
    popupService.deletePopupEvent(
      'sublist ' + this.sublists[index].canonicalForm, () => {
        this.sublists.splice(index, 1)
      })
  }

  addRole () {
    if (this.roles.some(r => r.name.trim() == '')) return
    this.roles.unshift({ name: '', flag: 'created' })
  }

  addExample () {
    if (this.examples.some(r => r.name.trim() == '')) return
    this.examples.unshift({ name: '' })
  }

  addRegex () {
    if (this.regexes.some(r => r.name.trim() == '')) return
    this.regexes.unshift({ name: '' })
  }

  deleteRole (index) {
    popupService.deletePopupEvent(
      'role ' + this.roles[index].name, () => {
        this.roles.splice(index, 1)
      })
  }

  deleteExample (index) {
    popupService.deletePopupEvent(
      'example ' + this.examples[index].name, () => {
        this.examples.splice(index, 1)
      })
  }

  deleteRegex (index) {
    popupService.deletePopupEvent(
      'regex ' + this.regexes[index].name, () => {
        this.regexes.splice(index, 1)
      })
  }

  submitSimpleEntity () {
    this.examples.shift()
    const nonEmptyExamples = this.examples
      .map(e => e.name)
      .filter(e => !!e && e.trim())
    if (nonEmptyExamples.length === 0) {
      this.examplesError = true
      this.examples.push([])
      return
    } else {
      this.examplesError = false
    }
    if (this.roles.length != 0) {
      this.roles.shift()
    }
    const entity = {
      name: this.entityName.trim(),
      description: this.entityDescription.trim(),
      roles: this.roles,
      examples: nonEmptyExamples,
      type: 'entities'
    }
    this.$emit('create', entity)
  }

  submitRegexEntity () {
    this.regexes.shift()
    this.examples.shift()
    const nonEmptyRegexes = this.regexes
      .map(e => e.name)
      .filter(e => !!e && e.trim())
    const nonEmptyExamples = this.examples
      .map(e => e.name)
      .filter(e => !!e && e.trim())
    if (nonEmptyRegexes.length === 0) {
      this.regexesError = true
      this.examples.splice(0, 0, [])
      this.regexes.push([])
      return
    } else {
      this.regexesError = false
    }
    try {
      nonEmptyRegexes.forEach(r => new RegExp(r))
    } catch (_) {
      this.regexesError = true
      return
    }
    if (nonEmptyExamples.length === 0) {
      this.examplesError = true
      this.regexExamplesError = false
      this.examples.splice(0, 0, [])
      this.regexes.splice(0, 0, [])
      return
    } else if (!nonEmptyExamples.every(e => nonEmptyRegexes.some(r => new RegExp(r).test(e)))) {
      this.regexExamplesError = true
      this.examplesError = false
      this.examples.splice(0, 0, [])
      this.regexes.splice(0, 0, [])
      return
    } else {
      this.examplesError = false
      this.regexExamplesError = false
    }
    this.regexExamplesError = false
    this.examplesError = false
    this.regexesError = false
    if (this.roles.length != 0) {
      this.roles.shift()
    }
    const entity = {
      name: this.entityName.trim(),
      description: this.entityDescription.trim(),
      roles: this.roles,
      regexes: nonEmptyRegexes,
      examples: nonEmptyExamples,
      type: 'regexentities'
    }
    this.$emit('create', entity)
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/scss/setup/_colors.scss";
.fix-padding {
  top: -12px;
}

.fix-pading-delete {
  top: -28px !important;
}
</style>
