export enum BlockType {
  MessageBlock = "message",
  AskQuestion = "askQuestion",
  SendGIF = "sendGif",
  ShowOptions = "showOptions",
  StatisticsBlock = "userStatistics",
  Rating = "showRating",
  RedirectCall = "redirectCall",
  MultipleEntityCheck = "multipleEntityCheck",
  RandomAnswer = "randomAnswer",
  SendAttachment = "sendAttachment",
  ReceiveUserAttachmentBlock = "receiveUserAttachment",
  SetVariable = "setVariable",
  SetObject = "setObject",
  DictionaryBlock = "dictionary",
  Counter = "passCounter",
  ReplaceInVariable = "replaceInVariable",
  RecognizeIntent = "triggerRecognizer",
  SimpleCalculationBlock = "simpleCalculationBlock",
  SendConversationHistory = "sendConversationHistory",
  Handover = "handover",
  SendEmail = "sendEmail",
  LanguageTranslation = "languageTranslation",
  ValidationBlock = "validationBlock",

  UnexpectedAnswer = "unexpectedAnswer",
  LanguageDetection = "languageDetection",
  DelayBlock = "blockDelay",

  // SCSM Functions ->
  GetTicket = "GetTicket",
  EditTicket = "EditTicket",
  CreateTicket = "CreateTicket",
  DeleteTicket = "DeleteTicket",
  GetTicketsOfUser = "GetTicketsOfUser",
  GetAssetsOfUser = "GetAssetsOfUser",
  GetAssetDetails = "GetAssetDetails",

  // Foodl Functions ->
  Add = "addToBasket",
  LLM = "llm",
}
