/**
 * @param secondsBefore used to get time in the recent past, for audit queries.
 */
export function getGMTDateObject(secondsBefore = 0): Date {
  const now = new Date();
  const utc = new Date(
    now.getTime() + now.getTimezoneOffset() * 60000 - secondsBefore * 1000
  );

  return utc;
}

export function parseGMTDateObject(timestamp: number): Date {
  const date = new Date(timestamp);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}

export function formatGMTDate(date: Date): string {
  return date.toLocaleString("en-NL", { hour12: false });
}

export function getSecondsInCurrentMonth() {
  const now = new Date();
  return (
    new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate() * 60 * 60 * 24
  );
}

export function getSecondsInFuture(minutes = 0) {
  return getSeconds() + minutes * 60;
}

/** @description returns the seconds that have passed since the unix epoch */
export function getSeconds() {
  return Math.floor(Date.now() / 1000);
}

export function getGMTDate() {
  return formatGMTDate(getGMTDateObject());
}

export function getFullMonthName(date: number) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return monthNames[date];
}

export function formatLocaleDate(date = new Date()): string {
  return date.toLocaleDateString("en-NL").replaceAll("/", "-");
}

export function formatLocaleTime(date = new Date()): string {
  return date.toLocaleTimeString("en-NL");
}
