<template>
  <div class="wrapper-inside general-settings active">
    <SmallLoading v-if="isLoading" />
    <div v-else class="form-section">
      <h4>NLU Status</h4>
      <table v-if="nluStatus.lastSyncId !== undefined">
        <thead>
          <tr>
            <th>Provider</th>
            <th>Is configured</th>
            <th>Last publish</th>
            <th>Is latest publish</th>
          </tr>
        </thead>
        <tbody>
          <tr :key="provider" v-for="(status, provider) in nluStatus.status">
            <template v-if="isProviderDisabled(provider)">
              <td>{{ recognizers[provider] }}</td>
              <td>{{ isNluConfigured(provider) ? 'Yes' : 'No' }}</td>
              <td>{{ getSyncDate(status.lastSyncTime) }}</td>
              <td>
                {{ status.lastSyncId === nluStatus.lastSyncId ? 'Yes' : 'No' }}
              </td>

              <div class="buttons">
                <SmallLoading v-if="isNluConfiguring(provider)"></SmallLoading>
                <button v-else-if="isConfigurable(provider) && !isNluConfigured(provider)" class="btn btn--white" @click="configureNlu(provider)">
                  Configure
                </button>
                <button v-else-if="isNluConfigured(provider)" class="btn btn--white red" @click="removeNlu(provider)">Remove</button>
              </div>
            </template>
          </tr>
        </tbody>
      </table>
      <div class="helper-text" v-else>Publish your latest model to view the latest status</div>
      <button class="btn btn--purple gradient" type="button" @click="$parent.$emit('publish')" v-if="hasPermissionToPublish">
        <i class="icon icon-paperplane"></i>Publish NLU model
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { nluNames } from '../../../../../../../common/constants/nlu-names.constant'
import { NluProvider } from '../../../../../../../common/types/nlu-provider'
import botNluService from '../../../../../services/bots/bot-nlu.service'
import { SyncService } from '../../../../../services/bots/sync.service'
import permissionsService from '../../../../../services/tenants/permissions.service'
import SmallLoading from '../../../../helpers/SmallLoading.vue'
import { Permissions } from '../../../../../../../common/enums/tenant/user-permissions.enum'
import popupService from '../../../../../services/popup.service'
import { TenantPlan } from '../../../../../../../common/interfaces/plans/tenant-plan.interface'
import subscriptionMixin from '../../../configuration/_mixins/subscription.mixin'

@Component({ components: { SmallLoading } })
export default class NluStatus extends Mixins(subscriptionMixin) {
  isLoading = true

  @Prop() botName: string
  @Prop() tenantPlan: TenantPlan
  recognizers = nluNames

  private nluStatus: any = {}
  private configuredNlus: NluProvider[] = []
  private configuringNlus: NluProvider[] = []

  private hasPermissionToPublish = false

  async created() {
    try {
      await Promise.all([this.loadSyncStatus(), this.loadConfiguredNlus(), this.loadPermissions()])
    } finally {
      this.isLoading = false
    }
  }

  async loadSyncStatus() {
    this.nluStatus = await SyncService.getSyncStatus(this.botName)
  }

  async loadConfiguredNlus() {
    this.configuredNlus = await botNluService.getConfiguredNlps(this.botName)
  }

  async loadPermissions() {
    this.hasPermissionToPublish = await permissionsService.hasPermission(this.botName, Permissions.PublishIntentsAndEntities)
  }

  isNluConfigured(provider: NluProvider) {
    return this.configuredNlus.includes(provider)
  }

  isNluConfiguring(provider: NluProvider) {
    return this.configuringNlus.includes(provider)
  }

  getSyncDate(time: number) {
    if (!time) return '-'
    return new Date(time).toLocaleString('en-NL')
  }

  async configureNlu(provider: NluProvider) {
    if (this.configuringNlus.includes(provider)) {
      return
    }

    this.configuringNlus.push(provider)

    try {
      const { hasResource, hasNlu } = await botNluService.getNluStatus(this.botName, provider)
      if (!hasResource) {
        await botNluService.createNluResource(this.botName, provider)
      }
      if (!hasNlu) {
        await botNluService.createNlu(this.botName, provider)
      }
      this.configuredNlus.push(provider)
    } finally {
      const index = this.configuringNlus.indexOf(provider)
      if (index > -1) {
        this.configuringNlus.splice(index, 1)
      }
    }
  }

  async removeNlu(provider: NluProvider) {
    if (this.configuringNlus.includes(provider)) {
      return
    }

    const remainingNlus = this.configuredNlus.filter((nlu) => !this.configuringNlus.includes(nlu))
    if (remainingNlus.length === 1 && remainingNlus[0] === provider) {
      return popupService.showError('You cannot delete your last NLU Engine!')
    }

    this.configuringNlus.push(provider)

    try {
      await botNluService.deleteNlu(this.botName, provider)

      const index = this.configuredNlus.indexOf(provider)
      if (index > -1) {
        this.configuredNlus.splice(index, 1)
      }
      Object.keys(this.nluStatus.status[provider]).forEach((key) => (this.nluStatus.status[provider][key] = undefined))
    } finally {
      const index = this.configuringNlus.indexOf(provider)
      if (index > -1) {
        this.configuringNlus.splice(index, 1)
      }
    }
  }

  isProviderDisabled(provider: NluProvider) {
    const allowedEngines = this.getAvailableEngines(this.tenantPlan)
    return allowedEngines ? allowedEngines.includes(provider.toLowerCase()) : true
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/variables';

table,
p {
  margin-left: 20px;
}

th,
td {
  padding: 0 30px 20px 0;
  font-size: 15px;
  color: $default-dark;
  text-align: center;
}

.helper-text {
  font-size: 12px;
  color: $grey;
}

.buttons {
  margin: 0;

  div {
    padding-top: 4px;
    padding-left: 31px;
  }
  button {
    width: 100%;
  }
}
</style>
