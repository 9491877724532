<template>
  <div class="container">
    <div class="doc-content">
      <h2>App Headers</h2>
      <div>
        <h1>Header 1</h1>
        <h2>Header 2</h2>
        <h3>Header 3</h3>
        <h4>Header 4</h4>
        <h5>Header 5</h5>
        <h6>Header 6</h6>
      </div>
      <h2>App Buttons</h2>
      <div class="buttons-showroom">
        <button class="save-cancel-button">Demo Button</button>
        <button class="main-fill-color-button">Demo Button</button>
        <button class="grn-button">Grn Button</button>
        <button class="inv-purple-button">Demo Button</button>
        <button class="inv-main-button">Inv main Button</button>
        <button class="inv-blue-button">Inv Blue Button</button>
        <button class="blue-button">Blue Button</button>
        <button class="normal-button">Normal Button</button>
        <button class="btn-danger">Danger Button</button>

        <button class="btn-action">Action Button</button>
        <button class="btn-close">Close Button</button>
        <button class="btn-add-dahsed">Demo Button</button>
        <button class="btn-submit">Submit Button</button>
        <br />

        <button class="btn-add">Demo Button</button>
        <button class="dashed-line-button"><i class="plus"></i></button>
        <button class="add-button"><i class="plus"></i></button>
        <button class="button-with-tick"><i class="plus"></i></button>
        <button class="prl-button">Prl Button</button>
      </div>

      <h2>App Forms</h2>
      <div class="form--wrapper">
        <input type="text" placeholder="This is a text field" />
        <textarea placeholder="This is a textarea"></textarea>
        <select>
          <option>Option 1</option>
          <option>Option 2</option>
          <option>Option 3</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class StyleDoc extends Vue {
  @Prop() bots: []
  @Prop() files: File
}
</script>

<style lang="scss" scoped>
.doc-content {
  margin-top: 50px;
  position: relative;
  margin-bottom: 100px;

  & > h2 {
    margin-top: 75px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;
  }
}

.buttons-showroom {
  position: relative;
}

.form--wrapper {
  background-color: white;
  padding: 10px;
  & > * {
    margin: 15px 0;
  }
}
</style>
