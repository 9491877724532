<template>
  <div class="options--right">
    <div class="s-r-v-group">
      <div class="s-r-v-group--version">
        <button
          id="dropdownMenuLink2"
          type="button"
          class="btn save-reset-version version dropdown-toggle dropdown-toggle-split history-button"
          v-on:click.prevent.stop="dropdownClick('dropdownMenuLink2')"
          aria-haspopup="true"
          aria-expanded="false"
        ><span :key="`v-${componentKey}`">v{{ currentVersion }}</span></button>

        <div class="dropdown-menu dropdown-menu-h" aria-labelledby="dropdownMenuLink2"
             :class="{show:dropdownOpen === 'dropdownMenuLink2'}">
          <p v-if="!currentIntent.content.xmlArray[0]">There are no previous versions.</p>

          <div
            v-for="(version, index) in currentIntent.content.xmlArray"
            :id="index"
            :key="index"
            class="dropdown-item"
            @click="openVersion(index)"
            :title="isAutoSaved(version) ? 'This version was auto saved!' : ''"
            :class="{ 'auto-saved': isAutoSaved(version), 'faulty-flow': isFaultyFlow(version), 'active': version.version == (currentVersion || '').replace('*','') }"
          >
            <div>
              <strong>v{{ version.version }}{{ isAutoSaved(version) ? '*' : '' }}</strong>
              <span v-if="version.modified"> - {{ version.modified }} (GMT)</span>
            </div>
          </div>

          <div v-if="currentIntent.content.labeledVersions[0]" class="dropdown-item margin-15">
            <strong class="title">VERSION IN PRODUCTION</strong>
          </div>
          <div
            v-for="(ver, index) in currentIntent.content.labeledVersions"
            :key="`${index}-published`"
            class="dropdown-item labeled-version"
            @click="openLabeledVersion('published')"
          >{{ ver.name }}
          </div>
          <div v-if="mode === 'intents'">
            <div v-if="currentIntent.content.testVersions[0]" class="dropdown-item margin-15">
              <strong class="title">VERSION IN TEST</strong>
            </div>
            <div
              v-for="(ver, index) in currentIntent.content.testVersions"
              :key="`${index}-test`"
              class="dropdown-item labeled-version"
              @click="openTestVersion('published')"
            >{{ ver.name }}
            </div>
          </div>
        </div>
      </div>

      <button
        v-tippy="{
                  placement: 'bottom',
                  arrow: true,
                  theme: 'light',
                  delay: [1500, 0]
                }"
        type="button"
        class="btn save-reset-version reset"
        title="Reset flow"
        @click="$emit('reset')"
        v-if="hasPermissionManageIntents"
      >
        <div></div>
      </button>
      <button
        v-tippy="{
                  placement: 'bottom',
                  arrow: true,
                  theme: 'light',
                  delay: [1500, 0]
                }"
        type="button"
        class="btn save-delete-version delete"
        title="Clear"
        @click="$emit('clear')"
        v-if="hasPermissionManageIntents"
      >
        <div></div>
      </button>

      <button
        v-tippy="{
                  placement: 'bottom',
                  arrow: true,
                  theme: 'light',
                  delay: [1500, 0]
                }"
        type="button"
        class="btn save-reset-version export"
        title="Export Flow"
        @click="$emit('export')"
        v-if="hasPermissionImportAndExportFlows"
      >
        <div></div>
      </button>

      <button
        v-tippy="{
                  placement: 'bottom',
                  arrow: true,
                  theme: 'light',
                  delay: [1500, 0]
                }"
        type="button"
        class="btn save-reset-version import"
        title="Import Flow"
        @click="$refs.file.click()"
        v-if="hasPermissionImportAndExportFlows"
      >
        <div></div>
      </button>
      <input
        id="upload"
        class="form-control upload-file"
        type="file"
        ref="file"
        accept=".json"
        hidden
        @change="selectFile"
      />

      <div class="s-r-v-group--version" @mouseover="saveHover = true" @mouseleave="setSaveHoverCloseTimeout">
        <button
          type="button"
          class="btn save-reset-version save"
          @click="save"
          v-if="hasPermissionManageIntents || hasPermissionToChangeTexts"
          :disabled="isSaving"
        >
          <div></div>
        </button>
        <div @mouseover="deleteSaveHoverCloseTimeout" @mouseleave="saveHover = false">
          <div class="save-dropdown" v-if="saveHover && hasPermissionPublishFlowsTest">
            <div @click.stop class="auto-deploy" v-if="hasPermissionPublishFlowsTest">
              <Toggle
                label="Auto-activate"
                :tooltip="'Auto activate this intent flow to the test chat channel'"
                :disabled="autoPublishStatus.isUpdating"
                :value="autoPublishStatus.autoPublish"
                @input="$emit('update:autoPublish', $event)"
              ></Toggle>
            </div>
          </div>
        </div>
      </div>

      <button
        v-tippy="{
                  placement: 'bottom',
                  arrow: true,
                  theme: 'light',
                  delay: [1500, 0]
                }"
        type="button"
        class="btn btn--purple gradient narrow dialog-manager"
        title="Publish to live"
        @click="$emit('publish')"
        v-if="hasPermissionPublishFlowsPrd && mode === 'functions'"
      ><i class="icon icon-paperplane"></i>
        <div></div>
      </button>

      <div class="s-r-v-group--version" v-if="mode === 'intents'">
        <button
          v-tippy="{
                  placement: 'bottom',
                  arrow: true,
                  theme: 'light',
                  delay: [1500, 0]
                }"
          type="button"
          class="btn btn--purple gradient narrow dialog-manager"
          title="Activate flow"
          aria-haspopup="true"
          aria-expanded="false"
          id="dropdownMenuPublish"
          v-if="hasPermissionPublishFlowsPrd || hasPermissionPublishFlowsTest"
          v-on:click.prevent.stop="dropdownClick('dropdownMenuPublish')"
        ><i class="icon icon-paperplane"></i>
          <div></div>
        </button>
        <div class></div>
        <div class="dropdown-menu dropdown-menu-h"
             :class="{show:dropdownOpen === 'dropdownMenuPublish'}"
             aria-labelledby="dropdownMenuPublish">
          <div>
            <div class="dropdown-title">TEST</div>
            <div
              id="testVersion"
              class="dropdown-item"
              @click="$emit('publish-test')"
              v-if="hasPermissionPublishFlowsTest"
            >
              <div>Activate this intent flow to the test chat channel</div>
            </div>
            <div
              id="testVersion"
              class="dropdown-item"
              @click="$emit('publish-test-all')"
              v-if="hasPermissionPublishFlowsTest"
            >
              <div>Activate all intent flows to the test chat channel</div>
            </div>
            <div class="dropdown-title">PRODUCTION</div>
            <div
              id="labeledVersion"
              class="dropdown-item"
              @click="$emit('publish')"
              v-if="hasPermissionPublishFlowsPrd"
            >
              <div>Activate this intent flow to all channels</div>
            </div>
            <div
              id="labeledVersion"
              class="dropdown-item"
              @click="$emit('publish-all')"
              v-if="hasPermissionPublishFlowsPrd"
            >
              <div>Activate all intent flows to all channels</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import { AUTO_SAVED_FLOW_HISTORY, FAULTY_FLOW_HISTORY } from '../../../../../../common/constants/bpmn.constant'
import { Permissions } from '../../../../../../common/enums/tenant/user-permissions.enum'
import permissionsService from '../../../../services/tenants/permissions.service'
import Toggle from '../../../helpers/Toggle.vue'
import subscriptionMixin from '@/components/admin/configuration/_mixins/subscription.mixin'
import DropdownMixin from '@/components/admin/bots/_mixins/dropdown.mixin'

@Component({
  components: { Toggle }
})
export default class FunctionsBar extends Mixins(Vue, DropdownMixin) {
  private currentVersion = null

  @Prop({ default: () => ({}) }) readonly currentIntent!: any
  @Prop({}) readonly botName: string
  @Prop({ default: 'intents' }) readonly mode: 'intents' | 'functions'
  @Prop() isSaving: boolean
  @Prop({ default: () => ({}) }) readonly autoPublishStatus: any
  @Prop() readonly componentKey!: number

  private hasPermissionManageIntents = false
  private hasPermissionToChangeTexts = false
  private hasPermissionPublishFlowsTest = false
  private hasPermissionPublishFlowsPrd = false
  private hasPermissionImportAndExportFlows = false
  private autoDeployToTest = false
  private saveHover = false
  private saveHoverTimeout: any = null

  @Watch('componentKey')
  private onComponentKeyChanged(newVal: number, oldVal: number) {
    if (newVal > oldVal) {
      this.loadVersions()
    }
  }

  @Watch('isSaving')
  private onNewVersion(newVal: boolean, oldVal: boolean) {
    if (oldVal === true && newVal === false) {
      this.loadVersions()
    }
  }

  @Watch('currentVersion')
  private onCurrentVersionChanged() {
    const version = String(this.currentVersion)
    this.$emit(
      'change-version',
      version.endsWith('*') ? version.replace('*', '') : version
    )
  }

  get permissions() {
    return Permissions
  }

  async beforeMount() {
    [
      this.hasPermissionManageIntents,
      this.hasPermissionToChangeTexts,
      this.hasPermissionPublishFlowsTest,
      this.hasPermissionPublishFlowsPrd,
      this.hasPermissionManageIntents,
      this.hasPermissionImportAndExportFlows
    ] = await permissionsService.hasAllPermissions(
      this.botName,
      Permissions.ManageIntents,
      Permissions.ManageFlowsInDialogManager,
      Permissions.PublishFlowsTest,
      Permissions.PublishFlowsPrd,
      Permissions.ManageIntents,
      Permissions.ImportAndExportFlows
    )
    this.loadVersions()
  }

  openLabeledVersion(index) {
    this.openVersion(index, 'labeledVersions')
  }

  openTestVersion(index) {
    this.openVersion(index, 'testVersions')
  }

  save() {
    this.$emit('save')
  }

  openVersion(index, publishType: 'labeledVersions' | 'testVersions') {
    let xml
    if (index === 'published') {
      this.currentVersion = this.currentIntent.content[
        publishType
        ][0].name.replace('v', '')
      xml = this.currentIntent.content[publishType][0].xml
    } else {
      this.currentVersion = this.currentIntent.content.xmlArray[index].version
      this.currentVersion += this.isAutoSaved(
        this.currentIntent.content.xmlArray[index]
      )
        ? '*'
        : ''
      xml = this.currentIntent.content.xmlArray[index].xml
    }
    this.$emit('open-version', xml)
  }

  isAutoSaved(version) {
    return version.status === AUTO_SAVED_FLOW_HISTORY
  }

  isFaultyFlow(version) {
    return version.status === FAULTY_FLOW_HISTORY
  }

  @Emit('import')
  selectFile(event) {
    return event.target.files[0]
  }

  loadVersions() {
    if (this.currentIntent.content.xmlArray.length > 0) {
      this.currentVersion = this.currentIntent.content.xmlArray[0].version
      this.currentVersion += this.isAutoSaved(
        this.currentIntent.content.xmlArray[0]
      )
        ? '*'
        : ''
    } else {
      this.currentVersion = 0
    }
  }

  setSaveHoverCloseTimeout() {
    this.saveHoverTimeout = setTimeout(() => this.saveHover = false, 100)
  }

  deleteSaveHoverCloseTimeout() {
    clearTimeout(this.saveHoverTimeout)
  }
}
</script>

<style lang="scss">
@import "../../../../assets/scss/variables";
@import "../styles/zoom-top";

.s-r-v-group {
  float: right;

  .title {
    font-weight: 700;
    font-size: 14px;
    color: $default-dark;
    margin-bottom: 0px;
    margin-top: 15px;
  }

  .s-r-v-group--version {
    position: relative;
    display: block;
    float: left;

    .icon {
      position: absolute;
    }

    .dropdown-menu {
      margin-top: $s-r-v-group-height + 7px;
      margin-right: 20px;
      border: 0px solid $white;
      -webkit-box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
      -ms-box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
      box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: -6px;
        left: 5px;
        width: 8px;
        height: 6px;
        border-left: solid 6px transparent;
        border-right: solid 6px transparent;
      }

      &:before {
        border-bottom: solid 6px $white;
      }

      &:after {
        top: -4px;
        border-bottom: solid 6px $white;
      }

      p,
      .dropdown-item {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: $default-dark;
        cursor: pointer;

        &.margin-15 {
          margin-top: 15px;
        }

        &.auto-saved {
          color: $drag-drop2;
        }

        &.faulty-flow {
          color: $red;
        }

        &.active {
          background-color: $white;
          color: $main;
          font-weight: bold;
          border-left: 4px solid $main;
          padding-left: 19px;

          &.auto-saved {
            color: $drag-drop2;
            border-left: 4px solid $drag-drop2;
          }

          &.faulty-flow {
            color: $red;
            border-left: 4px solid $red;
          }
        }

        &:hover,
        &:focus:not(.auto-saved) {
          color: $main;
          font-weight: bold;
          border-left: 4px solid $main;
          padding-left: 19px;

          &.auto-saved {
            color: $drag-drop2;
            border-left: 4px solid $drag-drop2;
          }

          &.faulty-flow {
            color: $red;
            border-left: 4px solid $red;
          }
        }

        &:active {
          background-color: $white;
        }
      }

      .dropdown-title {
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        color: $default-dark;
        margin-left: 10px;
      }

      p {
        margin: 0;
        padding: 5px 20px;
        white-space: nowrap;
        cursor: default;
        color: $default-dark;

        &:hover,
        &.active,
        &:focus,
        &:active {
          color: $default-dark;
        }
      }
    }
  }
}

.upload-group--wrapper {
  margin-top: 15px;

  .btn {
    background: $default;
    border-color: $default;
    cursor: pointer;
    -webkit-box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
    -ms-box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
    box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
  }
}

.auto-deploy {
  margin: 13px 5px 0 4px;

  #toggle {
    margin-right: 4px;
  }
}

.save-dropdown {
  background: $default;
  border-radius: 6px;
  margin-top: 60px;
  position: absolute;
  width: 200px;
  left: -86px;
  border: 0px solid $white;
  -webkit-box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
  -ms-box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
  box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);

  &:before {
    content: "";
    position: absolute;
    top: -4px;
    left: 105px;
    width: 8px;
    height: 6px;
    border-left: solid 6px transparent;
    border-right: solid 6px transparent;
  }

  &:before {
    border-bottom: solid 6px $white;
  }

  &:after {
    top: -4px;
    border-bottom: solid 6px $white;
  }
}
</style>
