import { Component, Vue } from 'vue-property-decorator'
import imageCompression from 'browser-image-compression'
import rolesService from '@/services/roles.service'
import { GeneralSettingsService } from '@/services/bots/settings/general-settings.service'
import { ALLOWED_SIZE, BCR_MAX_AVATAR_SIZE } from '../../../../../../../../common/constants/file.constant'
import { ValidationError } from '../../../../../../../../common/errors'
import { IBotSettings } from '../../../../../../../../common/interfaces/bot-settings.interface'

@Component
export default class GeneralSettingsManagementMixin extends Vue {
  generalSettingsService: GeneralSettingsService
  isTenantAdmin = false
  displayName = ''
  botAvatar = ''
  settings: Partial<IBotSettings> = {}

  initGeneralSettings(botName: string) {
    this.generalSettingsService = new GeneralSettingsService(botName)
  }

  async getGeneralSettings() {
    const settingsPromise = this.generalSettingsService.getSettings().then((data) => {
      this.settings = data.settings
      this.displayName = data.displayName
      this.botAvatar = data.botAvatar
    })

    this.isTenantAdmin = await rolesService.isTenantAdmin()
    return await settingsPromise
  }

  updateDisplayName(newName: string) {
    this.displayName = newName
  }

  updateAvatar(file: File) {
    if (file.size > ALLOWED_SIZE) {
      throw new ValidationError('Avatar file is too big')
    }
    imageCompression(file, { maxSizeMB: BCR_MAX_AVATAR_SIZE / 1024 / 1024 }).then((compressed) => {
      const reader = new FileReader()
      reader.readAsDataURL(compressed)
      reader.onload = () => (this.botAvatar = reader.result.toString())
    })
  }

  async updateSettings() {
    this.settings.supportedLanguages = this.$store.state.supportedLanguages
    await this.generalSettingsService.updateSettings(this.displayName, this.settings, this.botAvatar)
  }
}
