import { Engine } from '../interfaces/engine'
import { ExternalProvider } from '../../../../../../../common/enums/external-provider.enum'
import { nluNames } from '../../../../../../../common/constants/nlu-names.constant'
import { entityExtractorNames } from '../../../../../../../common/constants/entity-extractor-names.constant'
import { ttsNames } from '../../../../../../../common/constants/tts-names.constant'
import { sentimentNames } from '../../../../../../../common/constants/sentiment-names.constant'
import { languageDetectorNames } from '../../../../../../../common/constants/language-detector-names.constant'
import { languageTranslatorNames } from '../../../../../../../common/constants/language-translator-names.constant'
import { sttNames } from '../../../../../../../common/constants/stt-names.constant'

const logoPaths = {
  microsoft: '/assets/img/logo-ms.svg',
  ibm: '/assets/img/logo-ibm.png',
  google: '/assets/img/logo-google.png',
  amazon: '/assets/img/logo-amazon.png',
  rasa: '/assets/img/logo-rasa.png',
  stanford: '/assets/img/logo-stanford.jpg',
  wit: '/assets/img/logo-wit.png',
  nuance: '/assets/img/logo-nuance.png',
  neuralspace: '/assets/img/logo-neuralspace.svg',
  openai: '/assets/img/logo-openai.png'
}

export const engines: Engine[] = [
  {
    id: 'INTENT_RECOGNIZER',
    name: 'Intent Recognizer',
    options: [
      {
        name: nluNames[ExternalProvider.Microsoft],
        imgSrc: logoPaths.microsoft,
        provider: ExternalProvider.Microsoft
      },
      {
        name: nluNames[ExternalProvider.MicrosoftCLU],
        imgSrc: logoPaths.microsoft,
        provider: ExternalProvider.MicrosoftCLU
      },
      {
        name: nluNames[ExternalProvider.IBM],
        imgSrc: logoPaths.ibm,
        provider: ExternalProvider.IBM
      },
      {
        name: nluNames[ExternalProvider.Google],
        imgSrc: logoPaths.google,
        provider: ExternalProvider.Google
      },
      {
        name: nluNames[ExternalProvider.Rasa],
        imgSrc: logoPaths.rasa,
        provider: ExternalProvider.Rasa
      },
      {
        name: nluNames[ExternalProvider.Amazon],
        imgSrc: logoPaths.amazon,
        provider: ExternalProvider.Amazon
      },
      {
        name: nluNames[ExternalProvider.Wit],
        imgSrc: logoPaths.wit,
        provider: ExternalProvider.Wit
      },
      {
        name: nluNames[ExternalProvider.Nuance],
        imgSrc: logoPaths.nuance,
        provider: ExternalProvider.Nuance
      },
      {
        name: nluNames[ExternalProvider.NeuralSpace],
        imgSrc: logoPaths.neuralspace,
        provider: ExternalProvider.NeuralSpace
      }
    ],
    selectedProvider: ExternalProvider.Microsoft
  },
  {
    id: 'ENTITY_EXTRACTOR',
    name: 'Entity Extractor',
    options: [
      {
        name: entityExtractorNames[ExternalProvider.Microsoft],
        imgSrc: logoPaths.microsoft,
        provider: ExternalProvider.Microsoft
      },
      {
        name: entityExtractorNames[ExternalProvider.MicrosoftCLU],
        imgSrc: logoPaths.microsoft,
        provider: ExternalProvider.MicrosoftCLU
      },
      {
        name: entityExtractorNames[ExternalProvider.IBM],
        imgSrc: logoPaths.ibm,
        provider: ExternalProvider.IBM
      },
      {
        name: entityExtractorNames[ExternalProvider.Google],
        imgSrc: logoPaths.google,
        provider: ExternalProvider.Google
      },
      {
        name: entityExtractorNames[ExternalProvider.Rasa],
        imgSrc: logoPaths.rasa,
        provider: ExternalProvider.Rasa
      },
      {
        name: entityExtractorNames[ExternalProvider.Amazon],
        imgSrc: logoPaths.amazon,
        provider: ExternalProvider.Amazon
      },
      {
        name: entityExtractorNames[ExternalProvider.Wit],
        imgSrc: logoPaths.wit,
        provider: ExternalProvider.Wit
      },
      {
        name: entityExtractorNames[ExternalProvider.Nuance],
        imgSrc: logoPaths.nuance,
        provider: ExternalProvider.Nuance
      },
      {
        name: entityExtractorNames[ExternalProvider.Stanford],
        imgSrc: logoPaths.stanford,
        provider: ExternalProvider.Stanford
      },
      {
        name: entityExtractorNames[ExternalProvider.NeuralSpace],
        imgSrc: logoPaths.neuralspace,
        provider: ExternalProvider.NeuralSpace
      }
    ],
    selectedProvider: ExternalProvider.Microsoft
  },
  {
    id: 'TTS',
    name: 'Text-to-speech',
    options: [
      {
        name: ttsNames.MICROSOFT,
        imgSrc: logoPaths.microsoft,
        provider: ExternalProvider.Microsoft
      },
      {
        name: ttsNames.OPEN_AI,
        imgSrc: logoPaths.openai,
        provider: ExternalProvider.OpenAI
      },
      {
        name: ttsNames.IBM,
        imgSrc: logoPaths.ibm,
        provider: ExternalProvider.IBM
      },
      {
        name: ttsNames.GOOGLE,
        imgSrc: logoPaths.google,
        provider: ExternalProvider.Google
      },
      {
        name: ttsNames.AMAZON,
        imgSrc: logoPaths.amazon,
        provider: ExternalProvider.Amazon
      }
    ],
    selectedProvider: ExternalProvider.Microsoft
  },
  {
    id: 'STT',
    name: 'Speech-to-text',
    options: [
      {
        name: sttNames.MICROSOFT,
        imgSrc: logoPaths.microsoft,
        provider: ExternalProvider.Microsoft
      },
      {
        name: sttNames.OPEN_AI,
        imgSrc: logoPaths.openai,
        provider: ExternalProvider.OpenAI
      },
      {
        name: sttNames.GOOGLE,
        imgSrc: logoPaths.google,
        provider: ExternalProvider.Google
      }
    ],
    selectedProvider: ExternalProvider.Microsoft
  },
  {
    id: 'SENTIMENT_ANALYZER',
    name: 'Sentiment Analysis',
    options: [
      {
        name: sentimentNames[ExternalProvider.Microsoft],
        imgSrc: logoPaths.microsoft,
        provider: ExternalProvider.Microsoft
      },
      {
        name: sentimentNames[ExternalProvider.IBM],
        imgSrc: logoPaths.ibm,
        provider: ExternalProvider.IBM
      },
      {
        name: sentimentNames[ExternalProvider.Google],
        imgSrc: logoPaths.google,
        provider: ExternalProvider.Google
      },
      {
        name: sentimentNames[ExternalProvider.Amazon],
        imgSrc: logoPaths.amazon,
        provider: ExternalProvider.Amazon
      },
      {
        name: sentimentNames[ExternalProvider.Stanford],
        imgSrc: logoPaths.stanford,
        provider: ExternalProvider.Stanford
      }
    ],
    selectedProvider: ExternalProvider.Microsoft
  },
  {
    id: 'LANGUAGE_DETECTION',
    name: 'Language Detection',
    options: [
      {
        name: languageDetectorNames[ExternalProvider.Microsoft],
        imgSrc: logoPaths.microsoft,
        provider: ExternalProvider.Microsoft
      },
      {
        name: languageDetectorNames[ExternalProvider.IBM],
        imgSrc: logoPaths.ibm,
        provider: ExternalProvider.IBM
      },
      {
        name: languageDetectorNames[ExternalProvider.Google],
        imgSrc: logoPaths.google,
        provider: ExternalProvider.Google
      },
      {
        name: languageDetectorNames[ExternalProvider.Amazon],
        imgSrc: logoPaths.amazon,
        provider: ExternalProvider.Amazon
      },
      {
        name: languageDetectorNames[ExternalProvider.Wit],
        imgSrc: logoPaths.wit,
        provider: ExternalProvider.Wit
      }
    ],
    selectedProvider: ExternalProvider.Microsoft
  },
  {
    id: 'LANGUAGE_TRANSLATION',
    name: 'Language Translation',
    options: [
      {
        name: languageTranslatorNames[ExternalProvider.Microsoft],
        imgSrc: logoPaths.microsoft,
        provider: ExternalProvider.Microsoft
      },
      {
        name: languageTranslatorNames[ExternalProvider.IBM],
        imgSrc: logoPaths.ibm,
        provider: ExternalProvider.IBM
      },
      {
        name: languageTranslatorNames[ExternalProvider.Google],
        imgSrc: logoPaths.google,
        provider: ExternalProvider.Google
      },
      {
        name: languageTranslatorNames[ExternalProvider.Amazon],
        imgSrc: logoPaths.amazon,
        provider: ExternalProvider.Amazon
      }
    ],
    selectedProvider: ExternalProvider.Microsoft
  },
  {
    id: 'VOICETONE_ANALYZER',
    name: 'Voicetone Analysis',
    isDisabled: true,
    options: [
      {
        name: 'Amazon Lex',
        imgSrc: logoPaths.amazon,
        provider: ExternalProvider.Amazon,
        isDisabled: true
      }
    ],
    selectedProvider: ExternalProvider.Amazon
  }
]
