import {ExternalProvider} from "../enums/external-provider.enum";
import {defaultBotSettings, defaultOpenAISettings} from "../constants/default-bot-settings";
import {IBotSettings} from "../interfaces/bot-settings.interface";
import {enumToSelectArray} from "../../frontend/src/components/helpers/select.helper";
import {
    OpenAICompletionResponseFormat,
    OpenAICompletionVoice,
    OpenAIResponseFormat,
    OpenAISpeechModel,
    OpenAITTSLanguage,
    OpenAIVoice
} from "../enums/openai/openai-tts.language.enum";
import {MicrosoftResponseFormat, MicrosoftTTSLanguage} from "../enums/microsoft/microsoft-tts.language.enum";
import {IbmTTSLanguage} from "../enums/ibm/ibm-tts-languages.enum";
import {AmazonTTSLanguage} from "../enums/amazon/amazon-tts.language.enum";
import {GoogleTTSLanguage} from "../enums/google/google-tts.language.enum";

type TTSRequired = Extract<
    'textToSpeechLanguageCode'
    , keyof IBotSettings>;

type TTSOptional = Extract<
    'textToSpeechModel'|
    'textToSpeechPreferredVoiceType'|
    'textToSpeechResponseFormat'|
    'textToSpeechSpeed'|
    'textToSpeechStreaming'|
    'idleTimeout'|
    'idleText'
    , keyof IBotSettings>;


export type TTSSettings = Pick<IBotSettings, TTSOptional | TTSRequired>;

type TTSRequiredOptions = { [K in TTSRequired]: any; }
type TTSOptionalOptions = { [K in TTSOptional]?: any; }
export type TTSOptions = TTSRequiredOptions & TTSOptionalOptions;

const showInputField = 'showInputField'

export class SettingsHelper {
    static getDefault(provider: ExternalProvider) {
        switch (provider) {
            case ExternalProvider.OpenAI: return defaultOpenAISettings;
            default: return defaultBotSettings;
        }
    }
    static getTTSDefault(provider: ExternalProvider): TTSSettings{
        const settings =  SettingsHelper.getDefault(provider);
        return {
            textToSpeechLanguageCode:       settings.textToSpeechLanguageCode       ?? null,
            textToSpeechModel:              settings.textToSpeechModel              ?? null,
            textToSpeechPreferredVoiceType: settings.textToSpeechPreferredVoiceType ?? null,
            textToSpeechResponseFormat:     settings.textToSpeechResponseFormat     ?? null,
            textToSpeechSpeed:              settings.textToSpeechSpeed              ?? null,
            textToSpeechStreaming:          settings.textToSpeechStreaming          ?? null,
            idleText:                       settings.idleText                       ?? null,
            idleTimeout:                    settings.idleTimeout                    ?? null,
        }
    }
    static getTTSOptions(provider: ExternalProvider, settings: TTSSettings): TTSOptions {
        const defaultOptions: TTSOptionalOptions = {
            textToSpeechPreferredVoiceType: [
                { id: 'm', label: 'Male' },
                { id: 'f', label: 'Female' }
            ]
        }
        switch (provider) {
            case ExternalProvider.OpenAI:       return SettingsHelper.getTTSOptionsOpenAI(settings)
            case ExternalProvider.Microsoft:    return SettingsHelper.getTTSMicrosoftOptions(defaultOptions);
            case ExternalProvider.IBM:          return SettingsHelper.getTTSIBMOptions(defaultOptions);
            case ExternalProvider.Amazon:       return SettingsHelper.getTTSAmazonOptions(defaultOptions);
            case ExternalProvider.Google:       return SettingsHelper.getTTSGoogleOptions(defaultOptions);
            default:
                throw new Error('unknown TTS provider: '+provider)
        }

    }
    private static getTTSOptionsOpenAI(settings: TTSSettings):TTSOptions {
        /**
         * Some OpenAI settings influence other options.
         * This logic is the same as OpenAITextToSpeechService constructor, with one exception. See below:
          */
        const useLLM = !settings.textToSpeechModel?.includes('tts')
        const streaming = settings.textToSpeechStreaming ?? false;
        let formatOptions: Partial<typeof OpenAICompletionResponseFormat> | Partial<typeof OpenAIResponseFormat>;
        let voice: typeof OpenAICompletionVoice | typeof OpenAIVoice
        if (useLLM) {
            voice = OpenAICompletionVoice
            if (streaming) {
                // a. Stream using LLM: only pcm16 is available
                formatOptions = {pcm16: OpenAICompletionResponseFormat.pcm16}
            } else {
                // b. Non-stream using LLM: all formats from ChatCompletions API
                formatOptions = OpenAICompletionResponseFormat;
            }
        }  else {
            voice = OpenAIVoice
            if (streaming) {
                // c. Stream using TTS: only mp3
                // OpenAITextToSpeechService constructor sets the model to 'tts-1'. We can't restrict the model options, or we'd stay stuck with 'tts-1'.
                formatOptions = {mp3: OpenAIResponseFormat.mp3}
            } else {
                // d. Non-stream using TTS: all formats from ChatCompletions API
                formatOptions = OpenAIResponseFormat;
            }
        }
        return {
            textToSpeechLanguageCode:       Object.entries(OpenAITTSLanguage),
            textToSpeechModel:              enumToSelectArray(OpenAISpeechModel),
            textToSpeechPreferredVoiceType: enumToSelectArray(voice), // OpenAI has voicesTypes other than male/female. https://platform.openai.com/docs/api-reference/audio/createSpeech
            textToSpeechResponseFormat:     enumToSelectArray(formatOptions),
            textToSpeechSpeed:              showInputField,
            textToSpeechStreaming:          showInputField,
        };
    }

    private static getTTSMicrosoftOptions(defaultOptions:TTSOptionalOptions) {
        return {...defaultOptions,
            textToSpeechLanguageCode:       Object.entries(MicrosoftTTSLanguage),
            textToSpeechResponseFormat:     enumToSelectArray(MicrosoftResponseFormat),
        };
    }
    private static getTTSIBMOptions(defaultOptions:TTSOptionalOptions) {
        return {...defaultOptions,
            textToSpeechLanguageCode: Object.entries(IbmTTSLanguage),
        };
    }
    private static getTTSAmazonOptions(defaultOptions:TTSOptionalOptions) {
        return {...defaultOptions,
            textToSpeechLanguageCode: Object.entries(AmazonTTSLanguage),
        };
    }
    private static getTTSGoogleOptions(defaultOptions:TTSOptionalOptions) {
        return {...defaultOptions,
            textToSpeechLanguageCode: Object.entries(GoogleTTSLanguage),
        };
    }

}