<template>
  <div>
    <div class="options--left" v-if="!currentFunction">
      <div class="options--intent">
        <button
          id="dropdownMenuLink3"
          type="button"
          class="btn intents dropdown-toggle dropdown-toggle-split"
          aria-haspopup="true"
          aria-expanded="false"
          v-on:click.prevent.stop="dropdownClick('dropdownMenuLink3')"
        >
          <div>
            <span>{{mode | capitalize}}:</span>
            {{ currentIntent.name }}
          </div>
        </button>
        <div
          class="dropdown-menu dropdown-menu--intent dropdown-menu-4"
          aria-labelledby="dropdownMenuLink3"
          :class="{show:dropdownOpen === 'dropdownMenuLink3'}"
        >
          <div
            v-if="mode == 'intents'"
            :key="welcomeIntent.name"
            class="dropdown-item"
            @click="selectItem(welcomeIntent)"
            :class="{'active': currentIntent.name === 'Welcome Intent'}"
          >{{ welcomeIntent.name }}</div>
          <div class="dropdown-item dropdown-item--model disabled">
            <b>App</b>
          </div>
          <div
            v-for="item in items.filter(i=> i.name != welcomeIntentName)"
            :key="item.name"
            class="dropdown-item"
            @click="selectItem(item)"
            :class="{'active': item.name ===  currentIntent.name}"
          >{{ item.name }}</div>
        </div>

        <div class="show-examples">
          <div class="show-examples__example" v-if="!isEntry && mode == 'intents'">
            Examples
            <label
              aria-haspopup="true"
              class="show-examples--toggle"
              :class="{ 'toggle-active': showUtterance }"
            >
              <input
                id="dropdownMenuLink4"
                type="checkbox"
                @change="showUtterance = !showUtterance"
              />
              <i></i>
            </label>
          </div>
          <div class="show-examples__example">
            Variables
            <label
              aria-haspopup="true"
              class="show-examples--toggle"
              :class="{ 'toggle-active': showVariables }"
            >
              <input
                id="variablesMenuLink4"
                type="checkbox"
                @change="showVariables = !showVariables; $emit('showVariables', showVariables)"
              />
              <i></i>
            </label>
          </div>
          <div class="show-examples__example">
            Statistics
            <label
              aria-haspopup="true"
              class="show-examples--toggle"
              :class="{ 'toggle-active': showStatistics }"
            >
              <input
                id="statisticsMenuLink4"
                type="checkbox"
                @change="showStatistics = !showStatistics; $emit('show-statistics', showStatistics)"
              />
              <i></i>
            </label>
          </div>
          <div class="show-examples__example" v-show="showStatistics">
            <label class="refresh-statistics-label" @click="$emit('refresh-statistics')">Refresh Statistics</label>
          </div>
        </div>

        <div
          class="dropdown-menu dropdown-menu--examples"
          :style="{display: showUtterance ? 'block' : 'none'}"
          aria-labelledby="dropdownMenuLink4"
          v-if="showUtterance"
        >
          <div
            v-if="!utterances || !utterances.length"
            class="dropdown-item"
          >There are no examples for this intent</div>
          <div
            v-for="utterance in utterances"
            :key="utterance.text"
            class="dropdown-item"
          >{{ utterance.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Emit, Mixins, Prop, Vue} from 'vue-property-decorator'
import { capitalize } from '../../../filters/string.filters'
import DropdownMixin from "@/components/admin/bots/_mixins/dropdown.mixin";

@Component({
  filters: {
    capitalize: capitalize
  }
})
export default class SelectionBar extends Mixins(Vue, DropdownMixin) {
  private currentIntent = null;
  private showUtterance = false;
  private showVariables = false;
  private showStatistics = false;
  private utterances = [];

  private readonly welcomeIntentName = 'Welcome Intent';

  @Prop({ default: '' }) readonly currentFunction: string;
  @Prop({ default: () => [] }) readonly items!: any[];
  @Prop({ default: () => { return {} } }) readonly entryItem!: any;
  @Prop({ default: () => { return {} } }) readonly mode: string;

  beforeMount () {
    this.currentIntent = this.entryItem || this.items[0]
    this.utterances = (this.currentIntent.patterns || []).concat(
      this.currentIntent.utterances || []
    )
  }

  get isEntry () {
    return this.currentIntent.name === this.welcomeIntentName
  }

  get welcomeIntent () {
    return this.items.find(i => i.name === this.welcomeIntentName) || {}
  }

  @Emit('open-item')
  selectItem (item) {
    this.currentIntent = item
    this.utterances = (item.patterns || []).concat(item.utterances || [])
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/variables";
// @import "../../flowEditor.style";
$default: white;
$dark: black;

.dropdown-item {
  color: $default-dark;
  font-size: 14px;

  &.active, &:active, &:focus, &:hover {
    color: $main;
    font-weight: bold;
    border-left: 4px solid $main;
    padding-left: 12px;
    background-color: $white !important;
  }
}

.options--intent {
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 100%;
  max-width: 304px;
  @media only screen and (min-width: 1640px) {
    max-width: inherit;
  }
  .dropdown-menu-4 {
    width: auto;
    max-height: 70vh;
    z-index: 10;
    float: right;
    margin-top: 7px;
    border: none;
    overflow: auto;
    -webkit-box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
    -ms-box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
    box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);

     &:before {
      content: '';
      position: absolute;
      left: 10px;
      top: -7px;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent $white transparent;
    }
  }
  .dropdown-item {
    cursor: pointer;
  }
  .dropdown-menu--examples {
    max-height: 60vh;
    overflow: auto;
  }
  .dropdown-item.disabled {
    padding-left: 5px;
    color: $dark;
  }
  button.intents {
    position: relative;
    text-align: left;
    border-radius: 6px;
    padding: 0 40px 0 20px;
    background-color: $white;
    color: $default-dark;
    border: none;
    height: 50px;
    font-weight: 400;
    -webkit-box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
    -ms-box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
    box-shadow: 0 2px 20px 9px rgba(31, 31, 31, 0.04);
    width: 134px;
    font-size: 16px;
    line-height: 48px;

    &:hover {
      color: $main;
    }

    @media only screen and (min-width: 1200px) {
      width: 294px;
    }
    > div {
      overflow: hidden;
      white-space: nowrap;
    }
    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
    }
    span {
      font-weight: 700;
    }
  }
  .show-examples {
    position: absolute;
    display: inline-block;
    margin-left: 20px;
    font-size: 16px;
    font-weight: 400;
    color: $secondary-text;
    line-height: 26px;
    vertical-align: top;
    top: 58px;
    left: 0;
    min-width: 194px;
    @media only screen and (min-width: 1200px) {
      position: relative;
      top: 8px;
    }

    .show-examples__example {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 5px;
      @media only screen and (min-width: 1440px) {
        margin-bottom: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      .refresh-statistics-label {
        text-decoration: underline;
      }
    }

    .show-examples--toggle {
      position: relative;
      top: 5px;
      margin: 0 0 0 10px;
      width: 51px;
      height: 26px;
      display: inline-block;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      &.toggle-active {
        i {
          background-color: $main;
          border-color: $main;
          &:after {
            transform: translate3d(27px, 2px, 0);
            border-color: $main;
          }
        }
      }

      input {
        display: none;
      }
      i {
        position: relative;
        display: inline-block;
        width: 51px;
        height: 26px;
        background-color: $default;
        border: 1px solid $border;
        border-radius: 13px;
        vertical-align: text-bottom;
        transition: all 0.3s linear;
        &:after {
          content: "";
          position: absolute;
          left: 0;
          width: 20px;
          height: 20px;
          background: linear-gradient(to bottom, white 3%, #f5f5f5 100%);
          border: 1px solid $border;
          border-radius: 50px;
          transform: translate3d(3px, 2px, 0);
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
}
</style>
